import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { FirstSymbolPipeModule } from '../../pipes/first-symbol-pipe/first-symbol-pipe.module';
import { LanguageService } from '../../services/language.service';
import { ButtonModule } from '../button/button.module';
import { CollapseModule } from '../collapse/collapse.module';
import { AuthorizationActionsComponent } from './components/authorization-actions/authorization-actions.component';
import { AuthorizedActionsComponent } from './components/authorized-actions/authorized-actions.component';
import { LanguageSelectorCollapseComponent } from './components/language-selector-collapse/language-selector-collapse.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LayoutContactsComponent } from './components/layout-contacts/layout-contacts.component';
import { LayoutFooterCopyrightComponent } from './components/layout-footer-copyright/layout-footer-copyright.component';
import { MainContactsComponent } from './components/main-contacts/main-contacts.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { NotAuthorizedActionsComponent } from './components/not-authorized-actions/not-authorized-actions.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SocialContactsLinkComponent } from './components/social-contact-link/social-contact-link.component';
import { SupportChatsComponent } from './components/support-chats/support-chats.component';
import { LayoutComponent } from './layout.component';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { LayoutConfigService } from './services/layout-config.service';
import { CalculatorLinkComponent } from './components/calculator-link/calculator-link.component';

@NgModule({
  declarations: [
    LayoutComponent,
    LayoutFooterCopyrightComponent,
    SocialContactsLinkComponent,
    LayoutContactsComponent,
    LanguageSelectorComponent,
    LanguageSelectorCollapseComponent,
    MainContactsComponent,
    AuthorizedActionsComponent,
    NotAuthorizedActionsComponent,
    AuthorizationActionsComponent,
    MobileMenuComponent,
    SupportChatsComponent,
    ScrollToTopComponent,
    CalculatorLinkComponent,
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    NzIconModule,
    TranslateModule,
    NzGridModule,
    CollapseModule,
    NzDropDownModule,
    ButtonModule,
    FirstSymbolPipeModule,
    RxPush,
    NzDrawerModule,
    RouterModule,
  ],
  providers: [LayoutConfigService],
  exports: [LayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}
