import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents time data, ISO-8601 encoded UTC date string. */
  DateTime: { input: any; output: any; }
  /** Arbitrary data encoded in JSON. See https://www.json.org/. */
  JSON: { input: any; output: any; }
};

export type AddCommentInput = {
  comment: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type AddCommentOutput = {
  __typename?: 'AddCommentOutput';
  error?: Maybe<MutationError>;
};

export type AddContainerToPlanInput = {
  containerId: Scalars['Int']['input'];
  planId: Scalars['Int']['input'];
};

export type AddContainerToPlanOutput = {
  __typename?: 'AddContainerToPlanOutput';
  error?: Maybe<MutationError>;
};

export type AddDisassemblyInfoInput = {
  message: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
  type: DisassemblyInfoEnum;
};

export type AddDisassemblyInfoOutput = {
  __typename?: 'AddDisassemblyInfoOutput';
  error?: Maybe<MutationError>;
};

export type AddExportCertificateToWorldwideObjectInput = {
  expirationDate: Scalars['String']['input'];
  exportCertificateKeyS3File: Scalars['String']['input'];
  number: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type AddExportCertificateToWorldwideObjectOutput = {
  __typename?: 'AddExportCertificateToWorldwideObjectOutput';
  error?: Maybe<MutationError>;
};

export type AddExportObjectToPlanInput = {
  chassisId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  customId?: InputMaybe<Scalars['Int']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  engineTypeId?: InputMaybe<Scalars['Int']['input']>;
  engineVolumeCm3?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  isAddDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  length?: InputMaybe<Scalars['Int']['input']>;
  loadCapacity?: InputMaybe<Scalars['Int']['input']>;
  makerId: Scalars['Int']['input'];
  markId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
  ownerId: Scalars['Int']['input'];
  payCountryId?: InputMaybe<Scalars['Int']['input']>;
  planId: Scalars['Int']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  priceCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  senderId: Scalars['Int']['input'];
  typeId: Scalars['Int']['input'];
  vin: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type AddExportObjectToPlanOutput = {
  __typename?: 'AddExportObjectToPlanOutput';
  error?: Maybe<MutationError>;
};

export type AddFilesToObjectInput = {
  contentType: Scalars['String']['input'];
  keyS3Files: Array<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  type: MediaTypeEnum;
};

export type AddFilesToObjectOutput = {
  __typename?: 'AddFilesToObjectOutput';
  error?: Maybe<MutationError>;
};

export type AddFirebaseTokenInput = {
  token: Scalars['String']['input'];
};

export type AddFirebaseTokenOutput = {
  __typename?: 'AddFirebaseTokenOutput';
  error?: Maybe<MutationError>;
};

export type AddMessageToChatInput = {
  chatId: Scalars['Int']['input'];
  filesKeys: Array<InputMaybe<Scalars['String']['input']>>;
  isTask: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

export type AddMessageToChatOutput = {
  __typename?: 'AddMessageToChatOutput';
  error?: Maybe<MutationError>;
};

export type AddObjectToPlanInput = {
  contract: Scalars['Int']['input'];
  isAddDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  objectId: Scalars['Int']['input'];
  planId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type AddObjectToPlanOutput = {
  __typename?: 'AddObjectToPlanOutput';
  error?: Maybe<MutationError>;
};

export type AddTagToChatInput = {
  chatId: Scalars['Int']['input'];
  tagId: Scalars['Int']['input'];
};

export type AddTagToChatOutput = {
  __typename?: 'AddTagToChatOutput';
  error?: Maybe<MutationError>;
};

export type AddToPlanAttention = {
  __typename?: 'AddToPlanAttention';
  reason?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Boolean']['output']>;
};

export enum AdditionalKeysStatusEnum {
  Exist = 'EXIST',
  PutInside = 'PUT_INSIDE',
  Received = 'RECEIVED'
}

export type AddresseesUsersToChatsPaginatedList = {
  __typename?: 'AddresseesUsersToChatsPaginatedList';
  nodes: Array<User>;
  total: Scalars['Int']['output'];
};

export type Agent = {
  __typename?: 'Agent';
  emails?: Maybe<Array<Scalars['String']['output']>>;
  /** Есть сделки перепродажи, требующие подтверждения */
  hasNotApprovedResales?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAllowChangeCustomsPaymentType?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** Количество сделок перепродажи, требующих подтверждения */
  notApprovedResalesCount?: Maybe<Scalars['Int']['output']>;
  phones?: Maybe<Array<Scalars['String']['output']>>;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  id?: Maybe<Scalars['Int']['output']>;
  isDefaultForScrap?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AggregateTypeEnum>;
};

export enum AggregateTypeEnum {
  Body = 'body',
  Engine = 'engine',
  Frame = 'frame'
}

export type AkebonoPenalty = {
  __typename?: 'AkebonoPenalty';
  /** Процент от стоимости покупки */
  baseAmount?: Maybe<Money>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  dayFirstPenalty?: Maybe<Scalars['DateTime']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<ObjectOfServices>;
  penaltyDays?: Maybe<Scalars['Int']['output']>;
  penaltyDaysTotal?: Maybe<Scalars['Int']['output']>;
  penaltyRubAmount?: Maybe<Money>;
  proposedPenaltyDays?: Maybe<Scalars['Int']['output']>;
  /** Планируется начислить */
  proposedPenaltyJpyAmount?: Maybe<Money>;
  /** 100% начисленные пеналити */
  proposedPenaltyRubAmount?: Maybe<Money>;
  /** 1/x от стоимости авто */
  taxAmount?: Maybe<Money>;
};

export type AllowVisiblePhotoInput = {
  objectId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type AllowVisiblePhotoOutput = {
  __typename?: 'AllowVisiblePhotoOutput';
  error?: Maybe<MutationError>;
};

export type Ambassador = {
  __typename?: 'Ambassador';
  agent?: Maybe<Owner>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  formalBroker?: Maybe<Broker>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameRod?: Maybe<Scalars['String']['output']>;
  nameRodShort?: Maybe<Scalars['String']['output']>;
  nameShort?: Maybe<Scalars['String']['output']>;
  nameShortAfter?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<Broker>;
  thumbprint?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AmbassadorsPaginatedList = {
  __typename?: 'AmbassadorsPaginatedList';
  nodes: Array<Ambassador>;
  total: Scalars['Int']['output'];
};

export type AsObserverFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AttachmentsTemplate = {
  __typename?: 'AttachmentsTemplate';
  engName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Auction = {
  __typename?: 'Auction';
  address?: Maybe<Scalars['String']['output']>;
  auctionData?: Maybe<AuctionData>;
  auctionsCountryRestrictions?: Maybe<Array<AuctionsCountryRestrictions>>;
  auctionsDealerFees?: Maybe<Array<AuctionsDealerFees>>;
  auctionsDeliveryCosts?: Maybe<Array<AuctionsDeliveryCosts>>;
  /** Лого банка */
  bankLogo?: Maybe<S3File>;
  fax?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<AuctionRating>;
  stock?: Maybe<Stock>;
  ticketRules?: Maybe<Scalars['String']['output']>;
};

export type AuctionData = {
  __typename?: 'AuctionData';
  auctionDealer?: Maybe<Scalars['Int']['output']>;
  auctionDelivery?: Maybe<Scalars['Int']['output']>;
  auctionDeliveryUndriveable?: Maybe<Scalars['Int']['output']>;
  auctionSbor?: Maybe<Scalars['Int']['output']>;
  auctionSborByNegotiations?: Maybe<Scalars['Int']['output']>;
  carrier?: Maybe<Carrier>;
  costDelivery?: Maybe<Scalars['Float']['output']>;
  destinationStock?: Maybe<Stock>;
  freight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isNoGroup?: Maybe<Scalars['Boolean']['output']>;
  isNoPallet?: Maybe<Scalars['Boolean']['output']>;
  port?: Maybe<Port>;
  remoteness?: Maybe<AuctionRemotenessEnum>;
  sourceStocks?: Maybe<Array<Stock>>;
};

export type AuctionFeedbacksPaginatedList = {
  __typename?: 'AuctionFeedbacksPaginatedList';
  nodes: Array<CarFeedback>;
  total: Scalars['Int']['output'];
};

export type AuctionGroup = {
  __typename?: 'AuctionGroup';
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isMenu?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  queryAlias?: Maybe<Scalars['String']['output']>;
  stocks?: Maybe<Array<Stock>>;
};

export type AuctionListing = {
  __typename?: 'AuctionListing';
  auction?: Maybe<Auction>;
  auctionDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  kai?: Maybe<Scalars['String']['output']>;
  notSoldPrice?: Maybe<Scalars['Int']['output']>;
  numberLot?: Maybe<Scalars['String']['output']>;
  soldPrice?: Maybe<Scalars['Int']['output']>;
  startPrice?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  transportCompany?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  wishPrice?: Maybe<Scalars['Int']['output']>;
};

export type AuctionListingCreateInput = {
  auctionId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
  startPrice: Scalars['Int']['input'];
  wishPrice: Scalars['Int']['input'];
};

export type AuctionListingCreateOutput = {
  __typename?: 'AuctionListingCreateOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingNotSoldInput = {
  notSoldPrice: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type AuctionListingNotSoldOutput = {
  __typename?: 'AuctionListingNotSoldOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingSetTransportInput = {
  objectId: Scalars['Int']['input'];
  transportCompany: Scalars['String']['input'];
};

export type AuctionListingSetTransportOutput = {
  __typename?: 'AuctionListingSetTransportOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingSoldInput = {
  objectId: Scalars['Int']['input'];
  soldPrice: Scalars['Int']['input'];
};

export type AuctionListingSoldOutput = {
  __typename?: 'AuctionListingSoldOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingUpdateLotInput = {
  auctionDate: Scalars['String']['input'];
  auctionId: Scalars['Int']['input'];
  kai?: InputMaybe<Scalars['String']['input']>;
  numberLot?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  startPrice: Scalars['Int']['input'];
  wishPrice: Scalars['Int']['input'];
};

export type AuctionListingUpdateLotOutput = {
  __typename?: 'AuctionListingUpdateLotOutput';
  error?: Maybe<MutationError>;
};

export type AuctionPaySheet = {
  __typename?: 'AuctionPaySheet';
  auction?: Maybe<Auction>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  isSend?: Maybe<Scalars['Boolean']['output']>;
  objectCount?: Maybe<Scalars['Int']['output']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  /** Информация о том кто нажимал оплачен расчётный лист */
  paidData?: Maybe<Array<AuctionPaySheetPaidData>>;
  paySheetPayments?: Maybe<Array<AuctionPaySheetPayment>>;
  sendedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AuctionPaySheetPaidData = {
  __typename?: 'AuctionPaySheetPaidData';
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type AuctionPaySheetPayment = {
  __typename?: 'AuctionPaySheetPayment';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type AuctionRating = {
  __typename?: 'AuctionRating';
  /** Аукцион */
  auction?: Maybe<Auction>;
  /** Колличество оценок */
  evaluationCount?: Maybe<Scalars['Int']['output']>;
  /** Колличество отзывов */
  feedbackCount?: Maybe<Scalars['Int']['output']>;
  /** Id */
  id?: Maybe<Scalars['Int']['output']>;
  /** Рейтинг аукциона */
  rating?: Maybe<Scalars['Float']['output']>;
};

export enum AuctionRemotenessEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type AuctionsCountryRestrictions = {
  __typename?: 'AuctionsCountryRestrictions';
  countryIso?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type AuctionsDealerFees = {
  __typename?: 'AuctionsDealerFees';
  commission?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  planId?: Maybe<Scalars['Int']['output']>;
};

export type AuctionsDeliveryCosts = {
  __typename?: 'AuctionsDeliveryCosts';
  costDelivery?: Maybe<Scalars['Float']['output']>;
  destinationCountryIso?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BalanceByOrganization = {
  __typename?: 'BalanceByOrganization';
  balance?: Maybe<Array<Money>>;
  organization?: Maybe<Organization>;
};

export type BankInvoice = {
  __typename?: 'BankInvoice';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  swift?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Bl = {
  __typename?: 'Bl';
  date?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  objects?: Maybe<Array<ObjectOfServices>>;
};

export type BlockTheIssuanceInput = {
  comment: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type BlockTheIssuanceOutput = {
  __typename?: 'BlockTheIssuanceOutput';
  error?: Maybe<MutationError>;
};

export type BookingRequest = {
  __typename?: 'BookingRequest';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  objectStateName?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  portFrom?: Maybe<Port>;
  sender?: Maybe<Sender>;
  senderCancelledAt?: Maybe<Scalars['DateTime']['output']>;
  trip?: Maybe<Trip>;
};

export type BookingRequestInput = {
  isBoundary: Scalars['Boolean']['input'];
  makerId?: InputMaybe<Scalars['Int']['input']>;
  markId?: InputMaybe<Scalars['Int']['input']>;
  mediaKeyS3ExportCertificate: Scalars['String']['input'];
  mediaKeysS3Documents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  objectState: ObjectStateEnum;
  tripId?: InputMaybe<Scalars['Int']['input']>;
  vin?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingRequestOutput = {
  __typename?: 'BookingRequestOutput';
  error?: Maybe<MutationError>;
};

export type Broker = {
  __typename?: 'Broker';
  engName?: Maybe<Scalars['String']['output']>;
  fullNameAgent?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
  powerOfAttorneyInfo?: Maybe<Scalars['String']['output']>;
};

export type BrokerExcelTemplate = {
  __typename?: 'BrokerExcelTemplate';
  broker?: Maybe<Broker>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isLegalEntity?: Maybe<Scalars['Boolean']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<BrokerExcelTemplateEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum BrokerExcelTemplateEnum {
  Address = 'address',
  Applicant = 'applicant',
  Color = 'color',
  Consignment = 'consignment',
  DateOfManufacture = 'date_of_manufacture',
  DryWeight = 'dry_weight',
  EngineModel = 'engine_model',
  EngineNumber = 'engine_number',
  EnginePower = 'engine_power',
  EngineType = 'engine_type',
  EngineVolume = 'engine_volume',
  GlonassModel = 'glonass_model',
  GlonassNumber = 'glonass_number',
  IndividualApplicant = 'individual_applicant',
  LegalApplicant = 'legal_applicant',
  Maker = 'maker',
  Mark = 'mark',
  MaxWeight = 'max_weight',
  Model = 'model',
  Ogrn = 'ogrn',
  Seats = 'seats',
  Vin = 'vin'
}

export type BrokersPaginatedList = {
  __typename?: 'BrokersPaginatedList';
  nodes: Array<Broker>;
  total: Scalars['Int']['output'];
};

export enum BucketTypeEnum {
  File = 'FILE',
  Image = 'IMAGE',
  Junk = 'JUNK',
  Video = 'VIDEO'
}

export type BuyLotteryTicketInput = {
  ticketId: Scalars['Int']['input'];
};

export type BuyLotteryTicketOutput = {
  __typename?: 'BuyLotteryTicketOutput';
  error?: Maybe<MutationError>;
};

export type CarClientParam = {
  __typename?: 'CarClientParam';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dealerId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasTelegram?: Maybe<Scalars['Boolean']['output']>;
  hasWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  isSendToChannel?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  priceInDestination?: Maybe<Price>;
  priceInJapan?: Maybe<Price>;
  priceOnDelivery?: Maybe<Price>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CarClientParamsUpdateInput = {
  currencyInDestination?: InputMaybe<Scalars['Int']['input']>;
  currencyInJapanId?: InputMaybe<Scalars['Int']['input']>;
  currencyOnDeliveryId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  priceInDestination?: InputMaybe<Scalars['Int']['input']>;
  priceInJapan?: InputMaybe<Scalars['Int']['input']>;
  priceOnDelivery?: InputMaybe<Scalars['Int']['input']>;
};

export type CarClientParamsUpdateOutput = {
  __typename?: 'CarClientParamsUpdateOutput';
  error?: Maybe<MutationError>;
};

export type CarFeedback = {
  __typename?: 'CarFeedback';
  /** Аукцион */
  auction?: Maybe<Auction>;
  /** Информация об обьекте */
  carInfo?: Maybe<CarInfo>;
  /** Дата создания */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Оценка 1-5 */
  evaluation?: Maybe<Scalars['Int']['output']>;
  /** Отзыв */
  feedback?: Maybe<Scalars['String']['output']>;
  /** Id */
  id?: Maybe<Scalars['Int']['output']>;
  /** Создатель отзыва */
  owner?: Maybe<OwnerFeedback>;
};

export type CarInPlantDayStatisticTotal = {
  __typename?: 'CarInPlantDayStatisticTotal';
  /** Всего зарегистрированно */
  count: Scalars['Int']['output'];
  /** На какой день зарегистрированно в рейс. Значение null для не заригистрированно. */
  dayDiff?: Maybe<Scalars['Int']['output']>;
  /** Всего зарегистрированно в % */
  percent: Scalars['Float']['output'];
};

export type CarInPlantStatistic = {
  __typename?: 'CarInPlantStatistic';
  /** Список аукционов за период */
  auctions?: Maybe<Array<Maybe<Auction>>>;
  /** Всего закупленно */
  buyCount: Scalars['Int']['output'];
  /** Период начало */
  dateFrom: Scalars['String']['output'];
  /** Период конец */
  dateTo: Scalars['String']['output'];
  /** Список дней за период */
  purchaseDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Зарегистрированно на рейс */
  registeredTotal: Scalars['Int']['output'];
  /** Зарегистрированно на рейс в % от buyCount */
  registeredTotalPercent: Scalars['Float']['output'];
  /** Статистка за период */
  statistic?: Maybe<Array<Maybe<CarInPlantDayStatisticTotal>>>;
};

export type CarInfo = {
  __typename?: 'CarInfo';
  /** Цвет */
  color?: Maybe<Scalars['String']['output']>;
  /** Производитель */
  maker?: Maybe<Scalars['String']['output']>;
  /** Марка */
  mark?: Maybe<Scalars['String']['output']>;
  /** Год выпуска */
  year?: Maybe<Scalars['String']['output']>;
};

export type CarMediaHiddenToggleInput = {
  carMediaId: Scalars['Int']['input'];
};

export type CarMediaHiddenToggleOutput = {
  __typename?: 'CarMediaHiddenToggleOutput';
  error?: Maybe<MutationError>;
};

export type Carrier = {
  __typename?: 'Carrier';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CarrierInfo = {
  __typename?: 'CarrierInfo';
  /** @deprecated Refactored */
  carrier?: Maybe<Scalars['Int']['output']>;
  carrierId?: Maybe<Scalars['Int']['output']>;
  carrierName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type CategoryCar = {
  __typename?: 'CategoryCar';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ChangeAdditionalTypeInput = {
  additionalTypeId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ChangeAdditionalTypeOutput = {
  __typename?: 'ChangeAdditionalTypeOutput';
  error?: Maybe<MutationError>;
};

export type ChangeDesiredTripInput = {
  objectId: Scalars['Int']['input'];
  tripId: Scalars['Int']['input'];
};

export type ChangeDesiredTripOutput = {
  __typename?: 'ChangeDesiredTripOutput';
  error?: Maybe<MutationError>;
};

export type ChangeExportPriceInput = {
  currencyId: Scalars['Int']['input'];
  exportPrice: Scalars['Float']['input'];
  objectId: Scalars['Int']['input'];
};

export type ChangeExportPriceOutput = {
  __typename?: 'ChangeExportPriceOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectBrokerInput = {
  brokerAddress?: InputMaybe<Scalars['String']['input']>;
  brokerContacts?: InputMaybe<Scalars['String']['input']>;
  brokerId?: InputMaybe<Scalars['Int']['input']>;
  brokerName?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ChangeObjectBrokerOutput = {
  __typename?: 'ChangeObjectBrokerOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectFormalOwnerInput = {
  formalOwnerId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ChangeObjectFormalOwnerOutput = {
  __typename?: 'ChangeObjectFormalOwnerOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectFreightCountryInput = {
  countryId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ChangeObjectFreightCountryOutput = {
  __typename?: 'ChangeObjectFreightCountryOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectOwnerInput = {
  objectId: Scalars['Int']['input'];
  ownerId: Scalars['Int']['input'];
};

export type ChangeObjectOwnerOutput = {
  __typename?: 'ChangeObjectOwnerOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectSenderInput = {
  objectId: Scalars['Int']['input'];
  senderId: Scalars['Int']['input'];
};

export type ChangeObjectSenderOutput = {
  __typename?: 'ChangeObjectSenderOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectTypeInput = {
  objectId: Scalars['Int']['input'];
  objectTypeId: Scalars['Int']['input'];
};

export type ChangeObjectTypeOutput = {
  __typename?: 'ChangeObjectTypeOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectVinInput = {
  newVin: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type ChangeObjectVinOutput = {
  __typename?: 'ChangeObjectVinOutput';
  error?: Maybe<MutationError>;
};

export type ChangeParentObjectInput = {
  objectId: Scalars['Int']['input'];
};

export type ChangeParentObjectOutput = {
  __typename?: 'ChangeParentObjectOutput';
  error?: Maybe<MutationError>;
};

export type ChangeSenderClientInput = {
  clientId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ChangeSenderClientOutput = {
  __typename?: 'ChangeSenderClientOutput';
  error?: Maybe<MutationError>;
};

export type Chassis = {
  __typename?: 'Chassis';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum ChassisTypeEnum {
  Chassis = 'chassis',
  Frame = 'frame'
}

export type ChatAccessUser = {
  __typename?: 'ChatAccessUser';
  accessGranted?: Maybe<User>;
  asObserver?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};

export type ChatAddGroupUsersInput = {
  chatId: Scalars['Int']['input'];
  groupId: Scalars['Int']['input'];
};

export type ChatAddGroupUsersOutput = {
  __typename?: 'ChatAddGroupUsersOutput';
  error?: Maybe<MutationError>;
};

export type ChatAddUserInput = {
  chatId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type ChatAddUserOutput = {
  __typename?: 'ChatAddUserOutput';
  error?: Maybe<MutationError>;
};

export type ChatAlias = {
  __typename?: 'ChatAlias';
  alias?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ChatAllFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatArchivingInput = {
  chatId: Scalars['Int']['input'];
};

export type ChatArchivingOutput = {
  __typename?: 'ChatArchivingOutput';
  error?: Maybe<MutationError>;
};

export type ChatDeleteMessageInput = {
  messageId: Scalars['Int']['input'];
};

export type ChatDeleteMessageOutput = {
  __typename?: 'ChatDeleteMessageOutput';
  error?: Maybe<MutationError>;
};

export type ChatForOthers = {
  __typename?: 'ChatForOthers';
  accessUsers?: Maybe<Array<ChatAccessUser>>;
  chatMembers?: Maybe<Array<Scalars['String']['output']>>;
  countTasksResolved?: Maybe<Scalars['Int']['output']>;
  countTasksUnresolved?: Maybe<Scalars['Int']['output']>;
  countUnreadMessages?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  lastMessage?: Maybe<ChatMessage>;
  messages?: Maybe<Array<ChatMessage>>;
  object?: Maybe<ObjectOfServices>;
  subject?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  ticket?: Maybe<Ticket>;
  toGroups?: Maybe<Array<ChatGroup>>;
};

export type ChatGroup = {
  __typename?: 'ChatGroup';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<UserShortInfo>>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use creatorName */
  creator?: Maybe<User>;
  creatorName?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  task?: Maybe<ChatMessageTask>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ChatMessageTask = {
  __typename?: 'ChatMessageTask';
  executor?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  solvedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChatMessagessFilter = {
  onlyTasks?: InputMaybe<OnlyTasksFilter>;
};

export type ChatMessagessPaginatedList = {
  __typename?: 'ChatMessagessPaginatedList';
  nodes: Array<ChatMessage>;
  total: Scalars['Int']['output'];
};

export type ChatObjectIdFilter = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type ChatRemoveUserInput = {
  id: Scalars['Int']['input'];
};

export type ChatRemoveUserOutput = {
  __typename?: 'ChatRemoveUserOutput';
  error?: Maybe<MutationError>;
};

export type ChatSearchFilter = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type ChatTagIdFilter = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type ChatUnArchivingInput = {
  chatId: Scalars['Int']['input'];
};

export type ChatUnArchivingOutput = {
  __typename?: 'ChatUnArchivingOutput';
  error?: Maybe<MutationError>;
};

export type ChatsForOtherssFilter = {
  asObserver?: InputMaybe<AsObserverFilter>;
  chatAll?: InputMaybe<ChatAllFilter>;
  chatObjectId?: InputMaybe<ChatObjectIdFilter>;
  chatSearch?: InputMaybe<ChatSearchFilter>;
  chatTagId?: InputMaybe<ChatTagIdFilter>;
  showArchive: ShowArchiveFilter;
  unResolvedTask?: InputMaybe<UnResolvedTaskFilter>;
  unread?: InputMaybe<UnreadFilter>;
  withUserMessages?: InputMaybe<WithUserMessagesFilter>;
};

export type ChatsForOtherssPaginatedList = {
  __typename?: 'ChatsForOtherssPaginatedList';
  nodes: Array<ChatForOthers>;
  total: Scalars['Int']['output'];
};

export type CheckDateOfManufactureInput = {
  objectId: Scalars['Int']['input'];
};

export type CheckDateOfManufactureOutput = {
  __typename?: 'CheckDateOfManufactureOutput';
  error?: Maybe<MutationError>;
};

export enum CityEnum {
  All = 'all',
  Otaru = 'otaru',
  Tomakomai = 'tomakomai',
  Toyama = 'toyama'
}

export type CityInfo = {
  __typename?: 'CityInfo';
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ClientContact = {
  __typename?: 'ClientContact';
  hasTelegram?: Maybe<Scalars['Boolean']['output']>;
  hasWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type ClientObjectAttachmentFilter = {
  partVin?: InputMaybe<Scalars['String']['input']>;
  /** Готовые к выдачи */
  showIssued?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};

export type ClientObjectAttachmentsFilter = {
  clientObjectAttachment?: InputMaybe<ClientObjectAttachmentFilter>;
};

export type ClientObjectAttachmentsPaginatedList = {
  __typename?: 'ClientObjectAttachmentsPaginatedList';
  nodes: Array<ObjectAttachment>;
  total: Scalars['Int']['output'];
};

export type ClientObjectDashboardFilter = {
  /** Заблокированные */
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  /** Готовые к выдаче */
  isHasIssuedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** С долгами */
  withDebts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientObjectLog = {
  __typename?: 'ClientObjectLog';
  guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  msg?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type ClientObjectLogFilter = {
  byGuid?: InputMaybe<Scalars['Boolean']['input']>;
  byVin?: InputMaybe<Scalars['Boolean']['input']>;
  isFull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientObjectLogsFilter = {
  clientObjectLog?: InputMaybe<ClientObjectLogFilter>;
};

export type ClientObjectLogsPaginatedList = {
  __typename?: 'ClientObjectLogsPaginatedList';
  nodes: Array<ClientObjectLog>;
  total: Scalars['Int']['output'];
};

export type ClientObjectQueue = {
  __typename?: 'ClientObjectQueue';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  routeKey?: Maybe<Scalars['String']['output']>;
};

export type ClientObjectQueuesPaginatedList = {
  __typename?: 'ClientObjectQueuesPaginatedList';
  nodes: Array<ClientObjectQueue>;
  total: Scalars['Int']['output'];
};

export type ClientObjectsFilter = {
  dealerObjects?: InputMaybe<DealerObjectsFilter>;
  objectAuctionListing?: InputMaybe<ObjectAuctionListingFilter>;
  objectGreenCorner?: InputMaybe<ObjectGreenCornerFilter>;
  objectOfService?: InputMaybe<ObjectOfServiceFilter>;
  objectPlan?: InputMaybe<ObjectPlanFilter>;
  objectWorldWide?: InputMaybe<ObjectWorldWideFilter>;
  scrap?: InputMaybe<ScrapFilter>;
};

export type ClientObjectsMonth = {
  __typename?: 'ClientObjectsMonth';
  countWithoutConsignee?: Maybe<Scalars['Int']['output']>;
  debts?: Maybe<Array<Price>>;
  hasPenaltyTax?: Maybe<Scalars['Boolean']['output']>;
  hasProposedPenalty?: Maybe<Scalars['Boolean']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ClientObjectsPaginatedList = {
  __typename?: 'ClientObjectsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export type ClientObjectsReportPaymentAkebonoAllowsPaginatedList = {
  __typename?: 'ClientObjectsReportPaymentAkebonoAllowsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export type ClientObjectsSort = {
  objectOfService?: InputMaybe<ObjectOfServiceSort>;
};

export type ClientObjectsTrip = {
  __typename?: 'ClientObjectsTrip';
  /** Кол-во готовых к выдаче */
  countHasIssuedStatus?: Maybe<Scalars['Int']['output']>;
  /** Кол-во без грузополучателя */
  countWithoutConsignee?: Maybe<Scalars['Int']['output']>;
  debts?: Maybe<Array<DebtPrice>>;
  id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  trip?: Maybe<Trip>;
};

export type ClientObjectsYear = {
  __typename?: 'ClientObjectsYear';
  months?: Maybe<Array<ClientObjectsMonth>>;
  year?: Maybe<Scalars['String']['output']>;
};

export type ClientRecipients = {
  __typename?: 'ClientRecipients';
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
  objectName?: Maybe<Scalars['String']['output']>;
  recipientObject?: Maybe<Scalars['String']['output']>;
  recipientRequest?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
};

export type ClientRecipientsPaginatedList = {
  __typename?: 'ClientRecipientsPaginatedList';
  nodes: Array<ClientRecipients>;
  total: Scalars['Int']['output'];
};

export type ClientUnionLog = {
  __typename?: 'ClientUnionLog';
  action?: Maybe<Scalars['String']['output']>;
  connection?: Maybe<Scalars['String']['output']>;
  /** Время создания события */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор события */
  guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jobFail?: Maybe<ExchangeJobFail>;
  /** Сообщение */
  msg?: Maybe<Scalars['String']['output']>;
  /** Параметры запроса */
  parameters?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  /** Время решения события */
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  resolvedMessage?: Maybe<Scalars['String']['output']>;
  /** Сообщение */
  response?: Maybe<Scalars['String']['output']>;
  /** Выборка из которой полученны данные */
  tableName?: Maybe<Scalars['String']['output']>;
  transport?: Maybe<Scalars['String']['output']>;
  /** Тип события */
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['String']['output']>;
  /** Идентификатор VIN обьекта */
  vin?: Maybe<Scalars['String']['output']>;
};

export type ClientUnionLogsPaginatedList = {
  __typename?: 'ClientUnionLogsPaginatedList';
  nodes: Array<ClientUnionLog>;
  total: Scalars['Int']['output'];
};

export type Color = {
  __typename?: 'Color';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameWithoutCode?: Maybe<Scalars['String']['output']>;
};

export type CompleteObjectInput = {
  objectId: Scalars['Int']['input'];
};

export type CompleteObjectOutput = {
  __typename?: 'CompleteObjectOutput';
  error?: Maybe<MutationError>;
};

export type CompletedServiceRequestInput = {
  cost: Scalars['Int']['input'];
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  param?: InputMaybe<CompletedServiceRequestParamInput>;
  requestId: Scalars['Int']['input'];
};

export type CompletedServiceRequestParamInput = {
  cost: Scalars['Int']['input'];
  paramId: Scalars['Int']['input'];
};

export type ComplexByInvoice = {
  __typename?: 'ComplexByInvoice';
  /** Комплекс услуг */
  complex?: Maybe<Scalars['Float']['output']>;
  /** Фрахт */
  freight?: Maybe<Scalars['Float']['output']>;
  /** Стоимость покупки */
  purchase?: Maybe<Scalars['Float']['output']>;
  /** Ресайкл */
  recycle?: Maybe<Scalars['Float']['output']>;
  /** Итого */
  total?: Maybe<Scalars['Float']['output']>;
};

export enum ConditionConsigneeEnum {
  /** Отсутствует */
  Empty = 'Empty',
  /** Обработан */
  Processed = 'Processed',
  /** Предоставлен */
  Provided = 'Provided',
  /** Отклонен */
  Rejected = 'Rejected'
}

export type Container = {
  __typename?: 'Container';
  /** Можно ли поставить объекты на данный рейс */
  canAddToPlan?: Maybe<AddToPlanAttention>;
  containerType?: Maybe<TypeOfContainer>;
  countObjects?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfLoadingAt?: Maybe<Scalars['DateTime']['output']>;
  events?: Maybe<Array<Maybe<ContainerEvent>>>;
  files?: Maybe<Array<S3File>>;
  forwarder?: Maybe<Forwarder>;
  freightType?: Maybe<TypeOfFreight>;
  id?: Maybe<Scalars['Int']['output']>;
  insurancePolicy?: Maybe<Scalars['String']['output']>;
  /** Можно ли выгрузить в страну */
  isCanUnloading?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  seaCarrier?: Maybe<SeaCarrier>;
  sendedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type ContainerCanAddToPlanArgs = {
  planId: Scalars['Int']['input'];
};

export type ContainerConfirmBookedInput = {
  containerId?: InputMaybe<Scalars['Int']['input']>;
  keyS3File?: InputMaybe<Scalars['String']['input']>;
};

export type ContainerConfirmBookedOutput = {
  __typename?: 'ContainerConfirmBookedOutput';
  error?: Maybe<MutationError>;
};

export type ContainerCreateInput = {
  dateOfLoading?: InputMaybe<Scalars['String']['input']>;
  insurancePolicy?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyKeyS3?: InputMaybe<Array<Scalars['String']['input']>>;
  number: Scalars['String']['input'];
  objects?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ContainerCreateOutput = {
  __typename?: 'ContainerCreateOutput';
  error?: Maybe<MutationError>;
};

export type ContainerDeleteInput = {
  id: Scalars['Int']['input'];
};

export type ContainerDeleteOutput = {
  __typename?: 'ContainerDeleteOutput';
  error?: Maybe<MutationError>;
};

export type ContainerEvent = {
  __typename?: 'ContainerEvent';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ContainerFilter = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  notSended?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export enum ContainerFilterEnum {
  All = 'all',
  WithContainer = 'with_container',
  WithoutContainer = 'without_container'
}

export type ContainerUpdateInput = {
  containerTypeId: Scalars['Int']['input'];
  dateOfLoading?: InputMaybe<Scalars['DateTime']['input']>;
  forwarderId?: InputMaybe<Scalars['Int']['input']>;
  freightTypeId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  insurancePolicy?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyKeyS3?: InputMaybe<Array<Scalars['String']['input']>>;
  number?: InputMaybe<Scalars['String']['input']>;
  seaCarrierId: Scalars['Int']['input'];
};

export type ContainerUpdateOutput = {
  __typename?: 'ContainerUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ContainersFilter = {
  container?: InputMaybe<ContainerFilter>;
};

export type ContainersPaginatedList = {
  __typename?: 'ContainersPaginatedList';
  nodes: Array<Container>;
  total: Scalars['Int']['output'];
};

export type Country = {
  __typename?: 'Country';
  alfa2?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  engName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CountryCreateInput = {
  alfa2: Scalars['String']['input'];
  code: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameEng: Scalars['String']['input'];
};

export type CountryCreateOutput = {
  __typename?: 'CountryCreateOutput';
  error?: Maybe<MutationError>;
};

export type CountryInfo = {
  __typename?: 'CountryInfo';
  engName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CountryStockFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  inJapan?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountrysFilter = {
  isPaymentCountry?: InputMaybe<IsPaymentCountryFilter>;
};

export type CountrysPaginatedList = {
  __typename?: 'CountrysPaginatedList';
  nodes: Array<Country>;
  total: Scalars['Int']['output'];
};

export type CreateChatInput = {
  isTask: Scalars['Boolean']['input'];
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  objectId?: InputMaybe<Scalars['Int']['input']>;
  subject: Scalars['String']['input'];
  toGroupsIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  toUsersIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CreateChatOutput = {
  __typename?: 'CreateChatOutput';
  error?: Maybe<MutationError>;
};

export type CreateDeliveryRequestInput = {
  carrierId?: InputMaybe<Scalars['Int']['input']>;
  clientPrice?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['Int']['input']>;
  deliveryPrice?: InputMaybe<Scalars['Int']['input']>;
  destinationId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
  sourceId: Scalars['Int']['input'];
};

export type CreateDeliveryRequestOutput = {
  __typename?: 'CreateDeliveryRequestOutput';
  error?: Maybe<MutationError>;
};

export type CreateInvoiceJapanAuctionPurchaseInput = {
  objectId: Scalars['Int']['input'];
};

export type CreateInvoiceJapanAuctionPurchaseOutput = {
  __typename?: 'CreateInvoiceJapanAuctionPurchaseOutput';
  error?: Maybe<MutationError>;
};

export type CreateObjectActInput = {
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
  subject: Scalars['String']['input'];
};

export type CreateObjectActOutput = {
  __typename?: 'CreateObjectActOutput';
  error?: Maybe<MutationError>;
};

export type CreateReceiptOfPaymentInput = {
  amount: Scalars['Int']['input'];
  currencyId: Scalars['Int']['input'];
  dateOfPayment: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  senderName: Scalars['String']['input'];
};

export type CreateReceiptOfPaymentOutput = {
  __typename?: 'CreateReceiptOfPaymentOutput';
  error?: Maybe<MutationError>;
};

export type CreateSbktsRequestInput = {
  ambassador: Scalars['Int']['input'];
  laboratory: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type CreateSbktsRequestOutput = {
  __typename?: 'CreateSbktsRequestOutput';
  error?: Maybe<MutationError>;
};

export type CreditStorage = {
  __typename?: 'CreditStorage';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  internationalSymbol?: Maybe<Scalars['String']['output']>;
  isoCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CurrencyEnum>;
};

export enum CurrencyEnum {
  Aed = 'AED',
  Cny = 'CNY',
  Eur = 'EUR',
  Jpy = 'JPY',
  Mnt = 'MNT',
  Php = 'PHP',
  Rub = 'RUB',
  Sgd = 'SGD',
  Usd = 'USD'
}

export type CurrencyFilter = {
  isPayment?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CurrentUserNotifyMessage = {
  __typename?: 'CurrentUserNotifyMessage';
  bot?: Maybe<NotifyBotInfo>;
  channels?: Maybe<Array<UserChannel>>;
  messageTypes?: Maybe<Array<NotifyMessage>>;
};

export type CustomPayment = {
  __typename?: 'CustomPayment';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomsClearanceTimeBySender = {
  __typename?: 'CustomsClearanceTimeBySender';
  byTypes?: Maybe<Array<CustomsClearanceTimeByTypes>>;
  senderName?: Maybe<Scalars['String']['output']>;
};

export type CustomsClearanceTimeByTypes = {
  __typename?: 'CustomsClearanceTimeByTypes';
  totalByInvoicePaymentCount?: Maybe<Scalars['Int']['output']>;
  totalByInvoicePaymentDay?: Maybe<Scalars['Int']['output']>;
  totalDays?: Maybe<Scalars['Int']['output']>;
  totalIndependentlyPaymentCount?: Maybe<Scalars['Int']['output']>;
  totalIndependentlyPaymentDay?: Maybe<Scalars['Int']['output']>;
  totalObjectsCount?: Maybe<Scalars['Int']['output']>;
  totalPrimbrokerPaymentCount?: Maybe<Scalars['Int']['output']>;
  totalPrimbrokerPaymentDay?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DealerObjectCompletedInput = {
  id: Scalars['Int']['input'];
};

export type DealerObjectCompletedOutput = {
  __typename?: 'DealerObjectCompletedOutput';
  error?: Maybe<MutationError>;
};

export type DealerObjectCreateInput = {
  colorEn: Scalars['String']['input'];
  dateOfManufacture?: InputMaybe<Scalars['String']['input']>;
  dealerId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  engineVolumeNum: Scalars['Float']['input'];
  equipmentEn: Scalars['String']['input'];
  hasTelegram?: InputMaybe<Scalars['Boolean']['input']>;
  hasWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished: Scalars['Boolean']['input'];
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  makerId: Scalars['Int']['input'];
  markId: Scalars['Int']['input'];
  mileageNum: Scalars['Int']['input'];
  modelGradeEn: Scalars['String']['input'];
  modelTypeEn: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  priceInDestination: Scalars['Float']['input'];
  priceInDestinationCurrencyId: Scalars['Int']['input'];
  transmissionEn: Scalars['String']['input'];
  userDescription?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export type DealerObjectCreateOutput = {
  __typename?: 'DealerObjectCreateOutput';
  error?: Maybe<MutationError>;
};

export type DealerObjectDeleteInput = {
  id: Scalars['Int']['input'];
};

export type DealerObjectDeleteOutput = {
  __typename?: 'DealerObjectDeleteOutput';
  error?: Maybe<MutationError>;
};

export type DealerObjectUpdateInput = {
  colorEn: Scalars['String']['input'];
  dateOfManufacture?: InputMaybe<Scalars['String']['input']>;
  dealerId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  engineVolumeNum: Scalars['Float']['input'];
  equipmentEn: Scalars['String']['input'];
  hasTelegram?: InputMaybe<Scalars['Boolean']['input']>;
  hasWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  isPublished: Scalars['Boolean']['input'];
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  makerId: Scalars['Int']['input'];
  markId: Scalars['Int']['input'];
  mileageNum: Scalars['Int']['input'];
  modelGradeEn: Scalars['String']['input'];
  modelTypeEn: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  priceInDestination: Scalars['Float']['input'];
  priceInDestinationCurrencyId: Scalars['Int']['input'];
  transmissionEn: Scalars['String']['input'];
  userDescription?: InputMaybe<Scalars['String']['input']>;
  vin: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export type DealerObjectUpdateOutput = {
  __typename?: 'DealerObjectUpdateOutput';
  error?: Maybe<MutationError>;
};

export type DealerObjectsFilter = {
  /** Дилерские объекты */
  isDealer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DebtPrice = {
  __typename?: 'DebtPrice';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DefaultOwner = {
  __typename?: 'DefaultOwner';
  address?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DeleteObjectInput = {
  objectId: Scalars['Int']['input'];
};

export type DeleteObjectOutput = {
  __typename?: 'DeleteObjectOutput';
  error?: Maybe<MutationError>;
};

export type DeleteUserNotifyMessageInput = {
  channelId: Scalars['Int']['input'];
};

export type DeleteUserNotifyMessageOutput = {
  __typename?: 'DeleteUserNotifyMessageOutput';
  error?: Maybe<MutationError>;
};

export enum DeliveryObjectStatusEnum {
  Active = 'active',
  Reject = 'reject'
}

export type DeliveryPlan = {
  __typename?: 'DeliveryPlan';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  countObjects?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryRequests?: Maybe<Array<DeliveryRequest>>;
  driver?: Maybe<Driver>;
  id?: Maybe<Scalars['Int']['output']>;
  isBeginDelivery?: Maybe<Scalars['Boolean']['output']>;
  numberPlan?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicle?: Maybe<Vehicle>;
};

export type DeliveryRequest = {
  __typename?: 'DeliveryRequest';
  assignCompanyAt?: Maybe<Scalars['DateTime']['output']>;
  carrier?: Maybe<Carrier>;
  carrierComment?: Maybe<Scalars['String']['output']>;
  clientPrice?: Maybe<Price>;
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdOver3Hours?: Maybe<Scalars['Boolean']['output']>;
  deliveredDate?: Maybe<Scalars['DateTime']['output']>;
  deliveryPrice?: Maybe<Price>;
  destinationStock?: Maybe<Stock>;
  driver?: Maybe<Driver>;
  driverBeginAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAddedToPlan?: Maybe<Scalars['Boolean']['output']>;
  isFound?: Maybe<Scalars['Boolean']['output']>;
  /** Наши японские доставки c аукциона, между складами */
  isOwnJapan?: Maybe<Scalars['Boolean']['output']>;
  /** Наши РФ доставки по перегонам */
  isOwnRussian?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  /** Состояние объекта доставки для Бархатной */
  objectState?: Maybe<ObjectDeliveryState>;
  plan?: Maybe<DeliveryPlan>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  sourceStock?: Maybe<Stock>;
  status?: Maybe<Status>;
};

export type DeliveryToStockInput = {
  objectId: Scalars['Int']['input'];
  sectorId?: InputMaybe<Scalars['Int']['input']>;
  stockId: Scalars['Int']['input'];
};

export type DeliveryToStockOutput = {
  __typename?: 'DeliveryToStockOutput';
  error?: Maybe<MutationError>;
};

export type DeliveryUnion = DeliveryRequest | ObjectDelivered;

export type DesiredTrip = {
  __typename?: 'DesiredTrip';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  draftObject?: Maybe<DraftFreightObject>;
  id?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<ObjectOfServices>;
  trip?: Maybe<Trip>;
};

export type DetailedRegistrationAddress = {
  __typename?: 'DetailedRegistrationAddress';
  cityName?: Maybe<Scalars['String']['output']>;
  districtName?: Maybe<Scalars['String']['output']>;
  flat?: Maybe<Scalars['String']['output']>;
  flatType?: Maybe<TypeOfFlat>;
  house?: Maybe<Scalars['String']['output']>;
  houseType?: Maybe<TypeOfHouse>;
  housing?: Maybe<Scalars['String']['output']>;
  housingType?: Maybe<TypeOfHousing>;
  localityName?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  regionName?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
};

export type DiagnosticDoc = {
  __typename?: 'DiagnosticDoc';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  issuedDate?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DiffEntityFilter = {
  entityId?: InputMaybe<Scalars['Int']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
};

export type Digest = {
  __typename?: 'Digest';
  name?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
};

export type DisallowVisiblePhotoInput = {
  objectId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type DisallowVisiblePhotoOutput = {
  __typename?: 'DisallowVisiblePhotoOutput';
  error?: Maybe<MutationError>;
};

export type Disassemble = {
  __typename?: 'Disassemble';
  backPart?: Maybe<Scalars['Boolean']['output']>;
  enginePart?: Maybe<Scalars['Boolean']['output']>;
  forwardPart?: Maybe<Scalars['Boolean']['output']>;
  framePart?: Maybe<Scalars['Boolean']['output']>;
  palletPart?: Maybe<Scalars['Boolean']['output']>;
};

export enum DisassemblyInfoEnum {
  Manual = 'manual',
  Notice = 'notice'
}

export type DisassemblyPlan = {
  __typename?: 'DisassemblyPlan';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']['output']>;
  isDelete?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  saw?: Maybe<TypeOfSaw>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum DisassemblyStateEnum {
  Active = 'active',
  Await = 'await',
  Completed = 'completed',
  Finish = 'finish',
  FinishNotSend = 'finish_not_send',
  Processing = 'processing'
}

export enum DiskTypeEnum {
  Aluminum = 'ALUMINUM',
  Iron = 'IRON'
}

export type DisplayBidsCheckInput = {
  agentId: Scalars['Int']['input'];
};

export type DisplayBidsCheckOutput = {
  __typename?: 'DisplayBidsCheckOutput';
  error?: Maybe<MutationError>;
};

export type DisposalsPaginatedList = {
  __typename?: 'DisposalsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export type DocumentAdditionField = {
  __typename?: 'DocumentAdditionField';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldName?: Maybe<DocumentFieldName>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DocumentField = {
  __typename?: 'DocumentField';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameId?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueId?: Maybe<Scalars['Int']['output']>;
};

export type DocumentFieldName = {
  __typename?: 'DocumentFieldName';
  description?: Maybe<Scalars['String']['output']>;
  docName?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isProtocol?: Maybe<Scalars['Boolean']['output']>;
  isRegularFill?: Maybe<Scalars['Boolean']['output']>;
  laboratory?: Maybe<Laboratory>;
  position?: Maybe<Scalars['Int']['output']>;
};

export type DocumentProtocol = {
  __typename?: 'DocumentProtocol';
  additionalFields?: Maybe<Array<DocumentAdditionField>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  docTemplate?: Maybe<DocumentTemplate>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTechnical?: Maybe<Scalars['Boolean']['output']>;
  model?: Maybe<Model>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  comment?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<DocumentField>>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isElectricCar?: Maybe<Scalars['Boolean']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  maker?: Maybe<DocumentValue>;
  mark?: Maybe<DocumentValue>;
  model?: Maybe<DocumentValue>;
  name?: Maybe<Scalars['String']['output']>;
  objectMaker?: Maybe<Maker>;
  objectMark?: Maybe<Mark>;
  objectModel?: Maybe<Model>;
  protocols?: Maybe<Array<DocumentProtocol>>;
  title?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type DocumentValue = {
  __typename?: 'DocumentValue';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DocxTemplate = {
  __typename?: 'DocxTemplate';
  categoriesCar?: Maybe<Array<CategoryCar>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  documentType?: Maybe<TypeOfDocumentEnum>;
  fileFizik?: Maybe<S3File>;
  fileUrik?: Maybe<S3File>;
  filesOld?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']['output']>;
  internalUse?: Maybe<Scalars['Boolean']['output']>;
  isProcessed?: Maybe<Scalars['Boolean']['output']>;
  laboratory?: Maybe<Laboratory>;
  name?: Maybe<Scalars['String']['output']>;
  needSignDeputy?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Organization>;
  protocolToFgis?: Maybe<Scalars['Boolean']['output']>;
  pteToFgis?: Maybe<Scalars['Boolean']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  template?: Maybe<DocxTemplateInfo>;
};

export type DocxTemplateInfo = {
  __typename?: 'DocxTemplateInfo';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DonateBuhanka = {
  __typename?: 'DonateBuhanka';
  collected?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type DonateBuhankaInput = {
  amount: Scalars['Int']['input'];
};

export type DonateBuhankaOutput = {
  __typename?: 'DonateBuhankaOutput';
  error?: Maybe<MutationError>;
};

export type DraftBroker = {
  __typename?: 'DraftBroker';
  address?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DraftClient = {
  __typename?: 'DraftClient';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  info?: Maybe<Scalars['String']['output']>;
};

export type DraftFreightObject = {
  __typename?: 'DraftFreightObject';
  auction?: Maybe<Auction>;
  broker?: Maybe<Broker>;
  client?: Maybe<Owner>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateOfManufacture?: Maybe<Scalars['DateTime']['output']>;
  desiredTrip?: Maybe<DesiredTrip>;
  draftBroker?: Maybe<DraftBroker>;
  draftClient?: Maybe<DraftClient>;
  exportPrice?: Maybe<Price>;
  id?: Maybe<Scalars['Int']['output']>;
  isBoundary?: Maybe<Scalars['Boolean']['output']>;
  maker?: Maybe<Maker>;
  mark?: Maybe<Mark>;
  name?: Maybe<Scalars['String']['output']>;
  objectStateName?: Maybe<ObjectStateEnum>;
  payCountry?: Maybe<Country>;
  sender?: Maybe<Sender>;
  senderClient?: Maybe<DraftSenderClient>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type DraftSenderClient = {
  __typename?: 'DraftSenderClient';
  agent?: Maybe<Agent>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Driver = {
  __typename?: 'Driver';
  carrier?: Maybe<Carrier>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
};

export type DriverMode = {
  __typename?: 'DriverMode';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Elpts = {
  __typename?: 'Elpts';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  issuedDate?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum EmailObjectNotificationEnum {
  /** Предоставьте паспорт с пропиской */
  GetPassportWithRegistration = 'GET_PASSPORT_WITH_REGISTRATION',
  /** Неверная регистрация физлица в СЭП */
  WrongEpsRegistration = 'WRONG_EPS_REGISTRATION'
}

export type Engine = {
  __typename?: 'Engine';
  engineType?: Maybe<TypeOfEngine>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type ExchangeJob = {
  __typename?: 'ExchangeJob';
  action?: Maybe<Scalars['String']['output']>;
  connection?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jobFail?: Maybe<ExchangeJobFail>;
  parameters?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  resolvedMessage?: Maybe<Scalars['String']['output']>;
  transport?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ExchangeJobCloseInput = {
  id: Scalars['Int']['input'];
};

export type ExchangeJobCloseOutput = {
  __typename?: 'ExchangeJobCloseOutput';
  error?: Maybe<MutationError>;
};

export type ExchangeJobFail = {
  __typename?: 'ExchangeJobFail';
  attempts?: Maybe<Scalars['Int']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ExchangeJobFilter = {
  action?: InputMaybe<Scalars['String']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  isHasFail?: InputMaybe<Scalars['Boolean']['input']>;
  isNotResolve?: InputMaybe<Scalars['Boolean']['input']>;
  isTroyan?: InputMaybe<Scalars['Boolean']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  transport?: InputMaybe<Scalars['String']['input']>;
};

export type ExchangeJobSendInput = {
  id: Scalars['Int']['input'];
};

export type ExchangeJobSendOutput = {
  __typename?: 'ExchangeJobSendOutput';
  error?: Maybe<MutationError>;
};

export type ExchangeJobsFilter = {
  exchangeJob?: InputMaybe<ExchangeJobFilter>;
};

export type ExchangeJobsPaginatedList = {
  __typename?: 'ExchangeJobsPaginatedList';
  nodes: Array<ExchangeJob>;
  total: Scalars['Int']['output'];
};

/** Модель с данными для заполнения ежедневных измерений */
export type ExpertDailyParam = {
  __typename?: 'ExpertDailyParam';
  /** Аффикс для названия передается во ФГИС */
  affixText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Ключ в документах */
  docName?: Maybe<Scalars['String']['output']>;
  /** Подгруппа параметров */
  groupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Лаборатория */
  laboratory?: Maybe<Laboratory>;
  /** Название параметра */
  name?: Maybe<Scalars['String']['output']>;
  /** Порядок отображения */
  position?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ExpertData = {
  __typename?: 'ExpertData';
  categoriesCar?: Maybe<Array<CategoryCar>>;
  certificateNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  docName?: Maybe<Scalars['String']['output']>;
  factoryN?: Maybe<Scalars['String']['output']>;
  /** Конец эксплуатации инструмента */
  finishServiceAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inventoryN?: Maybe<Scalars['String']['output']>;
  inventoryNumber?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** Начало эксплуатации инструмента */
  startServiceAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  validityPeriod?: Maybe<Scalars['String']['output']>;
};

export type ExportCertificate = {
  __typename?: 'ExportCertificate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Int']['output']>;
  issuedStatus?: Maybe<StatusToObject>;
  number?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  receivedOriginal?: Maybe<Scalars['Int']['output']>;
  receivedStatus?: Maybe<StatusToObject>;
  returnAt?: Maybe<Scalars['DateTime']['output']>;
  returnReason?: Maybe<Scalars['String']['output']>;
  scheduledDate?: Maybe<Scalars['String']['output']>;
};

export type ExportDeclaration = {
  __typename?: 'ExportDeclaration';
  bookingNo?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  registrationDate?: Maybe<Scalars['DateTime']['output']>;
};

export type ExportDeclarationAddObjectsInput = {
  id: Scalars['Int']['input'];
  objectIds: Array<Scalars['Int']['input']>;
};

export type ExportDeclarationAddObjectsOutput = {
  __typename?: 'ExportDeclarationAddObjectsOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationCreateInput = {
  bookingNo: Scalars['String']['input'];
  objectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  registrationDate: Scalars['DateTime']['input'];
  tripId: Scalars['Int']['input'];
};

export type ExportDeclarationCreateOutput = {
  __typename?: 'ExportDeclarationCreateOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationPaidInput = {
  id: Scalars['Int']['input'];
  keyS3File: Scalars['String']['input'];
  paidAt: Scalars['DateTime']['input'];
};

export type ExportDeclarationPaidOutput = {
  __typename?: 'ExportDeclarationPaidOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationRemoveObjectInput = {
  id: Scalars['Int']['input'];
  objectIds: Scalars['Int']['input'];
};

export type ExportDeclarationRemoveObjectOutput = {
  __typename?: 'ExportDeclarationRemoveObjectOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationUpdateInput = {
  bookingNo: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  registrationDate: Scalars['DateTime']['input'];
  tripId: Scalars['Int']['input'];
};

export type ExportDeclarationUpdateOutput = {
  __typename?: 'ExportDeclarationUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ExportToAuctionInput = {
  auctionId: Scalars['Int']['input'];
  driverId?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ExportToAuctionOutput = {
  __typename?: 'ExportToAuctionOutput';
  error?: Maybe<MutationError>;
};

export type ExportTrip = {
  __typename?: 'ExportTrip';
  cancelReason?: Maybe<Scalars['String']['output']>;
  declarationFile?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']['output']>;
  invoiceFile?: Maybe<S3File>;
  isCancelled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExportTripsFilter = {
  trip?: InputMaybe<TripFilter>;
};

export type ExportTripsPaginatedList = {
  __typename?: 'ExportTripsPaginatedList';
  nodes: Array<Trip>;
  total: Scalars['Int']['output'];
};

export type ExportTripsSort = {
  trip?: InputMaybe<TripSort>;
};

export type FastObjects = {
  __typename?: 'FastObjects';
  all?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<ObjectOfServices>;
  stockToPort?: Maybe<Scalars['Int']['output']>;
  toStock?: Maybe<Scalars['Int']['output']>;
};

export type FastObjectsByTrip = {
  __typename?: 'FastObjectsByTrip';
  byTypes?: Maybe<Array<FastObjectsByTypeOf>>;
  plan?: Maybe<Plan>;
};

export type FastObjectsByTypeOf = {
  __typename?: 'FastObjectsByTypeOf';
  objects?: Maybe<Array<FastObjects>>;
  type?: Maybe<TypeOfExport>;
};

export type FeedbackFilter = {
  /** Период начало */
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  /** Период конец */
  dateTo?: InputMaybe<Scalars['String']['input']>;
  /** По отценке */
  evaluation?: InputMaybe<Scalars['Int']['input']>;
  /** По содержимому отзыва */
  feedback?: InputMaybe<Scalars['String']['input']>;
  /** По пользователю */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type FeedbackssFilter = {
  feedback?: InputMaybe<FeedbackFilter>;
};

export type FeedbackssPaginatedList = {
  __typename?: 'FeedbackssPaginatedList';
  nodes: Array<CarFeedback>;
  total: Scalars['Int']['output'];
};

/** Статусы загрузки файлов для таможни */
export enum FileCustomDocumentStatusEnum {
  /** Подтвержден */
  Accepted = 'ACCEPTED',
  /** Новый файл */
  New = 'NEW',
  /** Не изменялся */
  NotChanged = 'NOT_CHANGED'
}

export type FileDeleteInput = {
  fileId: Scalars['Int']['input'];
};

export type FileDeleteOutput = {
  __typename?: 'FileDeleteOutput';
  error?: Maybe<MutationError>;
};

export type FinanceReport = {
  __typename?: 'FinanceReport';
  balanceIn?: Maybe<Scalars['Float']['output']>;
  balanceOut?: Maybe<Scalars['Float']['output']>;
  contractName?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  documents?: Maybe<Array<FinanceReportDocument>>;
};

export type FinanceReportDocument = {
  __typename?: 'FinanceReportDocument';
  comment?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  objects?: Maybe<Array<FinanceReportObject>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FinanceReportObject = {
  __typename?: 'FinanceReportObject';
  amountIn?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  balanceIn?: Maybe<Scalars['Float']['output']>;
  object?: Maybe<ObjectOfServices>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type FormalOwner = {
  __typename?: 'FormalOwner';
  bank?: Maybe<Scalars['String']['output']>;
  bik?: Maybe<Scalars['String']['output']>;
  dataForDocuments?: Maybe<OwnerData>;
  dateOfBirthday?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  documentIssuedAt?: Maybe<Scalars['DateTime']['output']>;
  documentIssuedUnit?: Maybe<Scalars['String']['output']>;
  documentIssuedUnitName?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  innFile?: Maybe<S3File>;
  isLegalPerson?: Maybe<Scalars['Boolean']['output']>;
  kpp?: Maybe<Scalars['String']['output']>;
  ks?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
  officialName?: Maybe<Scalars['String']['output']>;
  ogrn?: Maybe<Scalars['String']['output']>;
  ogrnFile?: Maybe<S3File>;
  passportNumber?: Maybe<Scalars['String']['output']>;
  passportSerial?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Scalars['String']['output']>>;
  placeOfBirthday?: Maybe<Scalars['String']['output']>;
  registrationAddress?: Maybe<RegistrationAddress>;
  rs?: Maybe<Scalars['String']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
};

export enum FormalOwnerStateEnum {
  FormalOwner = 'FORMAL_OWNER',
  None = 'NONE',
  Recipient = 'RECIPIENT'
}

export type FormalOwnersPaginatedList = {
  __typename?: 'FormalOwnersPaginatedList';
  nodes: Array<FormalOwner>;
  total: Scalars['Int']['output'];
};

export type Forwarder = {
  __typename?: 'Forwarder';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ForwarderCreateInput = {
  name: Scalars['String']['input'];
};

export type ForwarderCreateOutput = {
  __typename?: 'ForwarderCreateOutput';
  error?: Maybe<MutationError>;
};

export type ForwarderDeleteInput = {
  id: Scalars['Int']['input'];
};

export type ForwarderDeleteOutput = {
  __typename?: 'ForwarderDeleteOutput';
  error?: Maybe<MutationError>;
};

export type ForwarderUpdateInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type ForwarderUpdateOutput = {
  __typename?: 'ForwarderUpdateOutput';
  error?: Maybe<MutationError>;
};

export type GetAgentsByParamsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fio: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type GetAgentsByParamsOutput = {
  __typename?: 'GetAgentsByParamsOutput';
  agents?: Maybe<Array<OwnerWithAgreements>>;
  error?: Maybe<MutationError>;
};

export type GreenCornerObjects = {
  __typename?: 'GreenCornerObjects';
  /** Всего ЗУ с грузополучателем */
  all?: Maybe<Scalars['Int']['output']>;
  /** кол-во объектов не добавленных в список ожидания */
  notInAwaitingList?: Maybe<Scalars['Int']['output']>;
  /** Объекты не добавленные в список ожидания */
  objects?: Maybe<Array<Maybe<ObjectOfServices>>>;
};

export type GreenCornerStoragePeriod = {
  __typename?: 'GreenCornerStoragePeriod';
  period?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
};

export type Inventory = {
  __typename?: 'Inventory';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  sectors?: Maybe<Array<Sector>>;
  solver?: Maybe<User>;
  stock?: Maybe<Stock>;
};

export type InventoryObject = {
  __typename?: 'InventoryObject';
  checkedAt?: Maybe<Scalars['DateTime']['output']>;
  checker?: Maybe<User>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inventory?: Maybe<Inventory>;
  inventorySource?: Maybe<Scalars['String']['output']>;
  isFound?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  objectName?: Maybe<Scalars['String']['output']>;
  sector?: Maybe<Sector>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Последний запрос на изменение */
  activeUpdateRequest?: Maybe<InvoiceUpdateRequest>;
  amount?: Maybe<Scalars['Float']['output']>;
  amountSupplier?: Maybe<Scalars['Float']['output']>;
  client?: Maybe<Owner>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  isSendSupplier?: Maybe<Scalars['Boolean']['output']>;
  isSent?: Maybe<Scalars['Boolean']['output']>;
  isSentTo1C?: Maybe<Scalars['Boolean']['output']>;
  isShow1c?: Maybe<Scalars['Boolean']['output']>;
  nomenclaturesList?: Maybe<Array<Maybe<InvoiceNomenclatureItem>>>;
  number?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  organization?: Maybe<Organization>;
  sender?: Maybe<Sender>;
  type?: Maybe<InvoiceType>;
  /** Запросы на изменение */
  updateRequests?: Maybe<Array<InvoiceUpdateRequest>>;
};

export type InvoiceByObjectsGenerateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  advance?: InputMaybe<Scalars['Float']['input']>;
  currencyId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameRus: Scalars['String']['input'];
  logoKeyS3?: InputMaybe<Scalars['String']['input']>;
  logoState: LogoStateEnum;
  objects: Array<InputMaybe<ObjectInvoiceItemInput>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  portName?: InputMaybe<Scalars['String']['input']>;
  useAdvance?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceByObjectsGenerateOutput = {
  __typename?: 'InvoiceByObjectsGenerateOutput';
  error?: Maybe<MutationError>;
  invoice?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCustom = {
  __typename?: 'InvoiceCustom';
  address?: Maybe<Scalars['String']['output']>;
  advance?: Maybe<Scalars['Float']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  customInvoiceUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameRu?: Maybe<Scalars['String']['output']>;
  hasCustomLogo?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invoiceType?: Maybe<InvoiceType>;
  number?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  objectsInvoice?: Maybe<Array<InvoiceCustomObject>>;
  owner?: Maybe<Owner>;
  phone?: Maybe<Scalars['String']['output']>;
  purposeMessage?: Maybe<Scalars['String']['output']>;
  toBase64?: Maybe<Scalars['String']['output']>;
  type?: Maybe<InvoiceCustomTypeEnum>;
  useDeposit?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoiceCustomGenerateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Float']['input'];
  currencyId: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fullNameRus: Scalars['String']['input'];
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  purposeMessage?: InputMaybe<Scalars['String']['input']>;
  type: InvoiceCustomTypeEnum;
};

export type InvoiceCustomGenerateOutput = {
  __typename?: 'InvoiceCustomGenerateOutput';
  error?: Maybe<MutationError>;
  invoice?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCustomObject = {
  __typename?: 'InvoiceCustomObject';
  amount?: Maybe<Scalars['Float']['output']>;
  complexAmount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  freight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<ObjectOfServices>;
};

export enum InvoiceCustomTypeEnum {
  Advance = 'advance',
  Default = 'default',
  Deposit = 'deposit',
  YahooDeposit = 'yahoo_deposit'
}

export type InvoiceCustomerInfo = {
  __typename?: 'InvoiceCustomerInfo';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  fullNameRu?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCustomerInfosPaginatedList = {
  __typename?: 'InvoiceCustomerInfosPaginatedList';
  nodes: Array<InvoiceCustomerInfo>;
  total: Scalars['Int']['output'];
};

export enum InvoiceEmailTypeEnum {
  Accountant = 'ACCOUNTANT',
  Client = 'CLIENT'
}

export type InvoiceFilter = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  invoiceType?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceLog = {
  __typename?: 'InvoiceLog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  newAmount?: Maybe<Scalars['Int']['output']>;
  nomenclature?: Maybe<Nomenclature>;
  object?: Maybe<ObjectOfServices>;
  oldAmount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};

export type InvoiceLogsPaginatedList = {
  __typename?: 'InvoiceLogsPaginatedList';
  nodes: Array<InvoiceLog>;
  total: Scalars['Int']['output'];
};

export type InvoiceNomenclatureItem = {
  __typename?: 'InvoiceNomenclatureItem';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nomenclature?: Maybe<Nomenclature>;
};

export type InvoiceSearch = {
  __typename?: 'InvoiceSearch';
  amount?: Maybe<Scalars['Float']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currencyIso?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type InvoiceServiceInput = {
  amount: Scalars['Int']['input'];
  nomenclature: Scalars['Int']['input'];
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InvoiceUpdateRequest = {
  __typename?: 'InvoiceUpdateRequest';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  data?: Maybe<Array<InvoiceUpdateRequestData>>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Номенклатуры, затронутые в данном запросе */
  nomenclatures?: Maybe<Array<Nomenclature>>;
};

export type InvoiceUpdateRequestData = {
  __typename?: 'InvoiceUpdateRequestData';
  newValue?: Maybe<Scalars['Int']['output']>;
  nomenclatureId?: Maybe<Scalars['Int']['output']>;
  oldValue?: Maybe<Scalars['Int']['output']>;
};

export type InvoicesSearchsFilter = {
  invoice: InvoiceFilter;
};

export type InvoicesSearchsPaginatedList = {
  __typename?: 'InvoicesSearchsPaginatedList';
  nodes: Array<InvoiceSearch>;
  total: Scalars['Int']['output'];
};

export type IsPaymentCountryFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IssueBlock = {
  __typename?: 'IssueBlock';
  comment?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<IssueBlockedSourceEnum>;
};

export enum IssueBlockedSourceEnum {
  Navis = 'navis',
  Sender = 'sender',
  TemporaryStorage = 'temporary_storage',
  Trip = 'trip'
}

export type IssueObjectInput = {
  objectId: Scalars['Int']['input'];
};

export type IssueObjectOutput = {
  __typename?: 'IssueObjectOutput';
  error?: Maybe<MutationError>;
};

/** Квинтанция расходов по Японии */
export type JapanPayment = {
  __typename?: 'JapanPayment';
  /** Кол-во заявок */
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  /** Водитель выбрранный для начисления расходов */
  driver?: Maybe<Driver>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Заявки на доставк, сгон и объект за этот период */
  japanPaymentsToRequests?: Maybe<Array<Maybe<JapanPaymentsToRequest>>>;
  /** Статус обработки */
  status?: Maybe<Scalars['Boolean']['output']>;
  /** Общая сумма в иенах */
  totalAmount?: Maybe<Scalars['Int']['output']>;
  /** Тип начисления */
  type?: Maybe<TypeOfJapanPaymentEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Юзер создавший запись */
  user?: Maybe<User>;
};

/** Объект на который произвели начисление со связью к заявке на доставку или сгон */
export type JapanPaymentsToRequest = {
  __typename?: 'JapanPaymentsToRequest';
  /** Начисленная сумма */
  amount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Статус отправки в 1С */
  isSend?: Maybe<Scalars['Boolean']['output']>;
  /** Квинтанция расходов по Японии */
  japanPayment?: Maybe<JapanPayment>;
  /** Объект */
  object?: Maybe<ObjectOfServices>;
  /** Заявка с аукциона или сгон */
  request?: Maybe<DeliveryUnion>;
};

export type KaitaiAutoResubmitChangeStateInput = {
  state: KaitaiAutoResubmitStateEnum;
};

export type KaitaiAutoResubmitChangeStateOutput = {
  __typename?: 'KaitaiAutoResubmitChangeStateOutput';
  error?: Maybe<MutationError>;
  state: KaitaiAutoResubmitStateEnum;
};

export enum KaitaiAutoResubmitStateEnum {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type KaitaiParam = {
  __typename?: 'KaitaiParam';
  id?: Maybe<Scalars['Int']['output']>;
  purchasePrice?: Maybe<Price>;
};

export type KaitaiQueueStatusChangeInput = {
  state: Scalars['Boolean']['input'];
};

export type KaitaiQueueStatusChangeOutput = {
  __typename?: 'KaitaiQueueStatusChangeOutput';
  error?: Maybe<MutationError>;
  queueStatus?: Maybe<QueueStatus>;
};

export type Laboratory = {
  __typename?: 'Laboratory';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deputyDirectorFirstName?: Maybe<Scalars['String']['output']>;
  deputyDirectorLastName?: Maybe<Scalars['String']['output']>;
  deputyDirectorPatronymic?: Maybe<Scalars['String']['output']>;
  deputyThumbprint?: Maybe<Scalars['String']['output']>;
  directorFirstName?: Maybe<Scalars['String']['output']>;
  directorLastName?: Maybe<Scalars['String']['output']>;
  directorPatronymic?: Maybe<Scalars['String']['output']>;
  experts?: Maybe<Array<LaboratoryExpert>>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAlias?: Maybe<Scalars['String']['output']>;
  numberAccredited?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  thumbprint?: Maybe<Scalars['String']['output']>;
};

export type LaboratoryExpert = {
  __typename?: 'LaboratoryExpert';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Инструменты эксперта */
  expertData?: Maybe<Array<ExpertData>>;
  /** Инструменты эксперта (удаленные) */
  expertDataDeleted?: Maybe<Array<ExpertData>>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ignoreSendToFgis?: Maybe<Scalars['Boolean']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isJunior?: Maybe<Scalars['Boolean']['output']>;
  isSignDeputy?: Maybe<Scalars['Boolean']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** Стажёры */
  juniors?: Maybe<Array<LaboratoryExpert>>;
  laboratory?: Maybe<Laboratory>;
  name?: Maybe<Scalars['String']['output']>;
  parentExpert?: Maybe<LaboratoryExpert>;
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Получатели транспортного средства */
  receivers?: Maybe<Array<LaboratoryExpert>>;
  secondName?: Maybe<Scalars['String']['output']>;
  thumbprint?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export enum LanguageEnum {
  En = 'en',
  Ru = 'ru'
}

export type LimitsPlan = {
  __typename?: 'LimitsPlan';
  addExtraDays?: Maybe<Array<Scalars['String']['output']>>;
  generatePlanDays?: Maybe<Array<Scalars['String']['output']>>;
  limit?: Maybe<Scalars['Int']['output']>;
};

export type Log = {
  __typename?: 'Log';
  id?: Maybe<Scalars['Int']['output']>;
  isJson?: Maybe<Scalars['Boolean']['output']>;
  msg?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LogAgentEvent = {
  __typename?: 'LogAgentEvent';
  agent?: Maybe<Owner>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  serviceCode?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LogAgentEventFilter = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type LogAgentEventsFilter = {
  logAgentEvent?: InputMaybe<LogAgentEventFilter>;
};

export type LogAgentEventsPaginatedList = {
  __typename?: 'LogAgentEventsPaginatedList';
  nodes: Array<LogAgentEvent>;
  total: Scalars['Int']['output'];
};

export type LogEntity = {
  __typename?: 'LogEntity';
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  newData?: Maybe<Scalars['String']['output']>;
  oldData?: Maybe<Scalars['String']['output']>;
  operation?: Maybe<Scalars['String']['output']>;
  serviceCode?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LogFilter = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['Int']['input']>;
};

export enum LogicFileTypeEnum {
  FeedbackAttachment = 'FEEDBACK_ATTACHMENT'
}

export enum LogoStateEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Without = 'WITHOUT'
}

export type LogsFilter = {
  log?: InputMaybe<LogFilter>;
};

export type LogsPaginatedList = {
  __typename?: 'LogsPaginatedList';
  nodes: Array<Log>;
  total: Scalars['Int']['output'];
};

export type LogsUserRequest = {
  __typename?: 'LogsUserRequest';
  bodyParams?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  httpRequestMethod?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  remoteIp?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userAgent?: Maybe<Scalars['String']['output']>;
  userIp?: Maybe<Scalars['String']['output']>;
};

export type LogsUserRequestFilter = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['Int']['input']>;
};

export type LogsUserRequestsFilter = {
  logsUserRequest?: InputMaybe<LogsUserRequestFilter>;
};

export type LogsUserRequestsPaginatedList = {
  __typename?: 'LogsUserRequestsPaginatedList';
  nodes: Array<LogsUserRequest>;
  total: Scalars['Int']['output'];
};

export type LotTranslation = {
  __typename?: 'LotTranslation';
  file?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  translator?: Maybe<LotTranslator>;
};

export type LotTranslator = {
  __typename?: 'LotTranslator';
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type LotteryTicker = {
  __typename?: 'LotteryTicker';
  agentId?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ticketId?: Maybe<Scalars['Int']['output']>;
};

export type LotteryTicket = {
  __typename?: 'LotteryTicket';
  isVacant?: Maybe<Scalars['Boolean']['output']>;
  ticketId?: Maybe<Scalars['Int']['output']>;
};

export type MagadanObjectCreateInput = {
  attachments: Scalars['String']['input'];
  carrierId?: InputMaybe<Scalars['Int']['input']>;
  chassisTypeId?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  countKeys: Scalars['Int']['input'];
  hasSdCard?: InputMaybe<Scalars['Boolean']['input']>;
  isArrived?: InputMaybe<Scalars['Boolean']['input']>;
  isBroken?: InputMaybe<Scalars['Boolean']['input']>;
  isNoneKeys?: InputMaybe<Scalars['Boolean']['input']>;
  isNotDrivable?: InputMaybe<Scalars['Boolean']['input']>;
  isRadCheck?: InputMaybe<Scalars['Boolean']['input']>;
  makerId: Scalars['Int']['input'];
  markId: Scalars['Int']['input'];
  mediaKeysS3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  radiation: Scalars['String']['input'];
  sawId?: InputMaybe<Scalars['Int']['input']>;
  sectorId?: InputMaybe<Scalars['Int']['input']>;
  senderId: Scalars['Int']['input'];
  vin: Scalars['String']['input'];
};

export type MagadanObjectCreateOutput = {
  __typename?: 'MagadanObjectCreateOutput';
  error?: Maybe<MutationError>;
};

export type MagadanObjectUpdateInput = {
  attachments: Scalars['String']['input'];
  carrierId?: InputMaybe<Scalars['Int']['input']>;
  chassisTypeId?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  countKeys: Scalars['Int']['input'];
  hasSdCard?: InputMaybe<Scalars['Boolean']['input']>;
  isArrived?: InputMaybe<Scalars['Boolean']['input']>;
  isBroken?: InputMaybe<Scalars['Boolean']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  isNoneKeys?: InputMaybe<Scalars['Boolean']['input']>;
  isNotDrivable?: InputMaybe<Scalars['Boolean']['input']>;
  isRadCheck?: InputMaybe<Scalars['Boolean']['input']>;
  makerId: Scalars['Int']['input'];
  markId: Scalars['Int']['input'];
  mediaKeysS3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectId: Scalars['Int']['input'];
  radiation: Scalars['String']['input'];
  sawId?: InputMaybe<Scalars['Int']['input']>;
  sectorId?: InputMaybe<Scalars['Int']['input']>;
  senderId: Scalars['Int']['input'];
  vin: Scalars['String']['input'];
};

export type MagadanObjectUpdateOutput = {
  __typename?: 'MagadanObjectUpdateOutput';
  error?: Maybe<MutationError>;
};

export type Maker = {
  __typename?: 'Maker';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MakerColor = {
  __typename?: 'MakerColor';
  colorCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  maker?: Maybe<Maker>;
};

export type MakerCreateInput = {
  name: Scalars['String']['input'];
};

export type MakerCreateOutput = {
  __typename?: 'MakerCreateOutput';
  error?: Maybe<MutationError>;
};

export type Mark = {
  __typename?: 'Mark';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MarkCreateInput = {
  maker: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type MarkCreateOutput = {
  __typename?: 'MarkCreateOutput';
  error?: Maybe<MutationError>;
};

export type MasterAccountsClientsPaginatedList = {
  __typename?: 'MasterAccountsClientsPaginatedList';
  nodes: Array<Owner>;
  total: Scalars['Int']['output'];
};

export enum MediaEntityEnum {
  BrAgents = 'br_agents',
  BrAgentsFormal = 'br_agents_formal',
  RegCars = 'reg_cars',
  RegContainers = 'reg_containers',
  RegExportDeclarations = 'reg_export_declarations',
  RegSbktsDocs = 'reg_sbkts_docs',
  RegTicket = 'reg_ticket',
  RegVehicles = 'reg_vehicles'
}

export type MediaStatistics = {
  __typename?: 'MediaStatistics';
  countObjectsWithMedia?: Maybe<Scalars['Int']['output']>;
  countObjectsWoMedia?: Maybe<Scalars['Int']['output']>;
};

export enum MediaTypeEnum {
  AdditionalInvoiceCustom = 'ADDITIONAL_INVOICE_CUSTOM',
  AgentPassportAll = 'AGENT_PASSPORT_ALL',
  AkebonoUpForAuction = 'AKEBONO_UP_FOR_AUCTION',
  AuctionAutoFee = 'AUCTION_AUTO_FEE',
  AuctionLoader = 'AUCTION_LOADER',
  AuctionPaySheet = 'AUCTION_PAY_SHEET',
  AucCopyDocs = 'AUC_COPY_DOCS',
  AucDocs = 'AUC_DOCS',
  AucMedia = 'AUC_MEDIA',
  AucMediaHidden = 'AUC_MEDIA_HIDDEN',
  AutoimportAfterDisassembly = 'AUTOIMPORT_AFTER_DISASSEMBLY',
  AutoimportBeforeDisassembly = 'AUTOIMPORT_BEFORE_DISASSEMBLY',
  AutoimportFastening = 'AUTOIMPORT_FASTENING',
  AutoimportParkingPass = 'AUTOIMPORT_PARKING_PASS',
  AutoImportAcceptObject = 'AUTO_IMPORT_ACCEPT_OBJECT',
  AutoImportIssueObject = 'AUTO_IMPORT_ISSUE_OBJECT',
  BarhatAcceptObject = 'BARHAT_ACCEPT_OBJECT',
  BarhatIssueObject = 'BARHAT_ISSUE_OBJECT',
  BeforeOutsideParking = 'BEFORE_OUTSIDE_PARKING',
  BrokerImportExcels = 'BROKER_IMPORT_EXCELS',
  BrAgentBusinessCard = 'BR_AGENT_BUSINESS_CARD',
  BrAgentImages = 'BR_AGENT_IMAGES',
  BrAgentLogo = 'BR_AGENT_LOGO',
  CarsAdMedia = 'CARS_AD_MEDIA',
  CertificateGlonassDocs = 'CERTIFICATE_GLONASS_DOCS',
  Chat = 'CHAT',
  Client = 'CLIENT',
  ClientBoardingPasses = 'CLIENT_BOARDING_PASSES',
  ClientCertificateFromHotel = 'CLIENT_CERTIFICATE_FROM_HOTEL',
  ClientExplanatory = 'CLIENT_EXPLANATORY',
  ClientExplanatoryNoDvfo = 'CLIENT_EXPLANATORY_NO_DVFO',
  ClientInvoiceDoc = 'CLIENT_INVOICE_DOC',
  ClientPassport = 'CLIENT_PASSPORT',
  ClientTempRegistration = 'CLIENT_TEMP_REGISTRATION',
  ClientVerificationTempRegistration = 'CLIENT_VERIFICATION_TEMP_REGISTRATION',
  ContainerBookedConfirm = 'CONTAINER_BOOKED_CONFIRM',
  ContainerInsurePolicy = 'CONTAINER_INSURE_POLICY',
  ContainerPhotos = 'CONTAINER_PHOTOS',
  ContainerUnloading = 'CONTAINER_UNLOADING',
  CustomInvoiceDoc = 'CUSTOM_INVOICE_DOC',
  CustomInvoiceLogo = 'CUSTOM_INVOICE_LOGO',
  CustomPaymentDoc = 'CUSTOM_PAYMENT_DOC',
  DeliveryFromAuctionTroubles = 'DELIVERY_FROM_AUCTION_TROUBLES',
  DeliverySheet = 'DELIVERY_SHEET',
  EnginePhoto = 'ENGINE_PHOTO',
  ExportBl = 'EXPORT_BL',
  ExportCertificate = 'EXPORT_CERTIFICATE',
  ExportDeclaration = 'EXPORT_DECLARATION',
  ExportInvoice = 'EXPORT_INVOICE',
  GasFilling = 'GAS_FILLING',
  ImageToAct = 'IMAGE_TO_ACT',
  Inn = 'INN',
  InnLegalOwner = 'INN_LEGAL_OWNER',
  InnPdf = 'INN_PDF',
  Invoice = 'INVOICE',
  InvoiceTransportCompany = 'INVOICE_TRANSPORT_COMPANY',
  IssueFromStockMedia = 'ISSUE_FROM_STOCK_MEDIA',
  Loading = 'LOADING',
  LoadingOnTruck = 'LOADING_ON_TRUCK',
  Maintenance = 'MAINTENANCE',
  MainInvoiceCustom = 'MAIN_INVOICE_CUSTOM',
  ObjectAfterDeliveryFromAuction = 'OBJECT_AFTER_DELIVERY_FROM_AUCTION',
  ObjectAttachment = 'OBJECT_ATTACHMENT',
  ObjectBrokerPhotos = 'OBJECT_BROKER_PHOTOS',
  ObjectCatalyst = 'OBJECT_CATALYST',
  ObjectClientPassport = 'OBJECT_CLIENT_PASSPORT',
  ObjectCopyConsignment = 'OBJECT_COPY_CONSIGNMENT',
  ObjectElptsPhotos = 'OBJECT_ELPTS_PHOTOS',
  ObjectExplode = 'OBJECT_EXPLODE',
  ObjectGlonassContract = 'OBJECT_GLONASS_CONTRACT',
  ObjectImage360Internal = 'OBJECT_IMAGE360_INTERNAL',
  ObjectInvoiceChanged = 'OBJECT_INVOICE_CHANGED',
  ObjectInvoiceForShippingKaitai = 'OBJECT_INVOICE_FOR_SHIPPING_KAITAI',
  ObjectInvoiceOriginal = 'OBJECT_INVOICE_ORIGINAL',
  ObjectKaitaiPhoto = 'OBJECT_KAITAI_PHOTO',
  ObjectLotScan = 'OBJECT_LOT_SCAN',
  ObjectOldElptsPhotos = 'OBJECT_OLD_ELPTS_PHOTOS',
  ObjectSbktsBackViewPhotos = 'OBJECT_SBKTS_BACK_VIEW_PHOTOS',
  ObjectSbktsForwardViewPhotos = 'OBJECT_SBKTS_FORWARD_VIEW_PHOTOS',
  ObjectSbktsNameplatePhotos = 'OBJECT_SBKTS_NAMEPLATE_PHOTOS',
  ObjectSbktsPanelPhotos = 'OBJECT_SBKTS_PANEL_PHOTOS',
  ObjectSbktsPdf = 'OBJECT_SBKTS_PDF',
  ObjectSbktsPhotos = 'OBJECT_SBKTS_PHOTOS',
  ObjectTpoPhotos = 'OBJECT_TPO_PHOTOS',
  ObjectWheelStockman = 'OBJECT_WHEEL_STOCKMAN',
  Odometer = 'ODOMETER',
  OgrnLegalOwner = 'OGRN_LEGAL_OWNER',
  OutsideParking = 'OUTSIDE_PARKING',
  PassportCommonSheet = 'PASSPORT_COMMON_SHEET',
  PassportMainSheet = 'PASSPORT_MAIN_SHEET',
  PassportRegistrationSheet = 'PASSPORT_REGISTRATION_SHEET',
  PaymentAdditionalInvoiceCustom = 'PAYMENT_ADDITIONAL_INVOICE_CUSTOM',
  PaymentCustomBroker = 'PAYMENT_CUSTOM_BROKER',
  PaymentCustomStock = 'PAYMENT_CUSTOM_STOCK',
  PaymentInvoiceTransportCompany = 'PAYMENT_INVOICE_TRANSPORT_COMPANY',
  PaymentMainInvoiceCustom = 'PAYMENT_MAIN_INVOICE_CUSTOM',
  PaymentNotary = 'PAYMENT_NOTARY',
  PaymentOverstaying = 'PAYMENT_OVERSTAYING',
  PaymentTransferFromCustom = 'PAYMENT_TRANSFER_FROM_CUSTOM',
  PbAgreementBlank = 'PB_AGREEMENT_BLANK',
  PbAgreementClientPhoto = 'PB_AGREEMENT_CLIENT_PHOTO',
  PbAgreementClientSigned = 'PB_AGREEMENT_CLIENT_SIGNED',
  PdfAct = 'PDF_ACT',
  PreDeliveryOrder = 'PRE_DELIVERY_ORDER',
  PurchaseReceipt = 'PURCHASE_RECEIPT',
  PurchaseReceiptPdf = 'PURCHASE_RECEIPT_PDF',
  QrSheet = 'QR_SHEET',
  ReceiptOfMoneyToFtsFile = 'RECEIPT_OF_MONEY_TO_FTS_FILE',
  RecipientsDocs = 'RECIPIENTS_DOCS',
  RoadManifest = 'ROAD_MANIFEST',
  RussianStock = 'RUSSIAN_STOCK',
  RussianWatchMedia = 'RUSSIAN_WATCH_MEDIA',
  SbktsDocuments = 'SBKTS_DOCUMENTS',
  SbktsDocxFizik = 'SBKTS_DOCX_FIZIK',
  SbktsDocxUrik = 'SBKTS_DOCX_URIK',
  SenderDocument = 'SENDER_DOCUMENT',
  ServiceRequestCompleted = 'SERVICE_REQUEST_COMPLETED',
  StockMedia = 'STOCK_MEDIA',
  SupplierInvoice = 'SUPPLIER_INVOICE',
  TripBoxPhotos = 'TRIP_BOX_PHOTOS',
  TripExportPayment = 'TRIP_EXPORT_PAYMENT',
  TsmReplace = 'TSM_REPLACE',
  UnavailableAct = 'UNAVAILABLE_ACT',
  Unloading = 'UNLOADING',
  UnloadingNavis = 'UNLOADING_NAVIS',
  Vehicle = 'VEHICLE',
  WatchMedia = 'WATCH_MEDIA',
  WeighingAutoimport = 'WEIGHING_AUTOIMPORT',
  WorkerMedia = 'WORKER_MEDIA',
  WorldWideMedia = 'WORLD_WIDE_MEDIA'
}

export type MediaUnion = ObjectMediaItem | S3File;

export type Model = {
  __typename?: 'Model';
  chassisType?: Maybe<Chassis>;
  engine?: Maybe<Engine>;
  height?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageEngine?: Maybe<S3File>;
  length?: Maybe<Scalars['String']['output']>;
  mark?: Maybe<Mark>;
  name?: Maybe<Scalars['String']['output']>;
  places?: Maybe<Scalars['String']['output']>;
  transmission?: Maybe<Transmission>;
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

export type Module = {
  __typename?: 'Module';
  applicationId?: Maybe<Scalars['Int']['output']>;
  applicationName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Currency>;
};

export type MoveMediaToObjectInput = {
  sourceId: Scalars['Int']['input'];
  targetId: Scalars['Int']['input'];
};

export type MoveMediaToObjectOutput = {
  __typename?: 'MoveMediaToObjectOutput';
  error?: Maybe<MutationError>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addComment?: Maybe<AddCommentOutput>;
  /** Добавить контейнер в план WorldWide */
  addContainerToPlan?: Maybe<AddContainerToPlanOutput>;
  addDisassemblyInfo?: Maybe<AddDisassemblyInfoOutput>;
  addExportCertificateToWorldwideObject?: Maybe<AddExportCertificateToWorldwideObjectOutput>;
  /** Добавить объект в план WorldWide */
  addExportObjectToPlan?: Maybe<AddExportObjectToPlanOutput>;
  addFilesToObject?: Maybe<AddFilesToObjectOutput>;
  addFirebaseToken?: Maybe<AddFirebaseTokenOutput>;
  addMessageToChat?: Maybe<AddMessageToChatOutput>;
  addObjectToPlan?: Maybe<AddObjectToPlanOutput>;
  addTagToChat?: Maybe<AddTagToChatOutput>;
  allowVisiblePhoto?: Maybe<AllowVisiblePhotoOutput>;
  /** Добавить авто к выставлению на аукцион */
  auctionListingCreate?: Maybe<AuctionListingCreateOutput>;
  /** Отметить что авто не продано */
  auctionListingNotSold?: Maybe<AuctionListingNotSoldOutput>;
  /** Указать транспортную компанию для перевозки на аукцион */
  auctionListingSetTransport?: Maybe<AuctionListingSetTransportOutput>;
  /** Отметить что авто продано */
  auctionListingSold?: Maybe<AuctionListingSoldOutput>;
  /** Обновить данные по выставлению авто */
  auctionListingUpdateLot?: Maybe<AuctionListingUpdateLotOutput>;
  blockTheIssuance?: Maybe<BlockTheIssuanceOutput>;
  bookingRequest?: Maybe<BookingRequestOutput>;
  /** Купить лотерейный билет */
  buyLotteryTicket?: Maybe<BuyLotteryTicketOutput>;
  /** Создание|Изменений цен авто ЗУ|Другие страны */
  carClientParamsUpdate?: Maybe<CarClientParamsUpdateOutput>;
  /** Скрыты/Не скрыты фото */
  carMediaHiddenToggle?: Maybe<CarMediaHiddenToggleOutput>;
  changeAdditionalType?: Maybe<ChangeAdditionalTypeOutput>;
  changeDesiredTrip?: Maybe<ChangeDesiredTripOutput>;
  changeExportPrice?: Maybe<ChangeExportPriceOutput>;
  changeObjectBroker?: Maybe<ChangeObjectBrokerOutput>;
  changeObjectFormalOwner?: Maybe<ChangeObjectFormalOwnerOutput>;
  changeObjectFreightCountry?: Maybe<ChangeObjectFreightCountryOutput>;
  changeObjectOwner?: Maybe<ChangeObjectOwnerOutput>;
  changeObjectSender?: Maybe<ChangeObjectSenderOutput>;
  changeObjectType?: Maybe<ChangeObjectTypeOutput>;
  changeObjectVin?: Maybe<ChangeObjectVinOutput>;
  changeParentObject?: Maybe<ChangeParentObjectOutput>;
  changeSenderClient?: Maybe<ChangeSenderClientOutput>;
  /** Добавить группу пользователь в чат */
  chatAddGroupUsers?: Maybe<ChatAddGroupUsersOutput>;
  chatAddUser?: Maybe<ChatAddUserOutput>;
  chatArchiving?: Maybe<ChatArchivingOutput>;
  chatCreate?: Maybe<CreateChatOutput>;
  chatDeleteMessage?: Maybe<ChatDeleteMessageOutput>;
  chatRemoveUser?: Maybe<ChatRemoveUserOutput>;
  chatUnArchiving?: Maybe<ChatUnArchivingOutput>;
  checkDateOfManufacture?: Maybe<CheckDateOfManufactureOutput>;
  completeObject?: Maybe<CompleteObjectOutput>;
  /** Загрузить подтверждение букинга */
  containerConfirmBooked?: Maybe<ContainerConfirmBookedOutput>;
  containerCreate?: Maybe<ContainerCreateOutput>;
  containerDelete?: Maybe<ContainerDeleteOutput>;
  containerUpdate?: Maybe<ContainerUpdateOutput>;
  countryCreate?: Maybe<CountryCreateOutput>;
  createDeliveryRequest?: Maybe<CreateDeliveryRequestOutput>;
  createInvoiceJapanAuctionPurchase?: Maybe<CreateInvoiceJapanAuctionPurchaseOutput>;
  createObjectAct?: Maybe<CreateObjectActOutput>;
  /** Создать квитанцию об оплате */
  createReceiptOfPayment?: Maybe<CreateReceiptOfPaymentOutput>;
  createSbktsRequest?: Maybe<CreateSbktsRequestOutput>;
  /** Завершение работы над дилерским объектом */
  dealerObjectCompleted?: Maybe<DealerObjectCompletedOutput>;
  /** Создание дилерского объекта */
  dealerObjectCreate?: Maybe<DealerObjectCreateOutput>;
  /** Удаление дилерского объекта */
  dealerObjectDelete?: Maybe<DealerObjectDeleteOutput>;
  /** Изменение дилерского объекта */
  dealerObjectUpdate?: Maybe<DealerObjectUpdateOutput>;
  deleteObject?: Maybe<DeleteObjectOutput>;
  deleteUserNotifyMessage?: Maybe<DeleteUserNotifyMessageOutput>;
  deliveryToStock?: Maybe<DeliveryToStockOutput>;
  disallowVisiblePhoto?: Maybe<DisallowVisiblePhotoOutput>;
  /** Проверить статус оплаты услуги показа ставок */
  displayBidsCheck?: Maybe<DisplayBidsCheckOutput>;
  /** Задонатить на буханку */
  donateBuhanka?: Maybe<DonateBuhankaOutput>;
  exchangeJobClose?: Maybe<ExchangeJobCloseOutput>;
  exchangeJobSend?: Maybe<ExchangeJobSendOutput>;
  /** Добавить объекты в экспортную декларацию */
  exportDeclarationAddObjects?: Maybe<ExportDeclarationAddObjectsOutput>;
  /** Создать экспортную декларацию */
  exportDeclarationCreate?: Maybe<ExportDeclarationCreateOutput>;
  /** Экспортная декларация оплачена */
  exportDeclarationPaid?: Maybe<ExportDeclarationPaidOutput>;
  /** Убрать объект из экспортной декларации */
  exportDeclarationRemoveObject?: Maybe<ExportDeclarationRemoveObjectOutput>;
  /** Изменить данные экспортной декларации */
  exportDeclarationUpdate?: Maybe<ExportDeclarationUpdateOutput>;
  exportToAuction?: Maybe<ExportToAuctionOutput>;
  fileDelete?: Maybe<FileDeleteOutput>;
  /** Добавить форвадера */
  forwarderCreate?: Maybe<ForwarderCreateOutput>;
  /** Удалить форвадера */
  forwarderDelete?: Maybe<ForwarderDeleteOutput>;
  /** Изменить форвадера */
  forwarderUpdate?: Maybe<ForwarderUpdateOutput>;
  invoiceByObjectsGenerate?: Maybe<InvoiceByObjectsGenerateOutput>;
  invoiceCustomGenerate?: Maybe<InvoiceCustomGenerateOutput>;
  /** Выдать объект клиенту */
  issueObject?: Maybe<IssueObjectOutput>;
  /** Включить/выключить автовыставление лотов */
  kaitaiAutoResubmitChangeState?: Maybe<KaitaiAutoResubmitChangeStateOutput>;
  /** Запустить/остановить очередь Kaitai емэйлов */
  kaitaiQueueStatusChange?: Maybe<KaitaiQueueStatusChangeOutput>;
  magadanObjectCreate?: Maybe<MagadanObjectCreateOutput>;
  magadanObjectUpdate?: Maybe<MagadanObjectUpdateOutput>;
  makerCreate?: Maybe<MakerCreateOutput>;
  markCreate?: Maybe<MarkCreateOutput>;
  moveMediaToObject?: Maybe<MoveMediaToObjectOutput>;
  objectAddPriorityPass?: Maybe<ObjectAddPriorityPassOutput>;
  /** Добавить объекту статус "Запрошен букинг" */
  objectAddStatusBookingRequest?: Maybe<ObjectAddStatusBookingRequestOutput>;
  objectChangeDestinationCountry?: Maybe<ObjectChangeDestinationCountryOutput>;
  /** Запрос смены владельца объекта */
  objectChangeOwner?: Maybe<ObjectChangeOwnerOutput>;
  objectChangeSawType?: Maybe<ObjectChangeSawTypeOutput>;
  objectCheckDebts?: Maybe<ObjectCheckDebtsOutput>;
  /** Удаление файла таможенных документов */
  objectDeleteCustomDocuments?: Maybe<ObjectDeleteCustomDocumentsOutput>;
  objectDeleteMedia?: Maybe<ObjectDeleteMediaOutput>;
  /** Оставить отзыв. */
  objectFeedbackCreate?: Maybe<ObjectFeedbackCreateOutput>;
  objectGreenCornerTG?: Maybe<ObjectGreenCornerTgOutput>;
  objectHoldTo?: Maybe<ObjectHoldToOutput>;
  objectHoldToClose?: Maybe<ObjectHoldToCloseOutput>;
  /** Запросить видео досмотр */
  objectNeedVideoWatch?: Maybe<ObjectNeedVideoWatchOutput>;
  objectOfServiceRemoveFromContainer?: Maybe<ObjectOfServiceRemoveFromContainerOutput>;
  objectRecipientPaymentEmail?: Maybe<ObjectRecipientPaymentEmailOutput>;
  objectRequestToParking?: Maybe<ObjectRequestToParkingOutput>;
  /** Запрос услуги Подготовка пакета документов для жителей не ДВФО */
  objectRequestToPreparationDocuments?: Maybe<ObjectRequestToPreparationDocumentsOutput>;
  /** Подтвердить сделку перепродажи */
  objectResaleApprove?: Maybe<ObjectResaleApproveOutput>;
  /** Создать сделку перепродажи */
  objectResaleClientCreate?: Maybe<ObjectResaleClientCreateOutput>;
  /** Изменить сделку перепродажи */
  objectResaleClientUpdate?: Maybe<ObjectResaleClientUpdateOutput>;
  /** Завершить сделку перепродажи */
  objectResaleComplete?: Maybe<ObjectResaleCompleteOutput>;
  /** Отменить сделку перепродажи */
  objectResaleDelete?: Maybe<ObjectResaleDeleteOutput>;
  /** Создать инвойс вручную, если не создался */
  objectResaleInvoiceCreate?: Maybe<ObjectResaleInvoiceCreateOutput>;
  /** Создать сделку перепродажи */
  objectResaleManagerCreate?: Maybe<ObjectResaleManagerCreateOutput>;
  /** Изменить сделку перепродажи */
  objectResaleManagerUpdate?: Maybe<ObjectResaleManagerUpdateOutput>;
  objectReset?: Maybe<ObjectResetOutput>;
  objectSendInvoiceEmail?: Maybe<ObjectSendInvoiceEmailOutput>;
  objectSetDateDismantleForCube?: Maybe<ObjectSetDateDismantleForCubeOutput>;
  objectSetDateRecyclingToCube?: Maybe<ObjectSetDateRecyclingToCubeOutput>;
  objectSetDateSoldRecyclingCube?: Maybe<ObjectSetDateSoldRecyclingCubeOutput>;
  objectSetGuidFrom1c?: Maybe<ObjectSetGuidFrom1cOutput>;
  /** Автозачет делается на общих основаниях */
  objectSetIsGeneralBasisAdvance?: Maybe<ObjectSetIsGeneralBasisAdvanceOutput>;
  /** Привязать авто к тарифу ЗУ */
  objectSetIsGreenCorner?: Maybe<ObjectSetIsGreenCornerOutput>;
  /** Указать стоимость объекта утилизации */
  objectSetKaitaiPrice?: Maybe<ObjectSetKaitaiPriceOutput>;
  /** Указать клиента покупки (конечный клиент) */
  objectSetMasterAccountClient?: Maybe<ObjectSetMasterAccountClientOutput>;
  /** Указать дату продажи авто Дубаи, Монголия и т.д */
  objectSetSoldDate?: Maybe<ObjectSetSoldDateOutput>;
  /** Скрыть/отобразить объект */
  objectToggleHide?: Maybe<ObjectToggleHideOutput>;
  /** Удалить/восстановить объект */
  objectToggleRemove?: Maybe<ObjectToggleRemoveOutput>;
  /** Загрузка файлов для таможни */
  objectUploadCustomDocuments?: Maybe<ObjectUploadCustomDocumentsOutput>;
  objectUploadCustomPayment?: Maybe<ObjectUploadCustomPaymentOutput>;
  objectUploadFiles?: Maybe<ObjectUploadFilesOutput>;
  ownerSearchByParams?: Maybe<GetAgentsByParamsOutput>;
  /** Добавить в палет */
  palletAddWheel?: Maybe<PalletAddWheelOutput>;
  /** Создать палет */
  palletCreate?: Maybe<PalletCreateOutput>;
  /** Добавить колеса в план */
  palletToPlan?: Maybe<PalletToPlanOutput>;
  /** Переместить паллет в порт */
  palletToPort?: Maybe<PalletToPortOutput>;
  /** Соглашение ПБ отправлено в офис */
  pbAgreementSended?: Maybe<PbAgreementSendedOutput>;
  pickupAppointment?: Maybe<PickupAppointmentOutput>;
  /** Запланировать на тип судна */
  planOnVesselType?: Maybe<PlanOnVesselTypeOutput>;
  portCreate?: Maybe<PortCreateOutput>;
  preSignedPostCreate?: Maybe<PreSignedPostCreateOutput>;
  putUpForSaleCreate?: Maybe<PutUpForSaleCreateOutput>;
  putUpForSaleSoldOut?: Maybe<PutUpForSaleSoldOutOutput>;
  putUpForSaleUpdate?: Maybe<PutUpForSaleUpdateOutput>;
  putUpForSaleWithdraw?: Maybe<PutUpForSaleWithdrawOutput>;
  reminderRemove?: Maybe<ReminderRemoveOutput>;
  removeFromStock?: Maybe<RemoveFromStockOutput>;
  /** Снять объект с плана */
  removeObjectFromPlan?: Maybe<RemoveObjectFromPlanOutput>;
  renewExportCertificate?: Maybe<RenewExportCertificateOutput>;
  replaceDouble?: Maybe<ReplaceDoubleOutput>;
  replaceToStock?: Maybe<ReplaceToStockOutput>;
  /** Осмотр с аукциона */
  requestPhotoFromAuction?: Maybe<RequestPhotoFromAuctionOutput>;
  requestPrimbrokerInvoice?: Maybe<RequestPrimbrokerInvoiceOutput>;
  requestSbktsCreate?: Maybe<RequestSbktsCreateOutput>;
  requestSbktsFromExcelCreate?: Maybe<RequestSbktsFromExcelCreateOutput>;
  requestToIssue?: Maybe<RequestToIssueOutput>;
  requestUnHold?: Maybe<RequestUnHoldOutput>;
  restoreObject?: Maybe<RestoreObjectOutput>;
  /** Обновить сессию для кайтай аккаунта */
  scrapAccountSessionUpdate?: Maybe<ScrapAccountSessionUpdateOutput>;
  /** Добавить комментарий к объекту */
  scrapObjectCommentAdd?: Maybe<ScrapObjectCommentAddOutput>;
  /** Завести объект(запчасть) утилизации */
  scrapObjectCreate?: Maybe<ScrapObjectCreateOutput>;
  scrapObjectDeliverySet?: Maybe<ScrapObjectDeliverySetOutput>;
  /** Перевыставить объекты со скидкой */
  scrapObjectDiscountToYahoo?: Maybe<ScrapObjectsDiscountToYahooOutput>;
  /** Отменить лот в яху */
  scrapObjectLotCancel?: Maybe<ScrapObjectLotCancelOutput>;
  /** Расположение объекта на складе */
  scrapObjectPlaceSet?: Maybe<ScrapObjectPlaceSetOutput>;
  /** Отвязать запчасть и привязать к другому объекту */
  scrapObjectRebind?: Maybe<ScrapObjectRebindOutput>;
  /** Указать размер объекта */
  scrapObjectSizeSet?: Maybe<ScrapObjectSizeSetOutput>;
  /** Указать статус объекта */
  scrapObjectStatusSet?: Maybe<ScrapObjectStatusSetOutput>;
  /** Отправить объект в яху */
  scrapObjectToYahoo?: Maybe<ScrapObjectToYahooOutput>;
  /** Отвязать запчасть утилизации от головного */
  scrapObjectUnbind?: Maybe<ScrapObjectUnbindOutput>;
  /** Изменить объект(запчасть) утилизации */
  scrapObjectUpdate?: Maybe<ScrapObjectUpdateOutput>;
  /** Загрузить накладную отправки и списать объект */
  scrapObjectUploadShippingInvoice?: Maybe<ScrapObjectUploadShippingInvoiceOutput>;
  /** Списать объект */
  scrapObjectWriteOff?: Maybe<ScrapObjectWriteOffOutput>;
  scrapTypeCreate?: Maybe<ScrapTypeCreateOutput>;
  scrapTypeDescriptionCreate?: Maybe<ScrapTypeDescriptionCreateOutput>;
  scrapTypeDescriptionDelete?: Maybe<ScrapTypeDescriptionDeleteOutput>;
  scrapTypeDescriptionUpdate?: Maybe<ScrapTypeDescriptionUpdateOutput>;
  scrapTypeUpdate?: Maybe<ScrapTypeUpdateOutput>;
  scrapUtilized?: Maybe<ScrapUtilizedOutput>;
  /** Добавить морского доставщика */
  seaCarrierCreate?: Maybe<SeaCarrierCreateOutput>;
  /** Удалить морского доставщика */
  seaCarrierDelete?: Maybe<SeaCarrierDeleteOutput>;
  /** Изменгить морского доставщика */
  seaCarrierEdit?: Maybe<SeaCarrierEditOutput>;
  sendAllClientsNotification?: Maybe<SendAllClientsNotificationOutput>;
  sendDirectorFeedback?: Maybe<SendDirectorFeedbackOutput>;
  sendFeedback?: Maybe<SendFeedbackOutput>;
  sendNotificationEmail?: Maybe<SendNotificationEmailOutput>;
  sendPaymentInfo?: Maybe<SendPaymentInfoOutput>;
  /** @deprecated Use createReceiptOfPayment */
  sendReportAkebonoPayment?: Maybe<SendReportAkebonoPaymentOutput>;
  sendRussianDeliveryRequest?: Maybe<SendRussianDeliveryRequestOutput>;
  /** Отменить заявку инспекции */
  serviceRequestCanceled?: Maybe<ServiceRequestCanceledOutput>;
  /** Завершить работу над заявками */
  serviceRequestCompleted?: Maybe<ServiceRequestCompletedOutput>;
  /** Создать запрос на доп услуги */
  serviceRequestCreate?: Maybe<ServiceRequestCreateOutput>;
  /** Создать параметр для доп услуги */
  serviceRequestTypeParamAdd?: Maybe<ServiceRequestTypeParamAddOutput>;
  setBoundary?: Maybe<SetBoundaryOutput>;
  setIsGlonassNeed?: Maybe<SetIsGlonassNeedOutput>;
  sortObjectMedia?: Maybe<SortObjectMediaOutput>;
  storageCreate?: Maybe<StorageCreateOutput>;
  /** Редактирование параметров склада */
  storageEdit?: Maybe<StorageUpdateOutput>;
  storageSectorCreate?: Maybe<StorageSectorCreateOutput>;
  storageSectorEdit?: Maybe<StorageSectorEditOutput>;
  subscribeUserToTelegram?: Maybe<SubscribeUserToTelegramOutput>;
  syncObject?: Maybe<SyncObjectOutput>;
  ticketAddReview?: Maybe<TicketAddReviewOutput>;
  /** Добавить пользователя */
  ticketAddUser?: Maybe<TicketAddUserOutput>;
  /** Изменить дополнительную отметку притензии */
  ticketChangeSubType?: Maybe<TicketChangeSubTypeOutput>;
  /** Изменить тип претензии */
  ticketChangeType?: Maybe<TicketChangeTypeOutput>;
  ticketCreate?: Maybe<TicketCreateOutput>;
  /** Принять решение */
  ticketDecision?: Maybe<TicketDecisionOutput>;
  /** Добавить решение */
  ticketDecisionAdd?: Maybe<TicketDecisionAddOutput>;
  /** Апеллировать решение */
  ticketDecisionAppeal?: Maybe<TicketDecisionAppealOutput>;
  /** Подтвердить тикет */
  ticketDecisionApprove?: Maybe<TicketDecisionApproveOutput>;
  /** Подтвердить решение для руководителя организации */
  ticketDecisionApproveOrganization?: Maybe<TicketDecisionApproveOrganizationOutput>;
  /** Отменить решение */
  ticketDecisionCancel?: Maybe<TicketDecisionCancelOutput>;
  /** Удалить решение претензии из любого состояния */
  ticketDecisionCancelOnlyAdmin?: Maybe<TicketDecisionCancelOnlyAdminOutput>;
  /** Выплатить по претензии */
  ticketDecisionPayout?: Maybe<TicketDecisionPayoutOutput>;
  /** Сбросить из ожидает оплаты */
  ticketDecisionResetPayout?: Maybe<TicketDecisionResetPayoutOutput>;
  /** Изменить сумму решения */
  ticketDecisionUpdate?: Maybe<TicketDecisionUpdateOutput>;
  /** Отклонить претензию */
  ticketDecline?: Maybe<TicketDeclineOutput>;
  /** Удалить пользователя из претензии */
  ticketRemoveUser?: Maybe<TicketRemoveUserOutput>;
  /** Восстановить претензию */
  ticketReset?: Maybe<TicketResetOutput>;
  /** Закрыть без уведомления */
  ticketSilentClose?: Maybe<TicketSilentCloseOutput>;
  toggleUserNotifyMessage?: Maybe<ToggleUserNotifyMessageOutput>;
  /** Сменить порядок объектов на рейсе */
  tripChangeObjectsOrder?: Maybe<TripChangeObjectsOrderOutput>;
  tripClose?: Maybe<TripCloseOutput>;
  tripCreate?: Maybe<TripCreateOutput>;
  /** Удалить пустой рейс */
  tripDelete?: Maybe<TripDeleteOutput>;
  tripEdit?: Maybe<TripEditOutput>;
  /** Погрузить объекты на судно */
  tripLoading?: Maybe<TripLoadingOutput>;
  /** Вернуть объекты в порт погрузки */
  tripReturnToSourcePort?: Maybe<TripReturnToSourcePortOutput>;
  /** Рейс прибыл, выгрузка */
  tripToPort?: Maybe<TripToPortOutput>;
  typeOfExportEdit?: Maybe<TypeOfExportEditOutput>;
  unViewChat?: Maybe<UnViewChatOutput>;
  unlockTheIssuance?: Maybe<UnlockTheIssuanceOutput>;
  updateInvoiceAuctionPurchase?: Maybe<UpdateInvoiceAuctionPurchaseOutput>;
  uploadFiles?: Maybe<UploadFilesOutput>;
  userChangePassword?: Maybe<UserChangePasswordOutput>;
  userProfileUpdate?: Maybe<UserProfileUpdateOutput>;
  /** Создать судно */
  vesselCreate?: Maybe<VesselCreateOutput>;
  /** Редактировать судно */
  vesselUpdate?: Maybe<VesselUpdateOutput>;
  viewChat?: Maybe<ViewChatOutput>;
  watchCarUpdate?: Maybe<WatchCarUpdateOutput>;
  /** Купить колеса */
  wheelBuy?: Maybe<WheelBuyOutput>;
  /** Снять колёса с машины */
  wheelCreate?: Maybe<WheelCreateOutput>;
  /** Убрать колеса с пелеты */
  wheelRemoveFromPalle?: Maybe<WheelRemoveFromPalletOutput>;
  /** Собрано в палете */
  wheelSetIsLoaded?: Maybe<WheelSetIsLoadedOutput>;
  /** Установить цену колес */
  wheelSetPrice?: Maybe<WheelSetPriceOutput>;
};


export type MutationAddCommentArgs = {
  input?: InputMaybe<AddCommentInput>;
};


export type MutationAddContainerToPlanArgs = {
  input?: InputMaybe<AddContainerToPlanInput>;
};


export type MutationAddDisassemblyInfoArgs = {
  input?: InputMaybe<AddDisassemblyInfoInput>;
};


export type MutationAddExportCertificateToWorldwideObjectArgs = {
  input?: InputMaybe<AddExportCertificateToWorldwideObjectInput>;
};


export type MutationAddExportObjectToPlanArgs = {
  input?: InputMaybe<AddExportObjectToPlanInput>;
};


export type MutationAddFilesToObjectArgs = {
  input?: InputMaybe<AddFilesToObjectInput>;
};


export type MutationAddFirebaseTokenArgs = {
  input?: InputMaybe<AddFirebaseTokenInput>;
};


export type MutationAddMessageToChatArgs = {
  input?: InputMaybe<AddMessageToChatInput>;
};


export type MutationAddObjectToPlanArgs = {
  input?: InputMaybe<AddObjectToPlanInput>;
};


export type MutationAddTagToChatArgs = {
  input?: InputMaybe<AddTagToChatInput>;
};


export type MutationAllowVisiblePhotoArgs = {
  input?: InputMaybe<AllowVisiblePhotoInput>;
};


export type MutationAuctionListingCreateArgs = {
  input?: InputMaybe<AuctionListingCreateInput>;
};


export type MutationAuctionListingNotSoldArgs = {
  input?: InputMaybe<AuctionListingNotSoldInput>;
};


export type MutationAuctionListingSetTransportArgs = {
  input?: InputMaybe<AuctionListingSetTransportInput>;
};


export type MutationAuctionListingSoldArgs = {
  input?: InputMaybe<AuctionListingSoldInput>;
};


export type MutationAuctionListingUpdateLotArgs = {
  input?: InputMaybe<AuctionListingUpdateLotInput>;
};


export type MutationBlockTheIssuanceArgs = {
  input?: InputMaybe<BlockTheIssuanceInput>;
};


export type MutationBookingRequestArgs = {
  input?: InputMaybe<BookingRequestInput>;
};


export type MutationBuyLotteryTicketArgs = {
  input?: InputMaybe<BuyLotteryTicketInput>;
};


export type MutationCarClientParamsUpdateArgs = {
  input?: InputMaybe<CarClientParamsUpdateInput>;
};


export type MutationCarMediaHiddenToggleArgs = {
  input?: InputMaybe<CarMediaHiddenToggleInput>;
};


export type MutationChangeAdditionalTypeArgs = {
  input?: InputMaybe<ChangeAdditionalTypeInput>;
};


export type MutationChangeDesiredTripArgs = {
  input?: InputMaybe<ChangeDesiredTripInput>;
};


export type MutationChangeExportPriceArgs = {
  input?: InputMaybe<ChangeExportPriceInput>;
};


export type MutationChangeObjectBrokerArgs = {
  input?: InputMaybe<ChangeObjectBrokerInput>;
};


export type MutationChangeObjectFormalOwnerArgs = {
  input?: InputMaybe<ChangeObjectFormalOwnerInput>;
};


export type MutationChangeObjectFreightCountryArgs = {
  input?: InputMaybe<ChangeObjectFreightCountryInput>;
};


export type MutationChangeObjectOwnerArgs = {
  input?: InputMaybe<ChangeObjectOwnerInput>;
};


export type MutationChangeObjectSenderArgs = {
  input?: InputMaybe<ChangeObjectSenderInput>;
};


export type MutationChangeObjectTypeArgs = {
  input?: InputMaybe<ChangeObjectTypeInput>;
};


export type MutationChangeObjectVinArgs = {
  input?: InputMaybe<ChangeObjectVinInput>;
};


export type MutationChangeParentObjectArgs = {
  input?: InputMaybe<ChangeParentObjectInput>;
};


export type MutationChangeSenderClientArgs = {
  input?: InputMaybe<ChangeSenderClientInput>;
};


export type MutationChatAddGroupUsersArgs = {
  input?: InputMaybe<ChatAddGroupUsersInput>;
};


export type MutationChatAddUserArgs = {
  input?: InputMaybe<ChatAddUserInput>;
};


export type MutationChatArchivingArgs = {
  input?: InputMaybe<ChatArchivingInput>;
};


export type MutationChatCreateArgs = {
  input?: InputMaybe<CreateChatInput>;
};


export type MutationChatDeleteMessageArgs = {
  input?: InputMaybe<ChatDeleteMessageInput>;
};


export type MutationChatRemoveUserArgs = {
  input?: InputMaybe<ChatRemoveUserInput>;
};


export type MutationChatUnArchivingArgs = {
  input?: InputMaybe<ChatUnArchivingInput>;
};


export type MutationCheckDateOfManufactureArgs = {
  input?: InputMaybe<CheckDateOfManufactureInput>;
};


export type MutationCompleteObjectArgs = {
  input?: InputMaybe<CompleteObjectInput>;
};


export type MutationContainerConfirmBookedArgs = {
  input?: InputMaybe<ContainerConfirmBookedInput>;
};


export type MutationContainerCreateArgs = {
  input?: InputMaybe<ContainerCreateInput>;
};


export type MutationContainerDeleteArgs = {
  input?: InputMaybe<ContainerDeleteInput>;
};


export type MutationContainerUpdateArgs = {
  input?: InputMaybe<ContainerUpdateInput>;
};


export type MutationCountryCreateArgs = {
  input?: InputMaybe<CountryCreateInput>;
};


export type MutationCreateDeliveryRequestArgs = {
  input?: InputMaybe<CreateDeliveryRequestInput>;
};


export type MutationCreateInvoiceJapanAuctionPurchaseArgs = {
  input?: InputMaybe<CreateInvoiceJapanAuctionPurchaseInput>;
};


export type MutationCreateObjectActArgs = {
  input?: InputMaybe<CreateObjectActInput>;
};


export type MutationCreateReceiptOfPaymentArgs = {
  input?: InputMaybe<CreateReceiptOfPaymentInput>;
};


export type MutationCreateSbktsRequestArgs = {
  input?: InputMaybe<CreateSbktsRequestInput>;
};


export type MutationDealerObjectCompletedArgs = {
  input?: InputMaybe<DealerObjectCompletedInput>;
};


export type MutationDealerObjectCreateArgs = {
  input?: InputMaybe<DealerObjectCreateInput>;
};


export type MutationDealerObjectDeleteArgs = {
  input?: InputMaybe<DealerObjectDeleteInput>;
};


export type MutationDealerObjectUpdateArgs = {
  input?: InputMaybe<DealerObjectUpdateInput>;
};


export type MutationDeleteObjectArgs = {
  input?: InputMaybe<DeleteObjectInput>;
};


export type MutationDeleteUserNotifyMessageArgs = {
  input?: InputMaybe<DeleteUserNotifyMessageInput>;
};


export type MutationDeliveryToStockArgs = {
  input?: InputMaybe<DeliveryToStockInput>;
};


export type MutationDisallowVisiblePhotoArgs = {
  input?: InputMaybe<DisallowVisiblePhotoInput>;
};


export type MutationDisplayBidsCheckArgs = {
  input?: InputMaybe<DisplayBidsCheckInput>;
};


export type MutationDonateBuhankaArgs = {
  input?: InputMaybe<DonateBuhankaInput>;
};


export type MutationExchangeJobCloseArgs = {
  input?: InputMaybe<ExchangeJobCloseInput>;
};


export type MutationExchangeJobSendArgs = {
  input?: InputMaybe<ExchangeJobSendInput>;
};


export type MutationExportDeclarationAddObjectsArgs = {
  input?: InputMaybe<ExportDeclarationAddObjectsInput>;
};


export type MutationExportDeclarationCreateArgs = {
  input?: InputMaybe<ExportDeclarationCreateInput>;
};


export type MutationExportDeclarationPaidArgs = {
  input?: InputMaybe<ExportDeclarationPaidInput>;
};


export type MutationExportDeclarationRemoveObjectArgs = {
  input?: InputMaybe<ExportDeclarationRemoveObjectInput>;
};


export type MutationExportDeclarationUpdateArgs = {
  input?: InputMaybe<ExportDeclarationUpdateInput>;
};


export type MutationExportToAuctionArgs = {
  input?: InputMaybe<ExportToAuctionInput>;
};


export type MutationFileDeleteArgs = {
  input?: InputMaybe<FileDeleteInput>;
};


export type MutationForwarderCreateArgs = {
  input?: InputMaybe<ForwarderCreateInput>;
};


export type MutationForwarderDeleteArgs = {
  input?: InputMaybe<ForwarderDeleteInput>;
};


export type MutationForwarderUpdateArgs = {
  input?: InputMaybe<ForwarderUpdateInput>;
};


export type MutationInvoiceByObjectsGenerateArgs = {
  input?: InputMaybe<InvoiceByObjectsGenerateInput>;
};


export type MutationInvoiceCustomGenerateArgs = {
  input?: InputMaybe<InvoiceCustomGenerateInput>;
};


export type MutationIssueObjectArgs = {
  input?: InputMaybe<IssueObjectInput>;
};


export type MutationKaitaiAutoResubmitChangeStateArgs = {
  input?: InputMaybe<KaitaiAutoResubmitChangeStateInput>;
};


export type MutationKaitaiQueueStatusChangeArgs = {
  input?: InputMaybe<KaitaiQueueStatusChangeInput>;
};


export type MutationMagadanObjectCreateArgs = {
  input?: InputMaybe<MagadanObjectCreateInput>;
};


export type MutationMagadanObjectUpdateArgs = {
  input?: InputMaybe<MagadanObjectUpdateInput>;
};


export type MutationMakerCreateArgs = {
  input?: InputMaybe<MakerCreateInput>;
};


export type MutationMarkCreateArgs = {
  input?: InputMaybe<MarkCreateInput>;
};


export type MutationMoveMediaToObjectArgs = {
  input?: InputMaybe<MoveMediaToObjectInput>;
};


export type MutationObjectAddPriorityPassArgs = {
  input?: InputMaybe<ObjectAddPriorityPassInput>;
};


export type MutationObjectAddStatusBookingRequestArgs = {
  input?: InputMaybe<ObjectAddStatusBookingRequestInput>;
};


export type MutationObjectChangeDestinationCountryArgs = {
  input?: InputMaybe<ObjectChangeDestinationCountryInput>;
};


export type MutationObjectChangeOwnerArgs = {
  input?: InputMaybe<ObjectChangeOwnerInput>;
};


export type MutationObjectChangeSawTypeArgs = {
  input?: InputMaybe<ObjectChangeSawTypeInput>;
};


export type MutationObjectCheckDebtsArgs = {
  input?: InputMaybe<ObjectCheckDebtsInput>;
};


export type MutationObjectDeleteCustomDocumentsArgs = {
  input?: InputMaybe<ObjectDeleteCustomDocumentsInput>;
};


export type MutationObjectDeleteMediaArgs = {
  input?: InputMaybe<ObjectDeleteMediaInput>;
};


export type MutationObjectFeedbackCreateArgs = {
  input?: InputMaybe<ObjectFeedbackCreateInput>;
};


export type MutationObjectGreenCornerTgArgs = {
  input?: InputMaybe<ObjectGreenCornerTgInput>;
};


export type MutationObjectHoldToArgs = {
  input?: InputMaybe<ObjectHoldToInput>;
};


export type MutationObjectHoldToCloseArgs = {
  input?: InputMaybe<ObjectHoldToCloseInput>;
};


export type MutationObjectNeedVideoWatchArgs = {
  input?: InputMaybe<ObjectNeedVideoWatchInput>;
};


export type MutationObjectOfServiceRemoveFromContainerArgs = {
  input?: InputMaybe<ObjectOfServiceRemoveFromContainerInput>;
};


export type MutationObjectRecipientPaymentEmailArgs = {
  input?: InputMaybe<ObjectRecipientPaymentEmailInput>;
};


export type MutationObjectRequestToParkingArgs = {
  input?: InputMaybe<ObjectRequestToParkingInput>;
};


export type MutationObjectRequestToPreparationDocumentsArgs = {
  input?: InputMaybe<ObjectRequestToPreparationDocumentsInput>;
};


export type MutationObjectResaleApproveArgs = {
  input?: InputMaybe<ObjectResaleApproveInput>;
};


export type MutationObjectResaleClientCreateArgs = {
  input?: InputMaybe<ObjectResaleClientCreateInput>;
};


export type MutationObjectResaleClientUpdateArgs = {
  input?: InputMaybe<ObjectResaleClientUpdateInput>;
};


export type MutationObjectResaleCompleteArgs = {
  input?: InputMaybe<ObjectResaleCompleteInput>;
};


export type MutationObjectResaleDeleteArgs = {
  input?: InputMaybe<ObjectResaleDeleteInput>;
};


export type MutationObjectResaleInvoiceCreateArgs = {
  input?: InputMaybe<ObjectResaleInvoiceCreateInput>;
};


export type MutationObjectResaleManagerCreateArgs = {
  input?: InputMaybe<ObjectResaleManagerCreateInput>;
};


export type MutationObjectResaleManagerUpdateArgs = {
  input?: InputMaybe<ObjectResaleManagerUpdateInput>;
};


export type MutationObjectResetArgs = {
  input?: InputMaybe<ObjectResetInput>;
};


export type MutationObjectSendInvoiceEmailArgs = {
  input?: InputMaybe<ObjectSendInvoiceEmailInput>;
};


export type MutationObjectSetDateDismantleForCubeArgs = {
  input?: InputMaybe<ObjectSetDateDismantleForCubeInput>;
};


export type MutationObjectSetDateRecyclingToCubeArgs = {
  input?: InputMaybe<ObjectSetDateRecyclingToCubeInput>;
};


export type MutationObjectSetDateSoldRecyclingCubeArgs = {
  input?: InputMaybe<ObjectSetDateSoldRecyclingCubeInput>;
};


export type MutationObjectSetGuidFrom1cArgs = {
  input?: InputMaybe<ObjectSetGuidFrom1cInput>;
};


export type MutationObjectSetIsGeneralBasisAdvanceArgs = {
  input?: InputMaybe<ObjectSetIsGeneralBasisAdvanceInput>;
};


export type MutationObjectSetIsGreenCornerArgs = {
  input?: InputMaybe<ObjectSetIsGreenCornerInput>;
};


export type MutationObjectSetKaitaiPriceArgs = {
  input?: InputMaybe<ObjectSetKaitaiPriceInput>;
};


export type MutationObjectSetMasterAccountClientArgs = {
  input?: InputMaybe<ObjectSetMasterAccountClientInput>;
};


export type MutationObjectSetSoldDateArgs = {
  input?: InputMaybe<ObjectSetSoldDateInput>;
};


export type MutationObjectToggleHideArgs = {
  input?: InputMaybe<ObjectToggleHideInput>;
};


export type MutationObjectToggleRemoveArgs = {
  input?: InputMaybe<ObjectToggleRemoveInput>;
};


export type MutationObjectUploadCustomDocumentsArgs = {
  input?: InputMaybe<ObjectUploadCustomDocumentsInput>;
};


export type MutationObjectUploadCustomPaymentArgs = {
  input?: InputMaybe<ObjectUploadCustomPaymentInput>;
};


export type MutationObjectUploadFilesArgs = {
  input?: InputMaybe<ObjectUploadFilesInput>;
};


export type MutationOwnerSearchByParamsArgs = {
  input?: InputMaybe<GetAgentsByParamsInput>;
};


export type MutationPalletAddWheelArgs = {
  input?: InputMaybe<PalletAddWheelInput>;
};


export type MutationPalletCreateArgs = {
  input?: InputMaybe<PalletCreateInput>;
};


export type MutationPalletToPlanArgs = {
  input?: InputMaybe<PalletToPlanInput>;
};


export type MutationPalletToPortArgs = {
  input?: InputMaybe<PalletToPortInput>;
};


export type MutationPbAgreementSendedArgs = {
  input?: InputMaybe<PbAgreementSendedInput>;
};


export type MutationPickupAppointmentArgs = {
  input?: InputMaybe<PickupAppointmentInput>;
};


export type MutationPlanOnVesselTypeArgs = {
  input?: InputMaybe<PlanOnVesselTypeInput>;
};


export type MutationPortCreateArgs = {
  input?: InputMaybe<PortCreateInput>;
};


export type MutationPreSignedPostCreateArgs = {
  input?: InputMaybe<PreSignedPostCreateInput>;
};


export type MutationPutUpForSaleCreateArgs = {
  input?: InputMaybe<PutUpForSaleCreateInput>;
};


export type MutationPutUpForSaleSoldOutArgs = {
  input?: InputMaybe<PutUpForSaleSoldOutInput>;
};


export type MutationPutUpForSaleUpdateArgs = {
  input?: InputMaybe<PutUpForSaleUpdateInput>;
};


export type MutationPutUpForSaleWithdrawArgs = {
  input?: InputMaybe<PutUpForSaleWithdrawInput>;
};


export type MutationReminderRemoveArgs = {
  input?: InputMaybe<ReminderRemoveInput>;
};


export type MutationRemoveFromStockArgs = {
  input?: InputMaybe<RemoveFromStockInput>;
};


export type MutationRemoveObjectFromPlanArgs = {
  input?: InputMaybe<RemoveObjectFromPlanInput>;
};


export type MutationRenewExportCertificateArgs = {
  input?: InputMaybe<RenewExportCertificateInput>;
};


export type MutationReplaceDoubleArgs = {
  input?: InputMaybe<ReplaceDoubleInput>;
};


export type MutationReplaceToStockArgs = {
  input?: InputMaybe<ReplaceToStockInput>;
};


export type MutationRequestPhotoFromAuctionArgs = {
  input?: InputMaybe<RequestPhotoFromAuctionInput>;
};


export type MutationRequestPrimbrokerInvoiceArgs = {
  input?: InputMaybe<RequestPrimbrokerInvoiceInput>;
};


export type MutationRequestSbktsCreateArgs = {
  input?: InputMaybe<RequestSbktsCreateInput>;
};


export type MutationRequestSbktsFromExcelCreateArgs = {
  input?: InputMaybe<RequestSbktsFromExcelCreateInput>;
};


export type MutationRequestToIssueArgs = {
  input?: InputMaybe<RequestToIssueInput>;
};


export type MutationRequestUnHoldArgs = {
  input?: InputMaybe<RequestUnHoldInput>;
};


export type MutationRestoreObjectArgs = {
  input?: InputMaybe<RestoreObjectInput>;
};


export type MutationScrapAccountSessionUpdateArgs = {
  input?: InputMaybe<ScrapAccountSessionUpdateInput>;
};


export type MutationScrapObjectCommentAddArgs = {
  input?: InputMaybe<ScrapObjectCommentAddInput>;
};


export type MutationScrapObjectCreateArgs = {
  input?: InputMaybe<ScrapObjectCreateInput>;
};


export type MutationScrapObjectDeliverySetArgs = {
  input?: InputMaybe<ScrapObjectDeliverySetInput>;
};


export type MutationScrapObjectDiscountToYahooArgs = {
  input?: InputMaybe<ScrapObjectsDiscountToYahooInput>;
};


export type MutationScrapObjectLotCancelArgs = {
  input?: InputMaybe<ScrapObjectLotCancelInput>;
};


export type MutationScrapObjectPlaceSetArgs = {
  input?: InputMaybe<ScrapObjectPlaceSetInput>;
};


export type MutationScrapObjectRebindArgs = {
  input?: InputMaybe<ScrapObjectRebindInput>;
};


export type MutationScrapObjectSizeSetArgs = {
  input?: InputMaybe<ScrapObjectSizeSetInput>;
};


export type MutationScrapObjectStatusSetArgs = {
  input?: InputMaybe<ScrapObjectStatusSetInput>;
};


export type MutationScrapObjectToYahooArgs = {
  input?: InputMaybe<ScrapObjectToYahooInput>;
};


export type MutationScrapObjectUnbindArgs = {
  input?: InputMaybe<ScrapObjectUnbindInput>;
};


export type MutationScrapObjectUpdateArgs = {
  input?: InputMaybe<ScrapObjectUpdateInput>;
};


export type MutationScrapObjectUploadShippingInvoiceArgs = {
  input?: InputMaybe<ScrapObjectUploadShippingInvoiceInput>;
};


export type MutationScrapObjectWriteOffArgs = {
  input?: InputMaybe<ScrapObjectWriteOffInput>;
};


export type MutationScrapTypeCreateArgs = {
  input?: InputMaybe<ScrapTypeCreateInput>;
};


export type MutationScrapTypeDescriptionCreateArgs = {
  input?: InputMaybe<ScrapTypeDescriptionCreateInput>;
};


export type MutationScrapTypeDescriptionDeleteArgs = {
  input?: InputMaybe<ScrapTypeDescriptionDeleteInput>;
};


export type MutationScrapTypeDescriptionUpdateArgs = {
  input?: InputMaybe<ScrapTypeDescriptionUpdateInput>;
};


export type MutationScrapTypeUpdateArgs = {
  input?: InputMaybe<ScrapTypeUpdateInput>;
};


export type MutationScrapUtilizedArgs = {
  input?: InputMaybe<ScrapUtilizedInput>;
};


export type MutationSeaCarrierCreateArgs = {
  input?: InputMaybe<SeaCarrierCreateInput>;
};


export type MutationSeaCarrierDeleteArgs = {
  input?: InputMaybe<SeaCarrierDeleteInput>;
};


export type MutationSeaCarrierEditArgs = {
  input?: InputMaybe<SeaCarrierEditInput>;
};


export type MutationSendAllClientsNotificationArgs = {
  input?: InputMaybe<SendAllClientsNotificationInput>;
};


export type MutationSendDirectorFeedbackArgs = {
  input?: InputMaybe<SendDirectorFeedbackInput>;
};


export type MutationSendFeedbackArgs = {
  input?: InputMaybe<SendFeedbackInput>;
};


export type MutationSendNotificationEmailArgs = {
  input?: InputMaybe<SendNotificationEmailInput>;
};


export type MutationSendPaymentInfoArgs = {
  input?: InputMaybe<SendPaymentInfoInput>;
};


export type MutationSendReportAkebonoPaymentArgs = {
  input?: InputMaybe<SendReportAkebonoPaymentInput>;
};


export type MutationSendRussianDeliveryRequestArgs = {
  input?: InputMaybe<SendRussianDeliveryRequestInput>;
};


export type MutationServiceRequestCanceledArgs = {
  input?: InputMaybe<ServiceRequestCanceledInput>;
};


export type MutationServiceRequestCompletedArgs = {
  input?: InputMaybe<ServiceRequestCompletedInput>;
};


export type MutationServiceRequestCreateArgs = {
  input?: InputMaybe<ServiceRequestCreateInput>;
};


export type MutationServiceRequestTypeParamAddArgs = {
  input?: InputMaybe<ServiceRequestTypeParamAddInput>;
};


export type MutationSetBoundaryArgs = {
  input?: InputMaybe<SetBoundaryInput>;
};


export type MutationSetIsGlonassNeedArgs = {
  input?: InputMaybe<SetIsGlonassNeedInput>;
};


export type MutationSortObjectMediaArgs = {
  input?: InputMaybe<SortObjectMediaInput>;
};


export type MutationStorageCreateArgs = {
  input?: InputMaybe<StorageCreateInput>;
};


export type MutationStorageEditArgs = {
  input?: InputMaybe<StorageUpdateInput>;
};


export type MutationStorageSectorCreateArgs = {
  input?: InputMaybe<StorageSectorCreateInput>;
};


export type MutationStorageSectorEditArgs = {
  input?: InputMaybe<StorageSectorEditInput>;
};


export type MutationSubscribeUserToTelegramArgs = {
  input?: InputMaybe<SubscribeUserToTelegramInput>;
};


export type MutationSyncObjectArgs = {
  input?: InputMaybe<SyncObjectInput>;
};


export type MutationTicketAddReviewArgs = {
  input?: InputMaybe<TicketAddReviewInput>;
};


export type MutationTicketAddUserArgs = {
  input?: InputMaybe<TicketAddUserInput>;
};


export type MutationTicketChangeSubTypeArgs = {
  input?: InputMaybe<TicketChangeSubTypeInput>;
};


export type MutationTicketChangeTypeArgs = {
  input?: InputMaybe<TicketChangeTypeInput>;
};


export type MutationTicketCreateArgs = {
  input?: InputMaybe<TicketCreateInput>;
};


export type MutationTicketDecisionArgs = {
  input?: InputMaybe<TicketDecisionInput>;
};


export type MutationTicketDecisionAddArgs = {
  input?: InputMaybe<TicketDecisionAddInput>;
};


export type MutationTicketDecisionAppealArgs = {
  input?: InputMaybe<TicketDecisionAppealInput>;
};


export type MutationTicketDecisionApproveArgs = {
  input?: InputMaybe<TicketDecisionApproveInput>;
};


export type MutationTicketDecisionApproveOrganizationArgs = {
  input?: InputMaybe<TicketDecisionApproveOrganizationInput>;
};


export type MutationTicketDecisionCancelArgs = {
  input?: InputMaybe<TicketDecisionCancelInput>;
};


export type MutationTicketDecisionCancelOnlyAdminArgs = {
  input?: InputMaybe<TicketDecisionCancelOnlyAdminInput>;
};


export type MutationTicketDecisionPayoutArgs = {
  input?: InputMaybe<TicketDecisionPayoutInput>;
};


export type MutationTicketDecisionResetPayoutArgs = {
  input?: InputMaybe<TicketDecisionResetPayoutInput>;
};


export type MutationTicketDecisionUpdateArgs = {
  input?: InputMaybe<TicketDecisionUpdateInput>;
};


export type MutationTicketDeclineArgs = {
  input?: InputMaybe<TicketDeclineInput>;
};


export type MutationTicketRemoveUserArgs = {
  input?: InputMaybe<TicketRemoveUserInput>;
};


export type MutationTicketResetArgs = {
  input?: InputMaybe<TicketResetInput>;
};


export type MutationTicketSilentCloseArgs = {
  input?: InputMaybe<TicketSilentCloseInput>;
};


export type MutationToggleUserNotifyMessageArgs = {
  input?: InputMaybe<ToggleUserNotifyMessageInput>;
};


export type MutationTripChangeObjectsOrderArgs = {
  input?: InputMaybe<TripChangeObjectsOrderInput>;
};


export type MutationTripCloseArgs = {
  input?: InputMaybe<TripCloseInput>;
};


export type MutationTripCreateArgs = {
  input?: InputMaybe<TripCreateInput>;
};


export type MutationTripDeleteArgs = {
  input?: InputMaybe<TripDeleteInput>;
};


export type MutationTripEditArgs = {
  input?: InputMaybe<TripEditInput>;
};


export type MutationTripLoadingArgs = {
  input?: InputMaybe<TripLoadingInput>;
};


export type MutationTripReturnToSourcePortArgs = {
  input?: InputMaybe<TripReturnToSourcePortInput>;
};


export type MutationTripToPortArgs = {
  input?: InputMaybe<TripToPortInput>;
};


export type MutationTypeOfExportEditArgs = {
  input?: InputMaybe<TypeOfExportEditInput>;
};


export type MutationUnViewChatArgs = {
  input?: InputMaybe<UnViewChatInput>;
};


export type MutationUnlockTheIssuanceArgs = {
  input?: InputMaybe<UnlockTheIssuanceInput>;
};


export type MutationUpdateInvoiceAuctionPurchaseArgs = {
  input?: InputMaybe<UpdateInvoiceAuctionPurchaseInput>;
};


export type MutationUploadFilesArgs = {
  input?: InputMaybe<UploadFilesInput>;
};


export type MutationUserChangePasswordArgs = {
  input?: InputMaybe<UserChangePasswordInput>;
};


export type MutationUserProfileUpdateArgs = {
  input?: InputMaybe<UserProfileUpdateInput>;
};


export type MutationVesselCreateArgs = {
  input?: InputMaybe<VesselCreateInput>;
};


export type MutationVesselUpdateArgs = {
  input?: InputMaybe<VesselUpdateInput>;
};


export type MutationViewChatArgs = {
  input?: InputMaybe<ViewChatInput>;
};


export type MutationWatchCarUpdateArgs = {
  input?: InputMaybe<WatchCarUpdateInput>;
};


export type MutationWheelBuyArgs = {
  input?: InputMaybe<WheelBuyInput>;
};


export type MutationWheelCreateArgs = {
  input?: InputMaybe<WheelCreateInput>;
};


export type MutationWheelRemoveFromPalleArgs = {
  input?: InputMaybe<WheelRemoveFromPalletInput>;
};


export type MutationWheelSetIsLoadedArgs = {
  input?: InputMaybe<WheelSetIsLoadedInput>;
};


export type MutationWheelSetPriceArgs = {
  input?: InputMaybe<WheelSetPriceInput>;
};

export type MutationError = {
  __typename?: 'MutationError';
  code?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  line?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type NdsType = {
  __typename?: 'NdsType';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Ставка включена в сумму начисления */
  isIncluded?: Maybe<Scalars['Boolean']['output']>;
  /** Ставка НДС в процентах */
  value?: Maybe<Scalars['Int']['output']>;
};

export type News = {
  __typename?: 'News';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAttention?: Maybe<Scalars['Boolean']['output']>;
  shortContent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsPaginatedList = {
  __typename?: 'NewsPaginatedList';
  nodes: Array<News>;
  total: Scalars['Int']['output'];
};

export type Nomenclature = {
  __typename?: 'Nomenclature';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  isForExportPrice?: Maybe<Scalars['Boolean']['output']>;
  isForWorldWide?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  ndsType?: Maybe<NdsType>;
};

export type NotificationLog = {
  __typename?: 'NotificationLog';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<NotificationLogEntityEnum>;
  entityId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isSuccess?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<Scalars['String']['output']>;
  sendFrom?: Maybe<Scalars['String']['output']>;
  sendTo?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum NotificationLogEntityEnum {
  Agents = 'AGENTS',
  Objects = 'OBJECTS',
  Tickets = 'TICKETS'
}

export type NotificationLogFilter = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sendTo?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationLogsFilter = {
  notificationLog?: InputMaybe<NotificationLogFilter>;
};

export type NotificationLogsPaginatedList = {
  __typename?: 'NotificationLogsPaginatedList';
  nodes: Array<NotificationLog>;
  total: Scalars['Int']['output'];
};

export type NotifyBotInfo = {
  __typename?: 'NotifyBotInfo';
  clientName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type NotifyMessage = {
  __typename?: 'NotifyMessage';
  channels?: Maybe<Array<UserChannel>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export enum ObjectActionsEnum {
  AddComment = 'add_comment',
  AddDeliveryToStock = 'add_delivery_to_stock',
  AddDisassemblyManual = 'add_disassembly_manual',
  AddDisassemblyNotice = 'add_disassembly_notice',
  AddObjectToPlan = 'add_object_to_plan',
  AddPriorityPass = 'add_priority_pass',
  AddRecipientsBroker = 'add_recipients_broker',
  AddRecipientsClient = 'add_recipients_client',
  AddRikuunAccrual = 'add_rikuun_accrual',
  Attachments = 'attachments',
  ButtonObjectHide = 'button_object_hide',
  ButtonObjectRemove = 'button_object_remove',
  ChangeBroker = 'change_broker',
  ChangeDesiredTrip = 'change_desired_trip',
  ChangeDestinationCountry = 'change_destination_country',
  ChangeExportPrice = 'change_export_price',
  ChangeFormalOwner = 'change_formal_owner',
  ChangeFreightCountry = 'change_freight_country',
  ChangeObjectInLoading = 'change_object_in_loading',
  ChangeObjectType = 'change_object_type',
  ChangeObjectVehicleType = 'change_object_vehicle_type',
  ChangeOwner = 'change_owner',
  ChangeParentObject = 'change_parent_object',
  ChangeSawType = 'change_saw_type',
  ChangeSender = 'change_sender',
  ChangeSenderClient = 'change_sender_client',
  ChangeVin = 'change_vin',
  CheckDateManufacture = 'check_date_manufacture',
  CompleteObject = 'complete_object',
  CreateAct = 'create_act',
  CreateChat = 'create_chat',
  CreateDeliveryRequest = 'create_delivery_request',
  CreateFeedbackCar = 'create_feedback_car',
  CreateInvoiceJapanAuctionPurchase = 'create_invoice_japan_auction_purchase',
  CreateParkingRequest = 'create_parking_request',
  CreateSbktsRequest = 'create_sbkts_request',
  CreateTicket = 'create_ticket',
  DeleteObject = 'delete_object',
  ExportToAuction = 'export_to_auction',
  FillEptsForm = 'fill_epts_form',
  HideImages = 'hide_images',
  HoldTo = 'hold_to',
  LockIssuance = 'lock_issuance',
  MoveMediaToObject = 'move_media_to_object',
  PbAgreementSended = 'pb_agreement_sended',
  PbAgreementUpload = 'pb_agreement_upload',
  PickupAppointment = 'pickup_appointment',
  PrintQr = 'print_qr',
  PutUpForSaleCreate = 'put_up_for_sale_create',
  PutUpForSaleSoldOut = 'put_up_for_sale_sold_out',
  PutUpForSaleUpdate = 'put_up_for_sale_update',
  PutUpForSaleWithdraw = 'put_up_for_sale_withdraw',
  RemoveFromStock = 'remove_from_stock',
  RemoveObjectFromPlan = 'remove_object_from_plan',
  ReplaceDouble = 'replace_double',
  ReplaceToStock = 'replace_to_stock',
  ReportPaymentAkebono = 'report_payment_akebono',
  RequestDeliveryRussia = 'request_delivery_russia',
  RequestInvoicePrimBroker = 'request_invoice_prim_broker',
  RequestPrepareDocuments = 'request_prepare_documents',
  RequestToIssue = 'request_to_issue',
  Resale = 'resale',
  ResetObject = 'reset_object',
  RestoreObject = 'restore_object',
  SendObjectTo_1c = 'send_object_to_1c',
  ServiceRequestCreate = 'service_request_create',
  SetDateDismantleForCube = 'set_date_dismantle_for_cube',
  SetDateRecyclingToCube = 'set_date_recycling_to_cube',
  SetDateSoldRecyclingCube = 'set_date_sold_recycling_cube',
  SetGeneralBasisAdvance = 'set_general_basis_advance',
  SetGlonassNeed = 'set_glonass_need',
  SetGuidFrom_1c = 'set_guid_from_1c',
  SetIsBoundary = 'set_is_boundary',
  SetIsGreenCorner = 'set_is_green_corner',
  SetMasterAccountClient = 'set_master_account_client',
  SetObjectCatalogPrices = 'set_object_catalog_prices',
  SetObjectSoldDate = 'set_object_sold_date',
  ShowImages = 'show_images',
  UnlockIssuance = 'unlock_issuance',
  UploadClientImages = 'upload_client_images',
  UploadCustomPaymentDoc = 'upload_custom_payment_doc',
  UploadFiles = 'upload_files',
  VideoWatchRequest = 'video_watch_request',
  WatchObjectInLoading = 'watch_object_in_loading'
}

export type ObjectAddPriorityPassInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectAddPriorityPassOutput = {
  __typename?: 'ObjectAddPriorityPassOutput';
  error?: Maybe<MutationError>;
};

export type ObjectAddStatusBookingRequestInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectAddStatusBookingRequestOutput = {
  __typename?: 'ObjectAddStatusBookingRequestOutput';
  error?: Maybe<MutationError>;
};

export type ObjectAttachment = {
  __typename?: 'ObjectAttachment';
  comment?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  package?: Maybe<ObjectPackage>;
  quantity?: Maybe<Scalars['Int']['output']>;
  statusName?: Maybe<Scalars['String']['output']>;
};

export type ObjectAttachmentTypeLog = {
  __typename?: 'ObjectAttachmentTypeLog';
  message?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type ObjectAuction = {
  __typename?: 'ObjectAuction';
  additionalKeysStatus?: Maybe<AdditionalKeysStatusEnum>;
  auc?: Maybe<Auction>;
  aucId?: Maybe<Scalars['Int']['output']>;
  commentForClient?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  freightPrice?: Maybe<Price>;
  id?: Maybe<Scalars['Int']['output']>;
  isHaveVehicleRegistrationNumber?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  lotData?: Maybe<Scalars['JSON']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paidAuctionInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Стоимость объекта в Дубае
   * @deprecated Move to carClientParam type
   */
  priceInStock?: Maybe<Price>;
  /**
   * Стоимость объекта в пути до Дубая
   * @deprecated Move to carClientParam type
   */
  priceOnDelivery?: Maybe<Price>;
  purchasePrice?: Maybe<Price>;
  receivedCopyAuctionDoc?: Maybe<Scalars['Boolean']['output']>;
  sawPrice?: Maybe<Price>;
  stock?: Maybe<Stock>;
  troubleComment?: Maybe<Scalars['String']['output']>;
  vanningPrice?: Maybe<Price>;
};

export type ObjectAuctionHistory = {
  __typename?: 'ObjectAuctionHistory';
  author?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  solver?: Maybe<Scalars['String']['output']>;
  textError?: Maybe<Scalars['String']['output']>;
  textSolve?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Фильтр для выставленных на аукцион */
export type ObjectAuctionListingFilter = {
  /** Поиск по номеру лота */
  aucNumber?: InputMaybe<Scalars['String']['input']>;
  /** Авто выставленные на выбранные аукцион */
  auction?: InputMaybe<Scalars['Int']['input']>;
  /** Выставлено от */
  createdFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Выставлено до */
  createdTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата торгов от */
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата торгов до */
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Объекты которые можно выставить */
  forAuctionListing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Все выставленные */
  isListenedAuction?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фильтр по статусу */
  status?: InputMaybe<ObjectAuctionListingStatusEnum>;
};

export enum ObjectAuctionListingStatusEnum {
  /** Добавлен к выставлению */
  Listed = 'LISTED',
  /** Не продан */
  NotSold = 'NOT_SOLD',
  /** Подготовлен */
  Prepared = 'PREPARED',
  /** Отправлен */
  Send = 'SEND',
  /** Продан */
  Sold = 'SOLD'
}

export type ObjectChangeDestinationCountryInput = {
  countryId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectChangeDestinationCountryOutput = {
  __typename?: 'ObjectChangeDestinationCountryOutput';
  error?: Maybe<MutationError>;
};

export type ObjectChangeOwnerInput = {
  newOwnerId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectChangeOwnerOutput = {
  __typename?: 'ObjectChangeOwnerOutput';
  error?: Maybe<MutationError>;
};

export type ObjectChangeSawTypeInput = {
  objectId: Scalars['Int']['input'];
  sawTypeId: Scalars['Int']['input'];
};

export type ObjectChangeSawTypeOutput = {
  __typename?: 'ObjectChangeSawTypeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectCheckDebtsInput = {
  objectId: Scalars['Int']['input'];
  organizationGuids?: InputMaybe<Array<OrganizationGuidEnum>>;
};

export type ObjectCheckDebtsOutput = {
  __typename?: 'ObjectCheckDebtsOutput';
  error?: Maybe<MutationError>;
  isFinanceClear?: Maybe<Scalars['Boolean']['output']>;
};

export type ObjectClearance = {
  __typename?: 'ObjectClearance';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateClearance?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ObjectComment = {
  __typename?: 'ObjectComment';
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ObjectCustomDocument = {
  __typename?: 'ObjectCustomDocument';
  /** Список принятых типов документов которые нельзя перезагружать */
  acceptedFile?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Время смены статуса декларантом */
  changeStateAt?: Maybe<Scalars['DateTime']['output']>;
  /** Требуется явка в таможню */
  comeToCustoms?: Maybe<Scalars['Boolean']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']['output']>;
  invoiceCreateDate?: Maybe<Scalars['String']['output']>;
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  /** Документы приняты */
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  /** Документы отклонены */
  isDecline?: Maybe<Scalars['Boolean']['output']>;
  /** Можно ли принять или отменить набор документов */
  isNeedAction?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  /** Причина отмены */
  reasonCancel?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Пользователь */
  user?: Maybe<User>;
};

export type ObjectDelayRequest = {
  __typename?: 'ObjectDelayRequest';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  holdTo?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  startDelayFrom?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

export type ObjectDeleteCustomDocumentsInput = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectDeleteCustomDocumentsOutput = {
  __typename?: 'ObjectDeleteCustomDocumentsOutput';
  error?: Maybe<MutationError>;
};

export type ObjectDeleteMediaInput = {
  imagesList: Array<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectDeleteMediaOutput = {
  __typename?: 'ObjectDeleteMediaOutput';
  error?: Maybe<MutationError>;
};

export type ObjectDelivered = {
  __typename?: 'ObjectDelivered';
  destinationStock?: Maybe<Stock>;
  driver?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  isDelivered?: Maybe<Scalars['Boolean']['output']>;
  plan?: Maybe<Plan>;
  sourceStock?: Maybe<Stock>;
  systemCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  vehicle?: Maybe<Vehicle>;
  watch?: Maybe<Watch>;
};

export type ObjectDelivery = {
  __typename?: 'ObjectDelivery';
  contract?: Maybe<Scalars['Int']['output']>;
  destinationSector?: Maybe<Sector>;
  destinationStock?: Maybe<Stock>;
  driver?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  isFound?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<Plan>;
  sourceSector?: Maybe<Sector>;
  sourceStock?: Maybe<Stock>;
  systemCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  watch?: Maybe<Watch>;
};

export type ObjectDeliveryState = {
  __typename?: 'ObjectDeliveryState';
  /** Есть ли доставка */
  hasDeliveryRequest?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Находится в плане доставки */
  inPlan?: Maybe<Scalars['Boolean']['output']>;
  /** Есть ли доставка */
  inRussia?: Maybe<Scalars['Boolean']['output']>;
  /** Работа завершена */
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** Доставлена */
  isDelivered?: Maybe<Scalars['Boolean']['output']>;
  /** Начата доставка */
  onTransfer?: Maybe<Scalars['Boolean']['output']>;
};

export type ObjectDisassemble = {
  __typename?: 'ObjectDisassemble';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedBy?: Maybe<User>;
  assistants?: Maybe<Array<User>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  finishWorkAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCurrentUserJob?: Maybe<Scalars['Boolean']['output']>;
  manual?: Maybe<Scalars['String']['output']>;
  notice?: Maybe<Scalars['String']['output']>;
  selfAssistant?: Maybe<Scalars['Boolean']['output']>;
  startWorkAt?: Maybe<Scalars['DateTime']['output']>;
  worker?: Maybe<User>;
};

export type ObjectFeedbackCreateInput = {
  evaluation: Scalars['Int']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectFeedbackCreateOutput = {
  __typename?: 'ObjectFeedbackCreateOutput';
  error?: Maybe<MutationError>;
};

export type ObjectGreenCornerFilter = {
  isGreenCorner?: InputMaybe<Scalars['Boolean']['input']>;
  noPrice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ObjectGreenCornerTgInput = {
  fewImages?: InputMaybe<Scalars['Boolean']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectGreenCornerTgOutput = {
  __typename?: 'ObjectGreenCornerTGOutput';
  error?: Maybe<MutationError>;
};

export type ObjectHoldToCloseInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectHoldToCloseOutput = {
  __typename?: 'ObjectHoldToCloseOutput';
  error?: Maybe<MutationError>;
};

export type ObjectHoldToInput = {
  holdTo: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectHoldToOutput = {
  __typename?: 'ObjectHoldToOutput';
  error?: Maybe<MutationError>;
};

export enum ObjectIconsEnum {
  CustomsPaymentClient = 'CUSTOMS_PAYMENT_CLIENT',
  CustomsReceiptsInvoice = 'CUSTOMS_RECEIPTS_INVOICE',
  HasElpts = 'HAS_ELPTS',
  HasSbkts = 'HAS_SBKTS',
  IconAdditionalKeysExits = 'ICON_ADDITIONAL_KEYS_EXITS',
  IconAdditionalKeysPutInside = 'ICON_ADDITIONAL_KEYS_PUT_INSIDE',
  IconAdditionalKeysReceived = 'ICON_ADDITIONAL_KEYS_RECEIVED',
  IconAttachments = 'ICON_ATTACHMENTS',
  IconAttention = 'ICON_ATTENTION',
  IconAuctionDocuments = 'ICON_AUCTION_DOCUMENTS',
  IconAuctionPaid = 'ICON_AUCTION_PAID',
  IconBl = 'ICON_BL',
  IconBlockedBy_1C = 'ICON_BLOCKED_BY_1C',
  IconBlockedBySender = 'ICON_BLOCKED_BY_SENDER',
  IconBlockedByTrip = 'ICON_BLOCKED_BY_TRIP',
  IconBoundary = 'ICON_BOUNDARY',
  IconBroken = 'ICON_BROKEN',
  IconCarDelete = 'ICON_CAR_DELETE',
  IconComplete = 'ICON_COMPLETE',
  IconCopyAuctionDocs = 'ICON_COPY_AUCTION_DOCS',
  IconCut = 'ICON_CUT',
  IconDebt = 'ICON_DEBT',
  IconDelayedDelivery = 'ICON_DELAYED_DELIVERY',
  IconDocuments = 'ICON_DOCUMENTS',
  IconEmptyBattery = 'ICON_EMPTY_BATTERY',
  IconExportCertificate = 'ICON_EXPORT_CERTIFICATE',
  IconExportCertificateOriginal = 'ICON_EXPORT_CERTIFICATE_ORIGINAL',
  IconExportPrice = 'ICON_EXPORT_PRICE',
  IconFreightInvoiceNotPaid = 'ICON_FREIGHT_INVOICE_NOT_PAID',
  IconFreightInvoicePaid = 'ICON_FREIGHT_INVOICE_PAID',
  IconHasDisassemblyPhoto = 'ICON_HAS_DISASSEMBLY_PHOTO',
  IconHasDisassemblyVideo = 'ICON_HAS_DISASSEMBLY_VIDEO',
  IconHasOcrObject = 'ICON_HAS_OCR_OBJECT',
  IconHasRecipient = 'ICON_HAS_RECIPIENT',
  IconHasSbktsPdf = 'ICON_HAS_SBKTS_PDF',
  IconIsGreenCorner = 'ICON_IS_GREEN_CORNER',
  IconNeedFixSaw = 'ICON_NEED_FIX_SAW',
  IconNonPassLab = 'ICON_NON_PASS_LAB',
  IconNotDrivable = 'ICON_NOT_DRIVABLE',
  IconNoAuctionDocuments = 'ICON_NO_AUCTION_DOCUMENTS',
  IconNoCopyAuctionDocs = 'ICON_NO_COPY_AUCTION_DOCS',
  IconNoExportCertificate = 'ICON_NO_EXPORT_CERTIFICATE',
  IconNoExportCertificateOriginal = 'ICON_NO_EXPORT_CERTIFICATE_ORIGINAL',
  IconNoFuel = 'ICON_NO_FUEL',
  IconObjectMedia = 'ICON_OBJECT_MEDIA',
  IconPackages = 'ICON_PACKAGES',
  IconPaidStock = 'ICON_PAID_STOCK',
  IconPaySheet = 'ICON_PAY_SHEET',
  IconPbDocumentsIsDeclined = 'ICON_PB_DOCUMENTS_IS_DECLINED',
  IconPbDocumentsIsNotReceived = 'ICON_PB_DOCUMENTS_IS_NOT_RECEIVED',
  IconPbDocumentsIsReceived = 'ICON_PB_DOCUMENTS_IS_RECEIVED',
  IconPickUpAppointmentPlanned = 'ICON_PICK_UP_APPOINTMENT_PLANNED',
  IconPlannedVessel = 'ICON_PLANNED_VESSEL',
  IconReceiptOfPayment = 'ICON_RECEIPT_OF_PAYMENT',
  IconTemporaryPass = 'ICON_TEMPORARY_PASS',
  IconTickets = 'ICON_TICKETS',
  IconTrackingNumber = 'ICON_TRACKING_NUMBER'
}

export type ObjectInvoiceItemInput = {
  amount: Scalars['Float']['input'];
  complexAmount: Scalars['Float']['input'];
  freight: Scalars['Float']['input'];
  id: Scalars['Int']['input'];
};

export type ObjectKey = {
  __typename?: 'ObjectKey';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  stage?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TypeOfObjectKey>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ObjectLogEvent = {
  __typename?: 'ObjectLogEvent';
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ObjectMedia = {
  __typename?: 'ObjectMedia';
  items?: Maybe<Array<ObjectMediaItem>>;
  mediaType?: Maybe<MediaTypeEnum>;
};

export type ObjectMediaBlock = {
  __typename?: 'ObjectMediaBlock';
  id?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type ObjectMediaItem = {
  __typename?: 'ObjectMediaItem';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fullPath?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageOrder?: Maybe<Scalars['Int']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isPhoto?: Maybe<Scalars['Boolean']['output']>;
  thumbPath?: Maybe<Scalars['String']['output']>;
};

export type ObjectMirror = {
  __typename?: 'ObjectMirror';
  id?: Maybe<Scalars['Int']['output']>;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type ObjectNeedVideoWatchInput = {
  comment: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
  type: VideoWatchTypeEnum;
};

export type ObjectNeedVideoWatchOutput = {
  __typename?: 'ObjectNeedVideoWatchOutput';
  error?: Maybe<MutationError>;
};

export type ObjectNotification = {
  __typename?: 'ObjectNotification';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emails?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  phones?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ObjectOfServiceFilter = {
  aggregateTypes?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Дата покупки от */
  auctionDate?: InputMaybe<Scalars['String']['input']>;
  /** Дата покупки до (Если null берется конец дня auctionDate) */
  auctionDateTo?: InputMaybe<Scalars['String']['input']>;
  auctions?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** По названию филиала офиса */
  branchOffice?: InputMaybe<Scalars['String']['input']>;
  brokers?: InputMaybe<Array<Scalars['Int']['input']>>;
  buyByMonth?: InputMaybe<Scalars['String']['input']>;
  byDateIssueElpts?: InputMaybe<Scalars['String']['input']>;
  byDelayRequestDay?: InputMaybe<Scalars['String']['input']>;
  byInvoicePaymentType?: InputMaybe<Scalars['Boolean']['input']>;
  carTypes?: InputMaybe<Array<Scalars['Int']['input']>>;
  city?: InputMaybe<CityEnum>;
  containerNumber?: InputMaybe<Scalars['String']['input']>;
  customTypes?: InputMaybe<Array<Scalars['Int']['input']>>;
  dashboardTrip?: InputMaybe<Scalars['Int']['input']>;
  expiredDelayRequestDay?: InputMaybe<Scalars['Boolean']['input']>;
  forTrip?: InputMaybe<Scalars['Boolean']['input']>;
  formalOwners?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** С долгами по клиенту */
  hasClientDebts?: InputMaybe<Scalars['Boolean']['input']>;
  hasTicket?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inJapan?: InputMaybe<Scalars['Boolean']['input']>;
  independentlyPaymentType?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
  /** Заблокированные */
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  isBoundary?: InputMaybe<Scalars['Boolean']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDelayDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  isDisassembly?: InputMaybe<Scalars['Boolean']['input']>;
  isHasCustomInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isHasCustomPayment?: InputMaybe<Scalars['Boolean']['input']>;
  isHasElpts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Готовые к выдаче */
  isHasIssuedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  isHasPenalty?: InputMaybe<Scalars['Boolean']['input']>;
  isHasSbkts?: InputMaybe<Scalars['Boolean']['input']>;
  isHold?: InputMaybe<Scalars['Boolean']['input']>;
  isKaitai?: InputMaybe<Scalars['Boolean']['input']>;
  isPart?: InputMaybe<Scalars['Boolean']['input']>;
  isPassedTestDate?: InputMaybe<Scalars['String']['input']>;
  isRecycleCube?: InputMaybe<Scalars['Boolean']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Является донором */
  isScrap?: InputMaybe<Scalars['Boolean']['input']>;
  /** Является запчастью */
  isScrapObjects?: InputMaybe<Scalars['Boolean']['input']>;
  isSoldRecycleCube?: InputMaybe<Scalars['Boolean']['input']>;
  isYahoo?: InputMaybe<Scalars['Boolean']['input']>;
  ownStocks?: InputMaybe<Scalars['Boolean']['input']>;
  owners?: InputMaybe<Array<Scalars['Int']['input']>>;
  partVin?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['Int']['input']>;
  sawTypes?: InputMaybe<Array<Scalars['Int']['input']>>;
  sectorId?: InputMaybe<Scalars['Int']['input']>;
  senders?: InputMaybe<Array<Scalars['Int']['input']>>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  stocks?: InputMaybe<Array<Scalars['Int']['input']>>;
  trip?: InputMaybe<Scalars['Int']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  /** С долгами по типу пользователя */
  withDebts?: InputMaybe<Scalars['Boolean']['input']>;
  withStorageData?: InputMaybe<Scalars['Boolean']['input']>;
  withoutIssued?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ObjectOfServiceRemoveFromContainerInput = {
  containerId: Scalars['Int']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectOfServiceRemoveFromContainerOutput = {
  __typename?: 'ObjectOfServiceRemoveFromContainerOutput';
  error?: Maybe<MutationError>;
};

export type ObjectOfServiceSort = {
  holdAt?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  listingCreatedAt?: InputMaybe<SortOrderEnum>;
  locationId?: InputMaybe<SortOrderEnum>;
  statusAt?: InputMaybe<SortOrderEnum>;
};

export type ObjectOfServices = {
  __typename?: 'ObjectOfServices';
  accountantChatId?: Maybe<Scalars['Int']['output']>;
  activeHash?: Maybe<Scalars['String']['output']>;
  acts?: Maybe<Array<ChatForOthers>>;
  additionalType?: Maybe<TypeOfAdditional>;
  aggregateType?: Maybe<Aggregate>;
  aiParkingPass?: Maybe<ObjectParkingPass>;
  akebonoBuyer?: Maybe<Owner>;
  akebonoPenalty?: Maybe<AkebonoPenalty>;
  allKeys?: Maybe<Array<Maybe<ObjectKey>>>;
  ambassadors?: Maybe<Array<Ambassador>>;
  attachmentObjects?: Maybe<Array<ObjectOfServices>>;
  attachments?: Maybe<Scalars['String']['output']>;
  auction?: Maybe<ObjectAuction>;
  auctionHistory?: Maybe<Array<ObjectAuctionHistory>>;
  auctionListing?: Maybe<AuctionListing>;
  auctionPaySheet?: Maybe<AuctionPaySheet>;
  bls?: Maybe<Array<Bl>>;
  bookingRequests?: Maybe<Array<BookingRequest>>;
  branchOffice?: Maybe<Scalars['String']['output']>;
  broker?: Maybe<Broker>;
  /** @deprecated Use BrokerType */
  brokerNameForClient?: Maybe<Scalars['String']['output']>;
  /** Можно ли поставить объект на данный рейс */
  canAddToPlan?: Maybe<AddToPlanAttention>;
  /** Можно ли сделать претензию типа Таможенное оформление */
  canMakeCustomTicket?: Maybe<Scalars['Boolean']['output']>;
  /** Доступна ли загрузка таможенных документов */
  canUploadCustomDocuments?: Maybe<Scalars['Boolean']['output']>;
  carBuy?: Maybe<ObjectToSale>;
  carClientParam?: Maybe<CarClientParam>;
  carType?: Maybe<TypeOfExport>;
  carsClearance?: Maybe<ObjectClearance>;
  chassisType?: Maybe<TypeOfChassis>;
  chats?: Maybe<Array<ChatForOthers>>;
  /** Проверка возможности создать сделку перепродажи */
  checkAllowCreateResale?: Maybe<ObjectResaleActionCheck>;
  clientDebts?: Maybe<Array<Price>>;
  comment?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<ObjectComment>>;
  complexAmount?: Maybe<Scalars['Float']['output']>;
  /** Стоимость указанные в инвойсе */
  complexByInvoice?: Maybe<ComplexByInvoice>;
  /** Состояние грузо получателя */
  conditionConsignee?: Maybe<ConditionConsigneeEnum>;
  consigneeAddress?: Maybe<Scalars['String']['output']>;
  consigneeNameForClient?: Maybe<Scalars['String']['output']>;
  consignment?: Maybe<Scalars['Int']['output']>;
  container?: Maybe<Container>;
  creditsStorage?: Maybe<Array<Maybe<CreditStorage>>>;
  currentExportCertificate?: Maybe<Scalars['String']['output']>;
  currentExportTrip?: Maybe<ExportTrip>;
  currentOnPlan?: Maybe<OnPlan>;
  currentThirdPartyTrip?: Maybe<ThirdPartyTrip>;
  /** Набор документов для таможни */
  customDocument?: Maybe<ObjectCustomDocument>;
  /**
   * Набор документов для таможни
   * @deprecated Use customDocument
   */
  customDocuments?: Maybe<Array<Maybe<ObjectCustomDocument>>>;
  /** Способ оплаты таможни */
  customPaymentType?: Maybe<CustomPayment>;
  customType?: Maybe<TypeOfCustom>;
  customZone?: Maybe<Scalars['String']['output']>;
  /** Дата прибытия во Владивосток */
  dateArrivalVladivostok?: Maybe<Scalars['DateTime']['output']>;
  /** Дата покупки */
  dateBuy?: Maybe<Scalars['DateTime']['output']>;
  /** Предположительная дата получения авто с аукциона */
  dateCarWithAuction?: Maybe<Scalars['DateTime']['output']>;
  dateDismantleForCube?: Maybe<Scalars['DateTime']['output']>;
  /** Предположительная дата получения документов с аукциона */
  dateDocumentWithAuction?: Maybe<Scalars['DateTime']['output']>;
  /** Дата ближайшего рейса */
  dateNearTrip?: Maybe<Scalars['DateTime']['output']>;
  /** Предположительная дата выдачи клиету */
  dateOfIssue?: Maybe<Scalars['DateTime']['output']>;
  dateOfManufacture?: Maybe<Scalars['String']['output']>;
  dateRecyclingToCube?: Maybe<Scalars['DateTime']['output']>;
  dateSoldRecyclingCube?: Maybe<Scalars['DateTime']['output']>;
  /** Предположительная дата получения с аукциона. Max(dateCarWithAuction, dateDocumentWithAuction) */
  dateWithAuction?: Maybe<Scalars['DateTime']['output']>;
  daysPaidStorageAutoimport?: Maybe<Scalars['Int']['output']>;
  delayRequests?: Maybe<Array<ObjectDelayRequest>>;
  delivered?: Maybe<Array<ObjectDelivered>>;
  delivery?: Maybe<ObjectDelivery>;
  deliveryRequestFromPreDelivery?: Maybe<DeliveryRequest>;
  deliveryRequests?: Maybe<Array<DeliveryRequest>>;
  desiredTrip?: Maybe<DesiredTrip>;
  destinationCountry?: Maybe<Country>;
  diagnosticDoc?: Maybe<DiagnosticDoc>;
  disassemble?: Maybe<Disassemble>;
  /** Кол-во снятых колёс */
  disassembleWheelCount?: Maybe<Scalars['Int']['output']>;
  disassemblyState?: Maybe<DisassemblyStateEnum>;
  draftBroker?: Maybe<DraftBroker>;
  draftClient?: Maybe<DraftClient>;
  draftFreightObject?: Maybe<DraftFreightObject>;
  elpts?: Maybe<Elpts>;
  emailMessages?: Maybe<Array<ObjectNotification>>;
  exportCertificate?: Maybe<ExportCertificate>;
  exportCertificates?: Maybe<Array<ExportCertificate>>;
  exportPrice?: Maybe<Price>;
  exportTrips?: Maybe<Array<ExportTrip>>;
  feedback?: Maybe<CarFeedback>;
  files?: Maybe<Array<S3File>>;
  firstDeliveryCarrier?: Maybe<Carrier>;
  firstDeliveryRequest?: Maybe<DeliveryRequest>;
  /** FOB цена */
  fobPrice?: Maybe<Scalars['Int']['output']>;
  formalBroker?: Maybe<Broker>;
  formalOwner?: Maybe<FormalOwner>;
  formalOwnerState?: Maybe<FormalOwnerStateEnum>;
  freight?: Maybe<Scalars['Float']['output']>;
  freightCountryReason?: Maybe<Scalars['String']['output']>;
  /**
   * Количество дней до начала начислений
   * @deprecated Use paymentFreeDaysLeft
   */
  greenCornerFreeDaysLeft?: Maybe<Scalars['Int']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  /** @deprecated Delete un use */
  hasActiveDeliveryToAutoImport?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Delete un use */
  hasActiveDeliveryToTroyan?: Maybe<Scalars['Boolean']['output']>;
  hasAkebonoJpyDebts?: Maybe<Scalars['Boolean']['output']>;
  hasChassisNumber?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Delete un use */
  hasClosedDeliveryToTroyan?: Maybe<Scalars['Boolean']['output']>;
  hasFrameNumber?: Maybe<Scalars['Boolean']['output']>;
  hasStatus?: Maybe<Scalars['Boolean']['output']>;
  hasVin?: Maybe<Scalars['Boolean']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  headObject?: Maybe<ObjectOfServices>;
  holdTo?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inRussia?: Maybe<Scalars['Boolean']['output']>;
  inventoryObjects?: Maybe<Array<InventoryObject>>;
  invoice?: Maybe<Invoice>;
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesCustom?: Maybe<Array<InvoiceCustom>>;
  isAggregate?: Maybe<Scalars['Boolean']['output']>;
  /** Загружено изображение договора */
  isAgreementClientSigned?: Maybe<Scalars['Boolean']['output']>;
  isAutoImport?: Maybe<Scalars['Boolean']['output']>;
  /** Признак запроса букинга */
  isBookingRequested?: Maybe<Scalars['Boolean']['output']>;
  isBoundary?: Maybe<Scalars['Boolean']['output']>;
  /** Признак можно ли снимать колеса */
  isCanCreateWheel?: Maybe<Scalars['Boolean']['output']>;
  isCanEditByTroyan?: Maybe<Scalars['Boolean']['output']>;
  isCanFillSbkts?: Maybe<Scalars['Boolean']['output']>;
  /** Можно ли выдать объект */
  isCanIssue?: Maybe<Scalars['Boolean']['output']>;
  isCanRequestParking?: Maybe<Scalars['Boolean']['output']>;
  isCar?: Maybe<Scalars['Boolean']['output']>;
  isCarFinanceClearAkebono?: Maybe<Scalars['Boolean']['output']>;
  isCarFinanceClearFull?: Maybe<Scalars['Boolean']['output']>;
  isCarFinanceClearNavis?: Maybe<Scalars['Boolean']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  isDisassemble?: Maybe<Scalars['Boolean']['output']>;
  isFullFilledSbktsData?: Maybe<Scalars['Boolean']['output']>;
  /** Автозачет делается на общих основаниях */
  isGeneralBasisAdvance?: Maybe<Scalars['Boolean']['output']>;
  isGlonassNeed?: Maybe<Scalars['Boolean']['output']>;
  /** Зелёный угол */
  isGreenCorner?: Maybe<Scalars['Boolean']['output']>;
  isHaveVehicleRegistrationNumber?: Maybe<Scalars['Boolean']['output']>;
  isImpassable?: Maybe<Scalars['Boolean']['output']>;
  isMoto?: Maybe<Scalars['Boolean']['output']>;
  isNeedConsignee?: Maybe<Scalars['Boolean']['output']>;
  isNeedVideo?: Maybe<Scalars['Boolean']['output']>;
  isNoAutoFreightCountry?: Maybe<Scalars['Boolean']['output']>;
  isNumberCut?: Maybe<Scalars['Boolean']['output']>;
  isOnDisassemblyStock?: Maybe<Scalars['Boolean']['output']>;
  isOwnOwner?: Maybe<Scalars['Boolean']['output']>;
  isPaidStorageStarted?: Maybe<Scalars['Boolean']['output']>;
  isPart?: Maybe<Scalars['Boolean']['output']>;
  /** Приняты\Не получены документы\Отклонены (если есть pbPassportDeclineComment) */
  isPbReceivedPassportCopy?: Maybe<Scalars['Boolean']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  isPrimbroker?: Maybe<Scalars['Boolean']['output']>;
  isPriorityPass?: Maybe<Scalars['Boolean']['output']>;
  /** Признак что файлы поданные для таможни находятся в очереди их пред обработки */
  isQueueCustomFile?: Maybe<Scalars['Boolean']['output']>;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  /** Когда у клиента запрошены документы для таможни игнорируя статус Погружен */
  isRequestDocumentsOfCustoms?: Maybe<Scalars['DateTime']['output']>;
  /** Запросить квитанцию для предварительного пополнения лицевого счета на ФТС */
  isRequestReceiptFcs?: Maybe<Scalars['Boolean']['output']>;
  /** Заказан пакет документов для жителей не ДВФО */
  isRequestedPrepareDocuments?: Maybe<Scalars['Boolean']['output']>;
  isSaw?: Maybe<Scalars['Boolean']['output']>;
  /** Есть претензии */
  isTicket?: Maybe<Scalars['Boolean']['output']>;
  isTroyan?: Maybe<Scalars['Boolean']['output']>;
  /** Загружен ли набор документов и подтвержден */
  isUploadedCustomDocuments?: Maybe<Scalars['Boolean']['output']>;
  isVladTrek?: Maybe<Scalars['Boolean']['output']>;
  isYahoo?: Maybe<Scalars['Boolean']['output']>;
  issueBlocks?: Maybe<Array<IssueBlock>>;
  /** Список оплат УСО по Японии */
  japanPayments?: Maybe<Array<Maybe<JapanPaymentsToRequest>>>;
  jpTrackingNumberForPlate?: Maybe<Scalars['String']['output']>;
  kaitaiParam?: Maybe<KaitaiParam>;
  keys?: Maybe<Array<Maybe<ObjectKey>>>;
  keysCount?: Maybe<Scalars['Int']['output']>;
  labelElementType?: Maybe<Scalars['String']['output']>;
  labelEmbeddedType?: Maybe<Scalars['String']['output']>;
  /** Лаборатория */
  laboratory?: Maybe<Laboratory>;
  lastArrivalToAutoImport?: Maybe<StorageDaysByStock>;
  lastStatusToObject?: Maybe<StatusToObject>;
  /** @deprecated Use StockType */
  locationNameForChat?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced to logObjectEvents query */
  logEvents?: Maybe<Array<ObjectLogEvent>>;
  masterAccountClient?: Maybe<Owner>;
  media?: Maybe<Array<ObjectMedia>>;
  /** @deprecated Useless, remove */
  mirrors?: Maybe<Array<ObjectMirror>>;
  name?: Maybe<Scalars['String']['output']>;
  name1c?: Maybe<Scalars['String']['output']>;
  notRejectRecipient?: Maybe<Recipient>;
  numberCut?: Maybe<Scalars['String']['output']>;
  objectActions?: Maybe<Array<ObjectActionsEnum>>;
  objectCount?: Maybe<Scalars['Int']['output']>;
  objectDisassemble?: Maybe<ObjectDisassemble>;
  objectIcons?: Maybe<Array<ObjectIconsEnum>>;
  onPlans?: Maybe<Array<OnPlan>>;
  owner?: Maybe<Owner>;
  /** Параметры платного хранения */
  paidStorageData?: Maybe<ObjectPaidStorageData>;
  parkginRequestCancel?: Maybe<ObjectParkingRequestCancel>;
  parkginRequestsCancel?: Maybe<Array<Maybe<ObjectParkingRequestCancel>>>;
  parkingRequest?: Maybe<ObjectParkingRequest>;
  partObjects?: Maybe<Array<ObjectOfServices>>;
  partRelationType?: Maybe<ObjectRelationTypeEnum>;
  payCountry?: Maybe<Country>;
  /** Количество дней до начала начислений */
  paymentFreeDaysLeft?: Maybe<Scalars['Int']['output']>;
  /** План (тариф) оплаты объекта */
  paymentPlan?: Maybe<PaymentPlan>;
  pbAgreement?: Maybe<PbAgreement>;
  pbAgreementBlank?: Maybe<S3File>;
  pbAgreements?: Maybe<Array<Maybe<PbAgreement>>>;
  /** Причина отмены документов */
  pbPassportDeclineComment?: Maybe<Scalars['String']['output']>;
  photoVisibleBlock?: Maybe<ObjectMediaBlock>;
  /** Планируемый тип судна */
  plannedVesselType?: Maybe<VesselTypeInfo>;
  preDeliveryObject?: Maybe<PreDeliveryObject>;
  preDeliveryObjects?: Maybe<Array<PreDeliveryObject>>;
  quantityStorageDays?: Maybe<Scalars['Int']['output']>;
  radiation?: Maybe<Scalars['String']['output']>;
  /** Данные оплаты */
  receiptOfPayment?: Maybe<ReceiptOfPayment>;
  /** Данные оплаты */
  receiptOfPayments?: Maybe<Array<Maybe<ReceiptOfPayment>>>;
  recipientHash?: Maybe<Scalars['String']['output']>;
  /** Ссылка на грузополучателя */
  recipientUrl?: Maybe<Scalars['String']['output']>;
  recipients?: Maybe<Array<Recipient>>;
  /** Юридическое лицо «для коммерческих целей» */
  registrationForCommercialPurposes?: Maybe<Scalars['Boolean']['output']>;
  requestToIssueObject?: Maybe<RequestToIssueObject>;
  /** Сделка перепродажи */
  resale?: Maybe<ObjectResale>;
  rightSector?: Maybe<Sector>;
  sawType?: Maybe<TypeOfSaw>;
  sbktsRequest?: Maybe<SbktsRequest>;
  sbktsRequests?: Maybe<Array<SbktsRequest>>;
  scrapObject?: Maybe<ScrapObject>;
  scrapObjectsByParent?: Maybe<Array<ScrapObject>>;
  sender?: Maybe<Sender>;
  senderObject?: Maybe<ObjectSender>;
  /** Запрошенные услуги по объекту */
  servicesRequests?: Maybe<Array<ServiceRequest>>;
  /** Дата продажи авто Дубаи, Монголия и т.д */
  soldDate?: Maybe<Scalars['DateTime']['output']>;
  statusReadyForClient?: Maybe<StatusToObject>;
  statusReleasedFromCustoms?: Maybe<StatusToObject>;
  statusReleasedFromStock?: Maybe<StatusToObject>;
  statuses?: Maybe<Array<StatusToObject>>;
  stock?: Maybe<Stock>;
  storageDaysByStock?: Maybe<Array<StorageDaysByStock>>;
  sumAkebonoJpyDebts?: Maybe<Scalars['Int']['output']>;
  svhIssuedDate?: Maybe<Scalars['DateTime']['output']>;
  tariffModel?: Maybe<Tariff>;
  thingsAttachments?: Maybe<Array<ObjectAttachment>>;
  thingsAttachmentsLog?: Maybe<Array<ObjectAttachmentTypeLog>>;
  thirdPartyTrips?: Maybe<Array<ThirdPartyTrip>>;
  tickets?: Maybe<Array<Ticket>>;
  title?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Array<Maybe<LotTranslation>>>;
  troyanCustomer?: Maybe<TroyanCustomer>;
  troyanCustomers?: Maybe<Array<Maybe<TroyanCustomer>>>;
  vin?: Maybe<Scalars['String']['output']>;
  watch?: Maybe<Watch>;
  wheelOptions?: Maybe<Wheel>;
  yahooCode?: Maybe<Scalars['String']['output']>;
  yahooConsignmentName?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};


export type ObjectOfServicesCanAddToPlanArgs = {
  planId: Scalars['Int']['input'];
};


export type ObjectOfServicesDeliveryRequestsArgs = {
  organization?: InputMaybe<OrganizationEnum>;
};


export type ObjectOfServicesFilesArgs = {
  type?: InputMaybe<MediaTypeEnum>;
};


export type ObjectOfServicesHasStatusArgs = {
  statusId: Scalars['Int']['input'];
};


export type ObjectOfServicesMediaArgs = {
  types?: InputMaybe<Array<MediaTypeEnum>>;
};


export type ObjectOfServicesObjectActionsArgs = {
  actions?: InputMaybe<Array<ObjectActionsEnum>>;
};


export type ObjectOfServicesObjectIconsArgs = {
  icons?: InputMaybe<Array<ObjectIconsEnum>>;
};

export type ObjectPackage = {
  __typename?: 'ObjectPackage';
  attachments?: Maybe<Array<ObjectAttachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  emailSentAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']['output']>;
  isReturnableToOffice?: Maybe<Scalars['Boolean']['output']>;
  isReturnableToTrip?: Maybe<Scalars['Boolean']['output']>;
  issuedAt?: Maybe<Scalars['DateTime']['output']>;
  issuedBy?: Maybe<User>;
  object?: Maybe<ObjectOfServices>;
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  recipient?: Maybe<User>;
  tripBox?: Maybe<TripBox>;
};

export type ObjectPaidStorageData = {
  __typename?: 'ObjectPaidStorageData';
  /** Дней до начала начислений на склад */
  freeDaysLeft?: Maybe<Scalars['Int']['output']>;
  /** Прошло дней с начислением */
  paidDays?: Maybe<Scalars['Int']['output']>;
  /** Сумма к начислению */
  paidDaysSum?: Maybe<Scalars['Int']['output']>;
};

export type ObjectParkingPass = {
  __typename?: 'ObjectParkingPass';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isReady?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  type?: Maybe<MediaTypeEnum>;
};

export type ObjectParkingRequest = {
  __typename?: 'ObjectParkingRequest';
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hasOutFromSVHStatus?: Maybe<Scalars['Boolean']['output']>;
  histories?: Maybe<Array<ObjectParkingRequestHistory>>;
  id?: Maybe<Scalars['Int']['output']>;
  invoices?: Maybe<Array<Invoice>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isCanCancel?: Maybe<Scalars['Boolean']['output']>;
  isCanClose?: Maybe<Scalars['Boolean']['output']>;
  isCanRequestPass?: Maybe<Scalars['Boolean']['output']>;
  isCanUploadFiles?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHasBeforeOuterParkingPhoto?: Maybe<Scalars['Boolean']['output']>;
  isHasOuterParkingPhoto?: Maybe<Scalars['Boolean']['output']>;
  isPrepared?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ObjectParkingRequestCancel = {
  __typename?: 'ObjectParkingRequestCancel';
  comment?: Maybe<Scalars['String']['output']>;
  commentAi?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<ParkingRequestCanceledTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type ObjectParkingRequestHistory = {
  __typename?: 'ObjectParkingRequestHistory';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  user?: Maybe<User>;
};

export type ObjectPlanFilter = {
  contract?: InputMaybe<Scalars['Int']['input']>;
  plan?: InputMaybe<Scalars['Int']['input']>;
  trip?: InputMaybe<Scalars['Int']['input']>;
};

export type ObjectRecipientPaymentEmailInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectRecipientPaymentEmailOutput = {
  __typename?: 'ObjectRecipientPaymentEmailOutput';
  error?: Maybe<MutationError>;
};

export enum ObjectRelationTypeEnum {
  Attachment = 'ATTACHMENT',
  Saw = 'SAW',
  Self = 'SELF'
}

export type ObjectRequestToParkingInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectRequestToParkingOutput = {
  __typename?: 'ObjectRequestToParkingOutput';
  error?: Maybe<MutationError>;
};

export type ObjectRequestToPreparationDocumentsInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectRequestToPreparationDocumentsOutput = {
  __typename?: 'ObjectRequestToPreparationDocumentsOutput';
  error?: Maybe<MutationError>;
};

export type ObjectResale = {
  __typename?: 'ObjectResale';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  buyer?: Maybe<Owner>;
  buyerApprovedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Долги покупателя */
  buyerDebts?: Maybe<Array<Price>>;
  buyerEmail?: Maybe<Scalars['String']['output']>;
  buyerFullName?: Maybe<Scalars['String']['output']>;
  buyerPhone?: Maybe<Scalars['String']['output']>;
  checkAllowApprove?: Maybe<ObjectResaleActionCheck>;
  checkAllowComplete?: Maybe<ObjectResaleActionCheck>;
  checkAllowDelete?: Maybe<ObjectResaleActionCheck>;
  checkAllowUpdate?: Maybe<ObjectResaleActionCheck>;
  /** Сумма комиссии за услугу перепродажи */
  comissionAmount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  /** Сумма фрахта для покупателя */
  freightAmount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Текущий юзер - покупатель */
  isBuyer?: Maybe<Scalars['Boolean']['output']>;
  /** Текущий юзер - продавец */
  isSeller?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  price?: Maybe<Price>;
  seller?: Maybe<Owner>;
  sellerApprovedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Долги продавца */
  sellerDebts?: Maybe<Array<Price>>;
  /** Сумма фрахта для продавца */
  sellerFreightAmount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
};

export type ObjectResaleActionCheck = {
  __typename?: 'ObjectResaleActionCheck';
  reason?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Boolean']['output']>;
};

export type ObjectResaleApproveInput = {
  resaleId: Scalars['Int']['input'];
};

export type ObjectResaleApproveOutput = {
  __typename?: 'ObjectResaleApproveOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResaleClientCreateInput = {
  amount: Scalars['Int']['input'];
  buyerEmail: Scalars['String']['input'];
  buyerFullName: Scalars['String']['input'];
  buyerPhone: Scalars['String']['input'];
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectResaleClientCreateOutput = {
  __typename?: 'ObjectResaleClientCreateOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResaleClientUpdateInput = {
  amount: Scalars['Int']['input'];
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  resaleId: Scalars['Int']['input'];
};

export type ObjectResaleClientUpdateOutput = {
  __typename?: 'ObjectResaleClientUpdateOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResaleCompleteInput = {
  /** Сумма комиссии за услугу перепродажи */
  comissionAmount: Scalars['Int']['input'];
  /** Сумма фрахта для покупателя */
  freightAmount: Scalars['Int']['input'];
  resaleId: Scalars['Int']['input'];
  /** Сумма фрахта для продавца */
  sellerFreightAmount: Scalars['Int']['input'];
};

export type ObjectResaleCompleteOutput = {
  __typename?: 'ObjectResaleCompleteOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResaleDeleteInput = {
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  resaleId: Scalars['Int']['input'];
};

export type ObjectResaleDeleteOutput = {
  __typename?: 'ObjectResaleDeleteOutput';
  error?: Maybe<MutationError>;
};

export type ObjectResaleFilter = {
  buyerId?: InputMaybe<Scalars['Int']['input']>;
  createdFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Не отменённые и не завершённые */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ожидающие подтверждения текущим пользователем */
  isNeedApprove?: InputMaybe<Scalars['Boolean']['input']>;
  sellerId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type ObjectResaleInvoiceCreateInput = {
  resaleId: Scalars['Int']['input'];
};

export type ObjectResaleInvoiceCreateOutput = {
  __typename?: 'ObjectResaleInvoiceCreateOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResaleManagerCreateInput = {
  amount: Scalars['Int']['input'];
  buyerAgentId?: InputMaybe<Scalars['Int']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerFullName?: InputMaybe<Scalars['String']['input']>;
  buyerPhone?: InputMaybe<Scalars['String']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
};

export type ObjectResaleManagerCreateOutput = {
  __typename?: 'ObjectResaleManagerCreateOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResaleManagerUpdateInput = {
  amount: Scalars['Int']['input'];
  buyerAgentId?: InputMaybe<Scalars['Int']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerFullName?: InputMaybe<Scalars['String']['input']>;
  buyerPhone?: InputMaybe<Scalars['String']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  resaleId: Scalars['Int']['input'];
};

export type ObjectResaleManagerUpdateOutput = {
  __typename?: 'ObjectResaleManagerUpdateOutput';
  error?: Maybe<MutationError>;
  resale?: Maybe<ObjectResale>;
};

export type ObjectResalesFilter = {
  objectResale?: InputMaybe<ObjectResaleFilter>;
};

export type ObjectResalesPaginatedList = {
  __typename?: 'ObjectResalesPaginatedList';
  nodes: Array<ObjectResale>;
  total: Scalars['Int']['output'];
};

export type ObjectResetInput = {
  objectId: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type ObjectResetOutput = {
  __typename?: 'ObjectResetOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSendInvoiceEmailInput = {
  guid: Scalars['String']['input'];
  types: Array<InputMaybe<InvoiceEmailTypeEnum>>;
};

export type ObjectSendInvoiceEmailOutput = {
  __typename?: 'ObjectSendInvoiceEmailOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSender = {
  __typename?: 'ObjectSender';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  senderClient?: Maybe<SenderAgent>;
};

export type ObjectServiceRequestFilter = {
  /** Дата создания заявки от */
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата создания заявки до */
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Поиск по кузову */
  searchByVin?: InputMaybe<Scalars['String']['input']>;
  /** Статус заявки */
  status?: InputMaybe<ServiceRequestStatusEnum>;
  /** Тип услуги */
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type ObjectSetDateDismantleForCubeInput = {
  date: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectSetDateDismantleForCubeOutput = {
  __typename?: 'ObjectSetDateDismantleForCubeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetDateRecyclingToCubeInput = {
  date: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectSetDateRecyclingToCubeOutput = {
  __typename?: 'ObjectSetDateRecyclingToCubeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetDateSoldRecyclingCubeInput = {
  date: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectSetDateSoldRecyclingCubeOutput = {
  __typename?: 'ObjectSetDateSoldRecyclingCubeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetGuidFrom1cInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectSetGuidFrom1cOutput = {
  __typename?: 'ObjectSetGuidFrom1cOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetIsGeneralBasisAdvanceInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectSetIsGeneralBasisAdvanceOutput = {
  __typename?: 'ObjectSetIsGeneralBasisAdvanceOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetIsGreenCornerInput = {
  objectId: Scalars['Int']['input'];
};

export type ObjectSetIsGreenCornerOutput = {
  __typename?: 'ObjectSetIsGreenCornerOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetKaitaiPriceInput = {
  objectId: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
};

export type ObjectSetKaitaiPriceOutput = {
  __typename?: 'ObjectSetKaitaiPriceOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetMasterAccountClientInput = {
  clientId: Scalars['DateTime']['input'];
  objectId: Scalars['Int']['input'];
};

export type ObjectSetMasterAccountClientOutput = {
  __typename?: 'ObjectSetMasterAccountClientOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetSoldDateInput = {
  objectId: Scalars['Int']['input'];
  soldDate: Scalars['DateTime']['input'];
};

export type ObjectSetSoldDateOutput = {
  __typename?: 'ObjectSetSoldDateOutput';
  error?: Maybe<MutationError>;
};

export enum ObjectStateEnum {
  Car = 'CAR',
  Cut = 'CUT'
}

export type ObjectToSale = {
  __typename?: 'ObjectToSale';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Price>;
  saleAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ObjectToggleHideInput = {
  id: Scalars['Int']['input'];
};

export type ObjectToggleHideOutput = {
  __typename?: 'ObjectToggleHideOutput';
  error?: Maybe<MutationError>;
};

export type ObjectToggleRemoveInput = {
  id: Scalars['Int']['input'];
};

export type ObjectToggleRemoveOutput = {
  __typename?: 'ObjectToggleRemoveOutput';
  error?: Maybe<MutationError>;
};

export type ObjectUploadCustomDocumentsInput = {
  /** Файл подписанный договор ПБ клиентом */
  agreementKeyS3File?: InputMaybe<Scalars['String']['input']>;
  boardingPassesKeyS3Files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Файл справка из гостиницы с чеком об оплате */
  certificateFromHotelKeyS3Files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Флаг требуется явка в таможню */
  comeToCustoms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Комментарий */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Файл объяснительной */
  explanatoryKeyS3File?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Файл объяснительная (не ДВФО) */
  explanatoryNoDVFOKeyS3File?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Файл ИНН pdf */
  innKeyS3File?: InputMaybe<Scalars['String']['input']>;
  /** Дата создания инвойса */
  invoiceCreateDate?: InputMaybe<Scalars['String']['input']>;
  /** Дата оплаты инвойса */
  invoiceDate?: InputMaybe<Scalars['String']['input']>;
  /** Файл инвойс оплаты */
  invoiceKeyS3Files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Номер инвойса */
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  /** Файл паспорт PDF */
  passportKeyS3Files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Файл квитанция об оплате покупки */
  receiptKeyS3File?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Файл подтверждение поступления денежных средств на счет ФТС */
  receiptOfMoneyToFtsS3File?: InputMaybe<Scalars['String']['input']>;
  /** Файл временной регистрации */
  tempRegistrationKeyS3File?: InputMaybe<Scalars['String']['input']>;
  /** Файл проверки свидетельства о регистрации на действительность */
  validationTempRegistrationS3File?: InputMaybe<Scalars['String']['input']>;
};

export type ObjectUploadCustomDocumentsOutput = {
  __typename?: 'ObjectUploadCustomDocumentsOutput';
  error?: Maybe<MutationError>;
};

export type ObjectUploadCustomPaymentInput = {
  keyS3Files: Array<InputMaybe<Scalars['String']['input']>>;
  objectId: Scalars['Int']['input'];
};

export type ObjectUploadCustomPaymentOutput = {
  __typename?: 'ObjectUploadCustomPaymentOutput';
  error?: Maybe<MutationError>;
};

export type ObjectUploadFilesInput = {
  isFile: Scalars['Boolean']['input'];
  keyS3Files: Array<Scalars['String']['input']>;
  mediaType: MediaTypeEnum;
  objectId: Scalars['Int']['input'];
};

export type ObjectUploadFilesOutput = {
  __typename?: 'ObjectUploadFilesOutput';
  error?: Maybe<MutationError>;
};

export type ObjectWorldWideFilter = {
  container?: InputMaybe<ContainerFilterEnum>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  exportCertificate?: InputMaybe<Scalars['String']['input']>;
  isBookingRequested?: InputMaybe<Scalars['Boolean']['input']>;
  isWorldWide?: InputMaybe<Scalars['Boolean']['input']>;
  managerId?: InputMaybe<Scalars['Int']['input']>;
  trip?: InputMaybe<TripFilterEnum>;
  vesselTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type OnPlan = {
  __typename?: 'OnPlan';
  boardingAt?: Maybe<Scalars['DateTime']['output']>;
  contract?: Maybe<Scalars['Int']['output']>;
  hasGeneratedBl?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAttention?: Maybe<Scalars['Boolean']['output']>;
  isCancelled?: Maybe<Scalars['Boolean']['output']>;
  jpCheckerNumber?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<Plan>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantityCancelled?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<PlanCancellationReason>;
  unloadingAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type OnlyTasksFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OperationalObjectFilter = {
  broker?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isExtra?: InputMaybe<Scalars['Boolean']['input']>;
  planDate?: InputMaybe<Scalars['String']['input']>;
};

export type OperationalObjectsFilter = {
  operationalObject?: InputMaybe<OperationalObjectFilter>;
};

export type OperationalObjectsPaginatedList = {
  __typename?: 'OperationalObjectsPaginatedList';
  nodes: Array<SbktsObjectToPlan>;
  total: Scalars['Int']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OrganizationEnum {
  AkebonoLtd = 'AKEBONO_LTD',
  Autoimport = 'AUTOIMPORT'
}

export enum OrganizationGuidEnum {
  AkebonoLtdGuid = 'AKEBONO_LTD_GUID',
  AkebonoLtdRGuid = 'AKEBONO_LTD_R_GUID',
  NavisShippingGuid = 'NAVIS_SHIPPING_GUID',
  NavisShippingJscGuid = 'NAVIS_SHIPPING_JSC_GUID',
  PrimbrokerGuid = 'PRIMBROKER_GUID'
}

export type Owner = {
  __typename?: 'Owner';
  address?: Maybe<Scalars['String']['output']>;
  advanceAkebonoBalance?: Maybe<Scalars['Int']['output']>;
  akebonoData?: Maybe<OwnerAkebono>;
  akebonoPenalties?: Maybe<OwnerAkebonoPenalty>;
  allUser?: Maybe<Array<User>>;
  availableDepositAmounts?: Maybe<Array<Scalars['Int']['output']>>;
  balance?: Maybe<OwnerBalance>;
  comments?: Maybe<Array<OwnerComment>>;
  countryIso?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to formal owner */
  dataForDocuments?: Maybe<OwnerData>;
  daysOfFreeStorageOnDelivery?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Scalars['String']['output']>>;
  engLegalAddress?: Maybe<Scalars['String']['output']>;
  engName?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  guid1c?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  invoiceBank?: Maybe<BankInvoice>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesCustom?: Maybe<Array<InvoiceCustom>>;
  isAcceptedStorageAgreement?: Maybe<Scalars['Boolean']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isBroker?: Maybe<Scalars['Boolean']['output']>;
  isDealer?: Maybe<Scalars['Boolean']['output']>;
  isExportToOtherCountry?: Maybe<Scalars['Boolean']['output']>;
  isHasBlockedObjects?: Maybe<Scalars['Boolean']['output']>;
  isHasOldDebts?: Maybe<Scalars['Boolean']['output']>;
  isHasPenalty?: Maybe<Scalars['Boolean']['output']>;
  isMasterAccount?: Maybe<Scalars['Boolean']['output']>;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  isSender?: Maybe<Scalars['Boolean']['output']>;
  isUnfairPartner?: Maybe<Scalars['Boolean']['output']>;
  isWarning?: Maybe<Scalars['Boolean']['output']>;
  lastPlannedObject?: Maybe<ObjectOfServices>;
  lastPurchasedObject?: Maybe<ObjectOfServices>;
  logo?: Maybe<S3File>;
  lotteryTickets?: Maybe<Array<Maybe<LotteryTicker>>>;
  name?: Maybe<Scalars['String']['output']>;
  notPaidBidsDisplayStatus?: Maybe<PaidBidsDisplayStatus>;
  objectsStatistic?: Maybe<Scalars['Int']['output']>;
  paidBidsDisplayStatuses?: Maybe<Array<Maybe<PaidBidsDisplayStatus>>>;
  phones?: Maybe<Array<Scalars['String']['output']>>;
  senderAgents?: Maybe<Array<SenderAgent>>;
  senderCode?: Maybe<Scalars['String']['output']>;
  senderComment?: Maybe<Scalars['String']['output']>;
  senderEmails?: Maybe<Array<Scalars['String']['output']>>;
  senderFax?: Maybe<Scalars['String']['output']>;
  senderPhone?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Scalars['String']['output']>;
  tariffModel?: Maybe<Tariff>;
  userBroker?: Maybe<User>;
  userRecipient?: Maybe<User>;
  userSender?: Maybe<User>;
  uuid?: Maybe<Scalars['String']['output']>;
  yahooUser?: Maybe<Scalars['Int']['output']>;
};


export type OwnerBalanceArgs = {
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
};


export type OwnerObjectsStatisticArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
  type: TypeOfOwnerEnum;
};

export type OwnerAgentsFilter = {
  senderAgentsSearch?: InputMaybe<SenderAgentsSearchFilter>;
};

export type OwnerAgentsPaginatedList = {
  __typename?: 'OwnerAgentsPaginatedList';
  nodes: Array<Owner>;
  total: Scalars['Int']['output'];
};

export type OwnerAgreement = {
  __typename?: 'OwnerAgreement';
  nameAgreement?: Maybe<Scalars['String']['output']>;
  nameCurrency?: Maybe<Scalars['String']['output']>;
  nameOrganization?: Maybe<Scalars['String']['output']>;
  sumActive?: Maybe<Scalars['Float']['output']>;
  sumBalance?: Maybe<Scalars['Float']['output']>;
  sumPassive?: Maybe<Scalars['Float']['output']>;
};

export type OwnerAkebono = {
  __typename?: 'OwnerAkebono';
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type OwnerAkebonoPenalty = {
  __typename?: 'OwnerAkebonoPenalty';
  totalBases?: Maybe<Array<Money>>;
  totalPenaltiesRub?: Maybe<Money>;
  totalProposedPenaltiesJpy?: Maybe<Money>;
  totalProposedPenaltiesRub?: Maybe<Money>;
  totalTaxes?: Maybe<Array<Money>>;
};

export type OwnerBalance = {
  __typename?: 'OwnerBalance';
  advances?: Maybe<Array<Money>>;
  advancesByOrganization?: Maybe<Array<BalanceByOrganization>>;
  debts?: Maybe<Array<Money>>;
  deposits?: Maybe<Array<Money>>;
};

export type OwnerComment = {
  __typename?: 'OwnerComment';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type OwnerData = {
  __typename?: 'OwnerData';
  address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OwnerFeedback = {
  __typename?: 'OwnerFeedback';
  /** Id пользователя */
  id?: Maybe<Scalars['Int']['output']>;
  /** Ссылка на лот */
  link?: Maybe<Scalars['String']['output']>;
  /** Колличество покупок совешенных пользователем */
  purchase?: Maybe<Scalars['Int']['output']>;
  /** Рейтинг пользователя */
  rating?: Maybe<UserRatingEnum>;
};

export type OwnerWithAgreements = {
  __typename?: 'OwnerWithAgreements';
  agent?: Maybe<Owner>;
  agreementsList?: Maybe<Array<OwnerAgreement>>;
  guidClient?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OwnersPaginatedList = {
  __typename?: 'OwnersPaginatedList';
  nodes: Array<Owner>;
  total: Scalars['Int']['output'];
};

export type PackageFilter = {
  partVin?: InputMaybe<Scalars['String']['input']>;
  /** Готовые к выдачи */
  showIssued?: InputMaybe<Scalars['Boolean']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};

export type PackagesFilter = {
  package?: InputMaybe<PackageFilter>;
};

export type PackagesPaginatedList = {
  __typename?: 'PackagesPaginatedList';
  nodes: Array<ObjectPackage>;
  total: Scalars['Int']['output'];
};

export type PaidBidsDisplayStatus = {
  __typename?: 'PaidBidsDisplayStatus';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  datePaidAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  paidBidsDisplayPrice?: Maybe<Scalars['Int']['output']>;
};

export type Pallet = {
  __typename?: 'Pallet';
  /** ID палеты */
  id?: Maybe<Scalars['Int']['output']>;
  /** Объект на складе порта */
  isInPort?: Maybe<Scalars['Boolean']['output']>;
  /** Название плаеты */
  name?: Maybe<Scalars['String']['output']>;
  /** ID номер */
  number?: Maybe<Scalars['String']['output']>;
  /** Список колес */
  objects?: Maybe<Array<Maybe<ObjectOfServices>>>;
  /** Колличество бъектов */
  objectsCount?: Maybe<Scalars['Int']['output']>;
  /** План отгрузки */
  plan?: Maybe<Plan>;
  /** Колличество колес */
  wheelCount?: Maybe<Scalars['Int']['output']>;
};

export type PalletAddWheelInput = {
  objectsId: Array<InputMaybe<Scalars['Int']['input']>>;
  palletNumber: Scalars['String']['input'];
};

export type PalletAddWheelOutput = {
  __typename?: 'PalletAddWheelOutput';
  error?: Maybe<MutationError>;
};

export type PalletCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PalletCreateOutput = {
  __typename?: 'PalletCreateOutput';
  error?: Maybe<MutationError>;
};

export type PalletFilter = {
  /** Пустой */
  empty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Готов к отгрузке */
  readyToTrip?: InputMaybe<Scalars['Boolean']['input']>;
  /** Поставлен на рейс */
  toTrip?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PalletToPlanInput = {
  palletNumber: Scalars['String']['input'];
  tripId: Scalars['Int']['input'];
};

export type PalletToPlanOutput = {
  __typename?: 'PalletToPlanOutput';
  error?: Maybe<MutationError>;
};

export type PalletToPortInput = {
  palletNumber: Scalars['String']['input'];
};

export type PalletToPortOutput = {
  __typename?: 'PalletToPortOutput';
  error?: Maybe<MutationError>;
  failedObjects?: Maybe<Array<ObjectOfServices>>;
};

export type PalletsFilter = {
  pallet?: InputMaybe<PalletFilter>;
};

export type PalletsPaginatedList = {
  __typename?: 'PalletsPaginatedList';
  nodes: Array<Pallet>;
  total: Scalars['Int']['output'];
};

export enum ParkingRequestCanceledTypeEnum {
  Both = 'BOTH',
  Parking = 'PARKING',
  ToAutoimport = 'TO_AUTOIMPORT'
}

export type PartTemplate = {
  __typename?: 'PartTemplate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  comment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<PaymentHistoryItem>>;
  sum?: Maybe<Scalars['String']['output']>;
};

export type PaymentHistoryItem = {
  __typename?: 'PaymentHistoryItem';
  contractName?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  sum?: Maybe<Scalars['String']['output']>;
};

export type PaymentPlan = {
  __typename?: 'PaymentPlan';
  /** Ставки плана оплаты */
  conditions?: Maybe<Array<PaymentPlanCondition>>;
  /** Доп. описание */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Краткое обозначение */
  name: Scalars['String']['output'];
};

export type PaymentPlanCondition = {
  __typename?: 'PaymentPlanCondition';
  /** Период до(-)/после(+) события */
  days: Scalars['Int']['output'];
  /** Событие */
  event: PaymentPlanEventEnum;
  id: Scalars['Int']['output'];
  /** Процентная ставка */
  percentage: Scalars['Int']['output'];
};

export enum PaymentPlanEventEnum {
  /** Добавлен в план */
  AddToPlan = 'ADD_TO_PLAN',
  /** Дата прибытия в порт */
  BeforeArrived = 'BEFORE_ARRIVED',
  /** BL отправлен */
  BlSend = 'BL_SEND',
  /** Куплен */
  Buy = 'BUY',
  /** Погружен */
  LoadingToBoat = 'LOADING_TO_BOAT',
  /** Выгружен */
  Unload = 'UNLOAD'
}

export type PbAgreement = {
  __typename?: 'PbAgreement';
  cancelReason?: Maybe<Scalars['String']['output']>;
  contractNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  isAcceptedInOffice?: Maybe<Scalars['Boolean']['output']>;
  isSendToOffice?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  passport?: Maybe<Scalars['String']['output']>;
  passportAddress?: Maybe<DetailedRegistrationAddress>;
  passportIssueUnit?: Maybe<Scalars['String']['output']>;
  passportIssued?: Maybe<Scalars['String']['output']>;
  passportIssuedAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PbAgreementSendedInput = {
  objectId: Scalars['String']['input'];
};

export type PbAgreementSendedOutput = {
  __typename?: 'PbAgreementSendedOutput';
  error?: Maybe<MutationError>;
};

export type Permission = {
  __typename?: 'Permission';
  action?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Permission>>;
};

export type PermissionMenuItem = {
  __typename?: 'PermissionMenuItem';
  permission?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type PickupAppointmentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  plannedAt: Scalars['String']['input'];
};

export type PickupAppointmentOutput = {
  __typename?: 'PickupAppointmentOutput';
  error?: Maybe<MutationError>;
};

export type Plan = {
  __typename?: 'Plan';
  /** К погрузке */
  activeObjectsCount?: Maybe<Scalars['Int']['output']>;
  addToTripTitle?: Maybe<Scalars['String']['output']>;
  /** Контейнеры на судне */
  containers?: Maybe<Array<Container>>;
  countCars?: Maybe<Scalars['Int']['output']>;
  countCarsPrimbroker?: Maybe<Scalars['Int']['output']>;
  countObjects?: Maybe<Scalars['Int']['output']>;
  countOcrObjects?: Maybe<Scalars['Int']['output']>;
  countSaws?: Maybe<Scalars['Int']['output']>;
  /** Всего на судне */
  countTotal?: Maybe<Scalars['Int']['output']>;
  customsAt?: Maybe<Scalars['DateTime']['output']>;
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  driveFrom?: Maybe<Scalars['DateTime']['output']>;
  drivePaused?: Maybe<Scalars['Boolean']['output']>;
  driveTo?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  loadingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  loadingFinishedBy?: Maybe<User>;
  name?: Maybe<Scalars['String']['output']>;
  nextContractNumber?: Maybe<Scalars['Int']['output']>;
  /** Сгон со складов */
  notDeliveredObjectsCount?: Maybe<Scalars['Int']['output']>;
  /** кол-во объектов вне судна */
  notLoadingObjectsCount?: Maybe<Scalars['Int']['output']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  portFrom?: Maybe<Port>;
  simpleTitle?: Maybe<Scalars['String']['output']>;
  stockFrom?: Maybe<Stock>;
  trip?: Maybe<Trip>;
  /** Ждем в порт */
  waitObjectsInPortCount?: Maybe<Scalars['Int']['output']>;
};


export type PlanActiveObjectsCountArgs = {
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PlanCountTotalArgs = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PlanNotDeliveredObjectsCountArgs = {
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PlanWaitObjectsInPortCountArgs = {
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlanCancellationReason = {
  __typename?: 'PlanCancellationReason';
  id?: Maybe<Scalars['Int']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
};

export type PlanOnVesselTypeInput = {
  objectId: Scalars['Int']['input'];
  vesselTypeId: Scalars['Int']['input'];
};

export type PlanOnVesselTypeOutput = {
  __typename?: 'PlanOnVesselTypeOutput';
  error?: Maybe<MutationError>;
};

export type Policy = {
  __typename?: 'Policy';
  baseModule?: Maybe<Module>;
  carrier?: Maybe<CarrierInfo>;
  chats?: Maybe<Array<ChatGroup>>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  digests?: Maybe<Array<Digest>>;
  emailMaintainer?: Maybe<Scalars['String']['output']>;
  expirePassword?: Maybe<Scalars['Int']['output']>;
  expireToken?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isNewRoute?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<LanguageEnum>;
  name?: Maybe<Scalars['String']['output']>;
  oldRole?: Maybe<UserRole>;
  oldRouteUrl?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  permissionGroups?: Maybe<Array<PermissionGroup>>;
  permissions?: Maybe<Array<Permission>>;
  ports?: Maybe<Array<Port>>;
  stocks?: Maybe<Array<Stock>>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  userType?: Maybe<UserType>;
  vessels?: Maybe<Array<Vessel>>;
};

export type Port = {
  __typename?: 'Port';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portData?: Maybe<PortData>;
  stock?: Maybe<Stock>;
};

export type PortCreateInput = {
  countryId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type PortCreateOutput = {
  __typename?: 'PortCreateOutput';
  error?: Maybe<MutationError>;
};

export type PortData = {
  __typename?: 'PortData';
  countryIso?: Maybe<Scalars['String']['output']>;
  freight?: Maybe<Scalars['Float']['output']>;
  freightM3?: Maybe<Scalars['Float']['output']>;
  freightPart?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PortInfo = {
  __typename?: 'PortInfo';
  country?: Maybe<CountryInfo>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PreDeliveryAuction = {
  __typename?: 'PreDeliveryAuction';
  aucName?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  delivery?: Maybe<DeliveryRequest>;
  id?: Maybe<Scalars['Int']['output']>;
  isNumberCut?: Maybe<Scalars['Boolean']['output']>;
  numberCut?: Maybe<Scalars['String']['output']>;
  objects?: Maybe<Array<PreDeliveryObject>>;
  port?: Maybe<Port>;
  preDeliveryRequest?: Maybe<PreDeliveryRequest>;
  stock?: Maybe<Stock>;
};

export type PreDeliveryObject = {
  __typename?: 'PreDeliveryObject';
  delivery?: Maybe<DeliveryRequest>;
  executeAfterPaymentDate?: Maybe<Scalars['Boolean']['output']>;
  frameNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDrivable?: Maybe<Scalars['Boolean']['output']>;
  lotId?: Maybe<Scalars['String']['output']>;
  maker?: Maybe<Scalars['String']['output']>;
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  preDeliveryAuction?: Maybe<PreDeliveryAuction>;
  price?: Maybe<Price>;
  reasonReject?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DeliveryObjectStatusEnum>;
};

export type PreDeliveryRequest = {
  __typename?: 'PreDeliveryRequest';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  navisInternalId?: Maybe<Scalars['String']['output']>;
  preDeliveryRequest?: Maybe<Array<PreDeliveryAuction>>;
};

export type PreSignedPostCreateInput = {
  bucketType?: InputMaybe<BucketTypeEnum>;
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  increaseUploadSize?: InputMaybe<Scalars['Boolean']['input']>;
  logicFileType?: InputMaybe<LogicFileTypeEnum>;
};

export type PreSignedPostCreateOutput = {
  __typename?: 'PreSignedPostCreateOutput';
  error?: Maybe<MutationError>;
  fields?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type ProtocolField = {
  __typename?: 'ProtocolField';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldName?: Maybe<DocumentFieldName>;
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PutUpForSaleCreateInput = {
  objectId: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
};

export type PutUpForSaleCreateOutput = {
  __typename?: 'PutUpForSaleCreateOutput';
  error?: Maybe<MutationError>;
};

export type PutUpForSaleSoldOutInput = {
  objectId: Scalars['Int']['input'];
};

export type PutUpForSaleSoldOutOutput = {
  __typename?: 'PutUpForSaleSoldOutOutput';
  error?: Maybe<MutationError>;
};

export type PutUpForSaleUpdateInput = {
  objectId: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
};

export type PutUpForSaleUpdateOutput = {
  __typename?: 'PutUpForSaleUpdateOutput';
  error?: Maybe<MutationError>;
};

export type PutUpForSaleWithdrawInput = {
  objectId: Scalars['Int']['input'];
};

export type PutUpForSaleWithdrawOutput = {
  __typename?: 'PutUpForSaleWithdrawOutput';
  error?: Maybe<MutationError>;
};

export type Query = {
  __typename?: 'Query';
  activePlans?: Maybe<Array<Plan>>;
  activeTrips?: Maybe<Array<Trip>>;
  additionalTypes?: Maybe<Array<TypeOfAdditional>>;
  addresseesGroupToChat?: Maybe<Array<ChatGroup>>;
  addresseesUsersToChat?: Maybe<AddresseesUsersToChatsPaginatedList>;
  aggregateTypes?: Maybe<Array<Aggregate>>;
  akebonoPackages?: Maybe<PackagesPaginatedList>;
  ambassadors?: Maybe<AmbassadorsPaginatedList>;
  attachmentsTemplate?: Maybe<Array<AttachmentsTemplate>>;
  /** Получить все отзывы аукциона */
  auctionFeedbacks?: Maybe<AuctionFeedbacksPaginatedList>;
  auctions?: Maybe<Array<Stock>>;
  autoResolveJobsQuery?: Maybe<Scalars['Boolean']['output']>;
  branchOffices?: Maybe<Array<Scalars['String']['output']>>;
  brokerExcelTemplates?: Maybe<Array<BrokerExcelTemplate>>;
  brokers?: Maybe<BrokersPaginatedList>;
  carTypes?: Maybe<Array<TypeOfExport>>;
  carriers?: Maybe<Array<Carrier>>;
  chassisTypes?: Maybe<Array<TypeOfChassis>>;
  chatForOthers?: Maybe<ChatForOthers>;
  chatMessages?: Maybe<ChatMessagessPaginatedList>;
  chatTags?: Maybe<Array<Tag>>;
  chatsForOthers?: Maybe<ChatsForOtherssPaginatedList>;
  checkAgentDebts?: Maybe<Scalars['Boolean']['output']>;
  clientContacts?: Maybe<Array<ClientContact>>;
  clientObject?: Maybe<ObjectOfServices>;
  /** Вложения, посылки */
  clientObjectAttachments?: Maybe<ClientObjectAttachmentsPaginatedList>;
  clientObjectByVin?: Maybe<ObjectOfServices>;
  /** Список рейсов с активными объектами или долгами */
  clientObjectDashboard?: Maybe<Array<ClientObjectsTrip>>;
  clientObjectDebts?: Maybe<OwnerBalance>;
  clientObjectLogs?: Maybe<ClientObjectLogsPaginatedList>;
  clientObjectQueue?: Maybe<ClientObjectQueuesPaginatedList>;
  /** Размер комиссии перепродажи ЗУ */
  clientObjectResaleComission?: Maybe<Price>;
  /** Сделки перепродажи */
  clientObjectResales?: Maybe<ObjectResalesPaginatedList>;
  clientObjectYears?: Maybe<Array<ClientObjectsYear>>;
  clientObjects?: Maybe<ClientObjectsPaginatedList>;
  clientObjectsReportPaymentAkebonoAllow?: Maybe<ClientObjectsReportPaymentAkebonoAllowsPaginatedList>;
  clientRecipients?: Maybe<ClientRecipientsPaginatedList>;
  colors?: Maybe<Array<Color>>;
  /** Данные контейнера */
  container?: Maybe<Container>;
  /** Типов контейнеров */
  containerTypes?: Maybe<Array<Maybe<TypeOfContainer>>>;
  /** Список контейнеров */
  containers?: Maybe<ContainersPaginatedList>;
  countUnreadChatMessages?: Maybe<Scalars['Int']['output']>;
  countries?: Maybe<CountrysPaginatedList>;
  currencies?: Maybe<Array<Currency>>;
  currentPlan?: Maybe<SbktsPlan>;
  currentUser?: Maybe<User>;
  currentUserNotifyMessages?: Maybe<Array<CurrentUserNotifyMessage>>;
  customPaymentTypes?: Maybe<Array<CustomPayment>>;
  customTypes?: Maybe<Array<TypeOfCustom>>;
  deliveryWheelPrice?: Maybe<Scalars['Int']['output']>;
  depositOpenAmount?: Maybe<Array<Scalars['Int']['output']>>;
  diffEntities?: Maybe<Array<LogEntity>>;
  diffEntityTypes?: Maybe<Array<Scalars['String']['output']>>;
  /** Данные по донату на буханку */
  donateBuhankaStatus?: Maybe<DonateBuhanka>;
  drivers?: Maybe<Array<User>>;
  ecarJpBotSubscribeUrl: Scalars['String']['output'];
  engineTypes?: Maybe<Array<TypeOfEngine>>;
  engines?: Maybe<Array<TypeOfEngine>>;
  exchangeJobActions?: Maybe<Array<Scalars['String']['output']>>;
  exchangeJobTransports?: Maybe<Array<Scalars['String']['output']>>;
  exchangeJobs?: Maybe<ExchangeJobsPaginatedList>;
  /** Список сторонних рейсов */
  exportTrips?: Maybe<ExportTripsPaginatedList>;
  fastObjectsByTrip?: Maybe<Array<FastObjectsByTrip>>;
  /** Получить все отзывы */
  feedbacks?: Maybe<FeedbackssPaginatedList>;
  financeReport?: Maybe<Array<FinanceReport>>;
  flatTypes?: Maybe<Array<TypeOfFlat>>;
  /** Формальник по id */
  formalOwner?: Maybe<FormalOwner>;
  formalOwners?: Maybe<FormalOwnersPaginatedList>;
  /** Список форватеров */
  forwarders?: Maybe<Array<Maybe<Forwarder>>>;
  /** Список типов фрахта контейнера */
  freightTypes?: Maybe<Array<Maybe<TypeOfFreight>>>;
  getEPTSLauncherUrls?: Maybe<Array<Scalars['String']['output']>>;
  /** Статистика + объекты ЗУ с грузополучателем */
  greenCornerObjects?: Maybe<GreenCornerObjects>;
  greenCornerStoragePeriod?: Maybe<GreenCornerStoragePeriod>;
  houseTypes?: Maybe<Array<TypeOfHouse>>;
  housingTypes?: Maybe<Array<TypeOfHousing>>;
  invoiceCustoms?: Maybe<InvoiceCustomerInfosPaginatedList>;
  invoiceLogs?: Maybe<InvoiceLogsPaginatedList>;
  invoiceTypes?: Maybe<Array<InvoiceType>>;
  /** Запросы на изменение инвойса */
  invoiceUpdateRequests?: Maybe<Array<InvoiceUpdateRequest>>;
  /** Список и поиск инвойсов */
  invoicesSearch?: Maybe<InvoicesSearchsPaginatedList>;
  /** Состояние автовыставления лотов */
  kaitaiAutoResubmitState?: Maybe<KaitaiAutoResubmitStateEnum>;
  /** Состояние очереди Kaitai емэйлов */
  kaitaiQueueStatus?: Maybe<QueueStatus>;
  laboratories?: Maybe<Array<Laboratory>>;
  limitsPlan?: Maybe<LimitsPlan>;
  localityTypes?: Maybe<Array<TypeOfLocality>>;
  logAgentEvents?: Maybe<LogAgentEventsPaginatedList>;
  logObjectEvents?: Maybe<Array<ObjectLogEvent>>;
  logTypes?: Maybe<Array<Scalars['String']['output']>>;
  logs?: Maybe<LogsPaginatedList>;
  logsUserRequestTypes?: Maybe<Array<Scalars['String']['output']>>;
  logsUserRequests?: Maybe<LogsUserRequestsPaginatedList>;
  /** Получить список лотерейных билетов. */
  lotteryTickets?: Maybe<Array<Maybe<LotteryTicket>>>;
  makerColors?: Maybe<Array<MakerColor>>;
  makers?: Maybe<Array<Maker>>;
  marks?: Maybe<Array<Mark>>;
  masterAccountsClients?: Maybe<MasterAccountsClientsPaginatedList>;
  mediaStatistics?: Maybe<MediaStatistics>;
  mediaTypes?: Maybe<Array<MediaTypeEnum>>;
  models?: Maybe<Array<Model>>;
  /** Курс по банку MUFG JPT/CNY */
  mufgJpyToCnyExhangeRate?: Maybe<Scalars['Float']['output']>;
  /** Курс по банку MUFG JPT/EUR */
  mufgJpyToEurExhangeRate?: Maybe<Scalars['Float']['output']>;
  /** Курс по банку MUFG JPT/USD */
  mufgJpyToUsdExhangeRate?: Maybe<Scalars['Float']['output']>;
  navisSchedule?: Maybe<TripSchedule>;
  news?: Maybe<NewsPaginatedList>;
  newsView?: Maybe<News>;
  nomenclatures?: Maybe<Array<Nomenclature>>;
  notificationLogs?: Maybe<NotificationLogsPaginatedList>;
  objectKeyTypes?: Maybe<Array<TypeOfObjectKey>>;
  operationalObjects?: Maybe<OperationalObjectsPaginatedList>;
  /** Список организаций */
  organizations?: Maybe<Array<Organization>>;
  owner?: Maybe<Owner>;
  owners?: Maybe<OwnersPaginatedList>;
  /** Список палет */
  pallet?: Maybe<PalletsPaginatedList>;
  parkingPrice?: Maybe<Scalars['Int']['output']>;
  partTemplates?: Maybe<Array<PartTemplate>>;
  paymentsHistory?: Maybe<Array<PaymentHistory>>;
  plan?: Maybe<Plan>;
  planGenerate?: Maybe<SbktsPlan>;
  plans?: Maybe<SbktsPlansPaginatedList>;
  ports?: Maybe<Array<PortInfo>>;
  /** Стоимость услуги Подготовка пакета документов для жителей не ДВФО */
  prepareDocumentsPrice?: Maybe<Scalars['Int']['output']>;
  reasons?: Maybe<Array<Reason>>;
  sawTypes?: Maybe<Array<TypeOfSaw>>;
  sbktsBrokerStatistic?: Maybe<SbktsStatistic>;
  sbktsPlanStatistic?: Maybe<SbktsPlanStatistic>;
  sbktsRequest?: Maybe<SbktsRequest>;
  sbktsRequests?: Maybe<SbktsRequestsPaginatedList>;
  /** Список автомобилей на утилизацию */
  scrapObjects?: Maybe<DisposalsPaginatedList>;
  /** Список описаний шаблонов запчастей */
  scrapTypeDescriptions?: Maybe<ScrapTypeDescriptionsPaginatedList>;
  scrapTypes?: Maybe<Array<Scrap>>;
  /** Список морских доставщиков */
  seaCarriers?: Maybe<SeaCarriersPaginatedList>;
  searchObjects?: Maybe<SearchObjectsPaginatedList>;
  sectors?: Maybe<Array<Sector>>;
  senderAgentsSearch?: Maybe<OwnerAgentsPaginatedList>;
  senders?: Maybe<SendersPaginatedList>;
  /** Наличие заявки на фото с лота */
  serviceRequestAuctionLotByUser?: Maybe<ServiceRequest>;
  serviceRequestObjects?: Maybe<ServiceRequestObjectsPaginatedList>;
  /** Стоимость услуги (инспекций и т.д) */
  serviceRequestPrice?: Maybe<Scalars['Int']['output']>;
  serviceRequestTypeParams?: Maybe<Array<ServiceRequestTypeParam>>;
  serviceRequestTypes?: Maybe<Array<TypeOfServiceRequest>>;
  /** Список заявок на доп услуги */
  serviceRequests?: Maybe<ServiceRequestsPaginatedList>;
  /** Количество заявок на инспекцию */
  serviceRequestsCount?: Maybe<ServiceRequestCount>;
  /** Получить статистику на постановку в план. */
  statisticCarInPlan: CarInPlantStatistic;
  statuses?: Maybe<Array<Status>>;
  /** Страны в которых есть склады */
  stockCountries?: Maybe<StockCountiesPaginatedList>;
  /** Список складов */
  stocks?: Maybe<StocksPaginatedList>;
  ticket?: Maybe<Ticket>;
  ticketDecisionsQuery?: Maybe<TicketDecisionsPaginatedList>;
  ticketStatuses?: Maybe<Array<TicketStatus>>;
  /** Получить дополнительне отметки при работе с претензиями. */
  ticketSubTypes?: Maybe<Array<TypeOfTicketSub>>;
  ticketTypes?: Maybe<Array<TypeOfTicket>>;
  tickets?: Maybe<TicketsPaginatedList>;
  ticketsReasons?: Maybe<TicketsReasonsPaginatedList>;
  ticketsReportDownload?: Maybe<Scalars['String']['output']>;
  ticketsStatistic?: Maybe<TicketStatistic>;
  timeZones?: Maybe<Array<TimeZone>>;
  totalCustomsClearanceTime?: Maybe<TotalCustomsClearanceTime>;
  trip?: Maybe<Trip>;
  trips?: Maybe<TripsPaginatedList>;
  tripsCalendar?: Maybe<TripsCalendar>;
  undefinedPayments?: Maybe<UndefinedPaymentsPaginatedList>;
  /** Получить логи по объекту */
  unionLog?: Maybe<ClientUnionLogsPaginatedList>;
  userMenu?: Maybe<Array<PermissionMenuItem>>;
  users?: Maybe<UsersPaginatedList>;
  vesselTypes?: Maybe<Array<VesselTypeInfo>>;
  /** Список кораблей */
  vessels?: Maybe<Array<Vessel>>;
  watchCommentTemplate?: Maybe<Array<WatchCommentTemplate>>;
  watchType?: Maybe<Array<TypeOfWatchCar>>;
  /** Список колес */
  wheel?: Maybe<WheelsPaginatedList>;
  /** Список колес для менеджера */
  wheelAll?: Maybe<WheelAllsPaginatedList>;
  /** Значения фильтра для списка колес */
  wheelFilter?: Maybe<WheelFilterValue>;
  /** Статистика колес для менеджера */
  wheelStatistic?: Maybe<WheelStatistic>;
  withoutMediaObjects?: Maybe<WithoutMediaObjectsPaginatedList>;
  yahooOwners?: Maybe<YahooOwnersPaginatedList>;
};


export type QueryAddresseesGroupToChatArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAddresseesUsersToChatArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAkebonoPackagesArgs = {
  filter?: InputMaybe<PackagesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAmbassadorsArgs = {
  byAgent?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAuctionFeedbacksArgs = {
  auctionId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAuctionsArgs = {
  partName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBrokerExcelTemplatesArgs = {
  broker?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBrokersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  forSbkts?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partName?: InputMaybe<Scalars['String']['input']>;
  withoutPb?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCarTypesArgs = {
  forWorldWide?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryChatForOthersArgs = {
  chatId: Scalars['Int']['input'];
};


export type QueryChatMessagesArgs = {
  chatId: Scalars['Int']['input'];
  filter?: InputMaybe<ChatMessagessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChatsForOthersArgs = {
  filter?: InputMaybe<ChatsForOtherssFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientObjectArgs = {
  objectId: Scalars['Int']['input'];
};


export type QueryClientObjectAttachmentsArgs = {
  filter?: InputMaybe<ClientObjectAttachmentsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientObjectByVinArgs = {
  vin: Scalars['String']['input'];
};


export type QueryClientObjectDashboardArgs = {
  filter?: InputMaybe<ClientObjectDashboardFilter>;
};


export type QueryClientObjectDebtsArgs = {
  agentId?: InputMaybe<Scalars['Int']['input']>;
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
  objectId: Scalars['Int']['input'];
};


export type QueryClientObjectLogsArgs = {
  filter?: InputMaybe<ClientObjectLogsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientObjectQueueArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientObjectResalesArgs = {
  filter?: InputMaybe<ObjectResalesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientObjectYearsArgs = {
  agentId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientObjectsArgs = {
  filter?: InputMaybe<ClientObjectsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ClientObjectsSort>;
};


export type QueryClientObjectsReportPaymentAkebonoAllowArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partVin: Scalars['String']['input'];
};


export type QueryClientRecipientsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryContainerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryContainersArgs = {
  filter?: InputMaybe<ContainersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountUnreadChatMessagesArgs = {
  onlyClients?: InputMaybe<Scalars['Boolean']['input']>;
  onlyTickets?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCountriesArgs = {
  filter?: InputMaybe<CountrysFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCurrenciesArgs = {
  filter?: InputMaybe<CurrencyFilter>;
};


export type QueryCurrentPlanArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrentUserNotifyMessagesArgs = {
  onlyEcarjpBot?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDiffEntitiesArgs = {
  filter?: InputMaybe<DiffEntityFilter>;
};


export type QueryExchangeJobsArgs = {
  filter?: InputMaybe<ExchangeJobsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExportTripsArgs = {
  filter?: InputMaybe<ExportTripsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ExportTripsSort>;
};


export type QueryFeedbacksArgs = {
  filter?: InputMaybe<FeedbackssFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFinanceReportArgs = {
  agentId?: InputMaybe<Scalars['Int']['input']>;
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
};


export type QueryFormalOwnerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFormalOwnersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partName: Scalars['String']['input'];
};


export type QueryInvoiceCustomsArgs = {
  ownerId: Scalars['Int']['input'];
  partName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvoiceLogsArgs = {
  objectId: Scalars['Int']['input'];
};


export type QueryInvoiceUpdateRequestsArgs = {
  invoiceId: Scalars['Int']['input'];
};


export type QueryInvoicesSearchArgs = {
  filter?: InputMaybe<InvoicesSearchsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLogAgentEventsArgs = {
  filter?: InputMaybe<LogAgentEventsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLogObjectEventsArgs = {
  objectId: Scalars['Int']['input'];
};


export type QueryLogsArgs = {
  filter?: InputMaybe<LogsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLogsUserRequestTypesArgs = {
  user?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLogsUserRequestsArgs = {
  filter?: InputMaybe<LogsUserRequestsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMakerColorsArgs = {
  makerId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMarksArgs = {
  makerId: Scalars['Int']['input'];
};


export type QueryMasterAccountsClientsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMediaStatisticsArgs = {
  mediaType: MediaTypeEnum;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryModelsArgs = {
  markId: Scalars['Int']['input'];
  vin?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNavisScheduleArgs = {
  comingSoon?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNewsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNewsViewArgs = {
  id: Scalars['Int']['input'];
};


export type QueryNotificationLogsArgs = {
  filter?: InputMaybe<NotificationLogsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOperationalObjectsArgs = {
  filter?: InputMaybe<OperationalObjectsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsArgs = {
  forTickets?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOwnerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOwnersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  fullSearch?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partName: Scalars['String']['input'];
};


export type QueryPalletArgs = {
  filter?: InputMaybe<PalletsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentsHistoryArgs = {
  agentId?: InputMaybe<Scalars['Int']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlanArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPlansArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySawTypesArgs = {
  chassisTypeId?: InputMaybe<Scalars['Int']['input']>;
  objectTypeId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySbktsBrokerStatisticArgs = {
  date: Scalars['String']['input'];
};


export type QuerySbktsPlanStatisticArgs = {
  brokers?: InputMaybe<Array<Scalars['Int']['input']>>;
  date: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
};


export type QuerySbktsRequestArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySbktsRequestsArgs = {
  filter?: InputMaybe<SbktsRequestsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SbktsRequestsSort>;
};


export type QueryScrapObjectsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  makerId?: InputMaybe<Scalars['Int']['input']>;
  markId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};


export type QueryScrapTypeDescriptionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScrapTypesArgs = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySeaCarriersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchObjectsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QuerySectorsArgs = {
  stockId: Scalars['Int']['input'];
};


export type QuerySenderAgentsSearchArgs = {
  filter?: InputMaybe<OwnerAgentsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySendersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryServiceRequestAuctionLotByUserArgs = {
  lotLink: Scalars['String']['input'];
};


export type QueryServiceRequestObjectsArgs = {
  filter?: InputMaybe<ServiceRequestObjectsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ServiceRequestObjectsSort>;
};


export type QueryServiceRequestPriceArgs = {
  type: VideoWatchTypeEnum;
};


export type QueryServiceRequestTypeParamsArgs = {
  makerId?: InputMaybe<Scalars['Int']['input']>;
  markId?: InputMaybe<Scalars['Int']['input']>;
  requestTypeId: Scalars['Int']['input'];
};


export type QueryServiceRequestsArgs = {
  filter?: InputMaybe<ServiceRequestsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ServiceRequestsSort>;
};


export type QueryStatisticCarInPlanArgs = {
  byAuctionId?: InputMaybe<Scalars['Int']['input']>;
  byDate?: InputMaybe<Scalars['DateTime']['input']>;
  dateFrom: Scalars['DateTime']['input'];
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  statisticBy: StatisticTypeEnum;
};


export type QueryStatusesArgs = {
  type: TypeOfStatusEnum;
};


export type QueryStockCountriesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStocksArgs = {
  filter?: InputMaybe<StocksFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTicketArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTicketDecisionsQueryArgs = {
  filter?: InputMaybe<TicketDecisionsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTicketsArgs = {
  filter?: InputMaybe<TicketsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TicketsSort>;
};


export type QueryTicketsReasonsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTicketsReportDownloadArgs = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTripArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTripsArgs = {
  filter?: InputMaybe<TripsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TripsSort>;
};


export type QueryUndefinedPaymentsArgs = {
  filter?: InputMaybe<UndefinedPaymentsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<UndefinedPaymentsSort>;
};


export type QueryUnionLogArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserMenuArgs = {
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVesselTypesArgs = {
  forWorldWide?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryVesselsArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  forTickets?: InputMaybe<Scalars['Boolean']['input']>;
  isExport?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWheelArgs = {
  filter?: InputMaybe<WheelsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWheelAllArgs = {
  filter?: InputMaybe<WheelAllsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWithoutMediaObjectsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  mediaType: MediaTypeEnum;
  offset?: InputMaybe<Scalars['Int']['input']>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryYahooOwnersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  idList?: InputMaybe<Array<Scalars['Int']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueueStatus = {
  __typename?: 'QueueStatus';
  currentMessageCount?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
};

export type Reason = {
  __typename?: 'Reason';
  id?: Maybe<Scalars['Int']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
};

export type ReceiptOfPayment = {
  __typename?: 'ReceiptOfPayment';
  /** Сумма платежа */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Валюта платежа */
  currency?: Maybe<Currency>;
  /** Дата оплаты */
  dateOfPayment?: Maybe<Scalars['DateTime']['output']>;
  /** Файл чека */
  file?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Номер инвойса */
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  /** Комментарий */
  message?: Maybe<Scalars['String']['output']>;
  /** Имя плательщика */
  senderName?: Maybe<Scalars['String']['output']>;
};

export type Recipient = {
  __typename?: 'Recipient';
  customPaymentType?: Maybe<CustomPayment>;
  declarant?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  /** Грузополучатель находится в Дальневосточном федеральном округе */
  isDvfo?: Maybe<Scalars['Boolean']['output']>;
  isLegalEntity?: Maybe<Scalars['Boolean']['output']>;
  isNotProcessed?: Maybe<Scalars['Boolean']['output']>;
  isRejected?: Maybe<Scalars['Boolean']['output']>;
  /** Запросить квитанцию для предварительного пополнения лицевого счета на ФТС */
  isRequestReceiptFcs?: Maybe<Scalars['Boolean']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  nameIgnoreType?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
};

export type RegistrationAddress = {
  __typename?: 'RegistrationAddress';
  country?: Maybe<Country>;
  detailedRegistrationAddress?: Maybe<DetailedRegistrationAddress>;
  fullAddressNameEng?: Maybe<Scalars['String']['output']>;
  fullAddressNameRu?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  id?: Maybe<Scalars['Int']['output']>;
  lastAt?: Maybe<Scalars['DateTime']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  systemCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ReminderRemoveInput = {
  id: Scalars['Int']['input'];
};

export type ReminderRemoveOutput = {
  __typename?: 'ReminderRemoveOutput';
  error?: Maybe<MutationError>;
};

export type RemoveFromStockInput = {
  objectId: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type RemoveFromStockOutput = {
  __typename?: 'RemoveFromStockOutput';
  error?: Maybe<MutationError>;
};

export type RemoveObjectFromPlanInput = {
  objectId: Scalars['Int']['input'];
  reasonId: Scalars['Int']['input'];
};

export type RemoveObjectFromPlanOutput = {
  __typename?: 'RemoveObjectFromPlanOutput';
  error?: Maybe<MutationError>;
};

export type RenewExportCertificateInput = {
  exportCertificateId: Scalars['Int']['input'];
  scheduledDate: Scalars['String']['input'];
};

export type RenewExportCertificateOutput = {
  __typename?: 'RenewExportCertificateOutput';
  error?: Maybe<MutationError>;
};

export type ReplaceDoubleInput = {
  doubleId: Scalars['Int']['input'];
  originalId: Scalars['Int']['input'];
};

export type ReplaceDoubleOutput = {
  __typename?: 'ReplaceDoubleOutput';
  error?: Maybe<MutationError>;
};

export type ReplaceToStockInput = {
  objectId: Scalars['Int']['input'];
  sectorId?: InputMaybe<Scalars['Int']['input']>;
  stockId: Scalars['Int']['input'];
};

export type ReplaceToStockOutput = {
  __typename?: 'ReplaceToStockOutput';
  error?: Maybe<MutationError>;
};

export type RequestPhotoFromAuctionInput = {
  comment: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  lotData: Scalars['String']['input'];
  lotLink: Scalars['String']['input'];
  type: VideoWatchTypeEnum;
};

export type RequestPhotoFromAuctionOutput = {
  __typename?: 'RequestPhotoFromAuctionOutput';
  error?: Maybe<MutationError>;
};

export type RequestPrimbrokerInvoiceInput = {
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  objectId?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestPrimbrokerInvoiceOutput = {
  __typename?: 'RequestPrimbrokerInvoiceOutput';
  error?: Maybe<MutationError>;
};

export type RequestSbktsAgentInput = {
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  issuedAtPassport: Scalars['String']['input'];
  issuedUnit: Scalars['String']['input'];
  issuedUnitName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  passportDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  passportNumber: Scalars['String']['input'];
  passportSeries: Scalars['String']['input'];
  snils?: InputMaybe<Scalars['String']['input']>;
};

export type RequestSbktsAgentLegalInput = {
  bank: Scalars['String']['input'];
  bik: Scalars['String']['input'];
  kpp: Scalars['String']['input'];
  ks: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ogrn: Scalars['String']['input'];
  rs: Scalars['String']['input'];
};

export type RequestSbktsCreateInput = {
  agent?: InputMaybe<RequestSbktsAgentInput>;
  agentLegal?: InputMaybe<RequestSbktsAgentLegalInput>;
  ambassador: Scalars['Int']['input'];
  customer: Scalars['Int']['input'];
  laboratory: Scalars['Int']['input'];
  object: RequestSbktsObjectInput;
  objectId: Scalars['Int']['input'];
};

export type RequestSbktsCreateOutput = {
  __typename?: 'RequestSbktsCreateOutput';
  error?: Maybe<MutationError>;
};

export type RequestSbktsExcelAgentInput = {
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  innDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  issuedAtPassport?: InputMaybe<Scalars['String']['input']>;
  issuedUnit?: InputMaybe<Scalars['String']['input']>;
  issuedUnitName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ogrnDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  passportDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  passportSeries?: InputMaybe<Scalars['String']['input']>;
  residentFEFD?: InputMaybe<Scalars['Boolean']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
};

export type RequestSbktsExcelAgentLegalInput = {
  bank?: InputMaybe<Scalars['String']['input']>;
  bik?: InputMaybe<Scalars['String']['input']>;
  kpp?: InputMaybe<Scalars['String']['input']>;
  ks?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ogrn?: InputMaybe<Scalars['String']['input']>;
  rs?: InputMaybe<Scalars['String']['input']>;
};

export type RequestSbktsExcelFixField = {
  __typename?: 'RequestSbktsExcelFixField';
  errorMessage?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
};

export type RequestSbktsExcelInput = {
  color?: InputMaybe<Scalars['Int']['input']>;
  consignment?: InputMaybe<Array<Scalars['String']['input']>>;
  dateOfManufacture?: InputMaybe<Scalars['String']['input']>;
  driveType?: InputMaybe<Scalars['String']['input']>;
  enableGlonass?: InputMaybe<Scalars['Boolean']['input']>;
  engineModel?: InputMaybe<Scalars['String']['input']>;
  engineNumber?: InputMaybe<Scalars['String']['input']>;
  enginePower?: InputMaybe<Scalars['Float']['input']>;
  engineType?: InputMaybe<Scalars['Int']['input']>;
  engineVolume?: InputMaybe<Scalars['Float']['input']>;
  glonassDocument?: InputMaybe<Array<Scalars['String']['input']>>;
  glonassICCID?: InputMaybe<Scalars['String']['input']>;
  glonassModel?: InputMaybe<Scalars['String']['input']>;
  markName: Scalars['String']['input'];
  maxWeight?: InputMaybe<Scalars['Int']['input']>;
  modelName: Scalars['String']['input'];
  needEngineWatch?: InputMaybe<Scalars['Boolean']['input']>;
  ownWeight?: InputMaybe<Scalars['Int']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  tpo?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RequestSbktsFromExcelCreateInput = {
  agent?: InputMaybe<RequestSbktsExcelAgentInput>;
  agentLegal?: InputMaybe<RequestSbktsExcelAgentLegalInput>;
  ambassador: Scalars['Int']['input'];
  customer: Scalars['Int']['input'];
  laboratory: Scalars['Int']['input'];
  object?: InputMaybe<RequestSbktsExcelInput>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestSbktsFromExcelCreateOutput = {
  __typename?: 'RequestSbktsFromExcelCreateOutput';
  agentUpdateError?: Maybe<Scalars['String']['output']>;
  error?: Maybe<MutationError>;
  fields?: Maybe<Array<RequestSbktsExcelFixField>>;
  objectUpdateError?: Maybe<Scalars['String']['output']>;
  sbktsError?: Maybe<Scalars['String']['output']>;
};

export type RequestSbktsObjectInput = {
  color: Scalars['Int']['input'];
  consignment?: InputMaybe<Array<Scalars['String']['input']>>;
  dateOfManufacture: Scalars['String']['input'];
  driveType?: InputMaybe<Scalars['String']['input']>;
  engineModel?: InputMaybe<Scalars['String']['input']>;
  engineNumber: Scalars['String']['input'];
  enginePower: Scalars['Float']['input'];
  engineVolume?: InputMaybe<Scalars['Float']['input']>;
  glonassDocument?: InputMaybe<Array<Scalars['String']['input']>>;
  glonassICCID?: InputMaybe<Scalars['String']['input']>;
  glonassModel?: InputMaybe<Scalars['String']['input']>;
  markName: Scalars['String']['input'];
  maxWeight: Scalars['Int']['input'];
  modelName: Scalars['String']['input'];
  needEngineWatch?: InputMaybe<Scalars['Boolean']['input']>;
  ownWeight: Scalars['Int']['input'];
  seats?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestToIssueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  dateIssue: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type RequestToIssueObject = {
  __typename?: 'RequestToIssueObject';
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  issuedAt?: Maybe<Scalars['DateTime']['output']>;
  plannedAt?: Maybe<Scalars['DateTime']['output']>;
  sender?: Maybe<Sender>;
  storageDaysCount?: Maybe<Scalars['Int']['output']>;
};

export type RequestToIssueOutput = {
  __typename?: 'RequestToIssueOutput';
  error?: Maybe<MutationError>;
};

export type RequestUnHoldInput = {
  requestId: Scalars['Int']['input'];
};

export type RequestUnHoldOutput = {
  __typename?: 'RequestUnHoldOutput';
  error?: Maybe<MutationError>;
};

export type RestoreObjectInput = {
  objectId: Scalars['Int']['input'];
};

export type RestoreObjectOutput = {
  __typename?: 'RestoreObjectOutput';
  error?: Maybe<MutationError>;
};

export enum RoleEnum {
  AkebonoBukhgalter = 'AkebonoBukhgalter',
  AkebonoDokumentoved = 'AkebonoDokumentoved',
  AkebonoMenedzher = 'AkebonoMenedzher',
  AkebonoOtpravkaPoMiru = 'AkebonoOtpravkaPoMiru',
  AkebonoRazbor = 'AkebonoRazbor',
  AkebonoRossiya = 'AkebonoRossiya',
  AkebonoShipping = 'AkebonoShipping',
  AkebonoSklad = 'AkebonoSklad',
  AkebonoSkladMedia = 'AkebonoSkladMedia',
  AkebonoStarshijRazborshhik = 'AkebonoStarshijRazborshhik',
  AkebonoYaponskijOfis = 'AkebonoYaponskijOfis',
  Auktsionist = 'Auktsionist',
  BlNaEhksportnjkhRejsakh = 'BLNaEhksportnjkhRejsakh',
  Broker = 'Broker',
  BukhgalterPrimbroker = 'BukhgalterPrimbroker',
  Bukhgaltera = 'Bukhgaltera',
  ChatGruppaPrimbroker = 'ChatGruppaPrimbroker',
  DannjeAuktsionaVKartochkeObiekta = 'DannjeAuktsionaVKartochkeObiekta',
  Deklarant = 'Deklarant',
  Direktor = 'Direktor',
  DobavlenieKommentariyaKontragentu = 'DobavlenieKommentariyaKontragentu',
  DosmotrPrimbroker = 'DosmotrPrimbroker',
  DostupKDokumentamObiekta = 'DostupKDokumentamObiekta',
  DostupKProsmotryuPretenzij = 'DostupKProsmotryuPretenzij',
  DostupOrganizatsiiVlk = 'DostupOrganizatsiiVLK',
  EhkipazhSudna = 'EhkipazhSudna',
  EhksportnjeSertifikatjWorldWide = 'EhksportnjeSertifikatjWorldWide',
  GeneratsiyaOtchetaExcelPoZayavkamNaDostavku = 'GeneratsiyaOtchetaExcelPoZayavkamNaDostavku',
  IzmenenieOtmetkiKontragenta = 'IzmenenieOtmetkiKontragenta',
  IzmenenieSpiskaOzhidaniyaPogruzki = 'IzmenenieSpiskaOzhidaniyaPogruzki',
  IzmenenieYazjkaKontragenta = 'IzmenenieYazjkaKontragenta',
  Klient = 'Klient',
  Logist = 'Logist',
  LogistRf = 'LogistRF',
  LogistVoditelej = 'LogistVoditelej',
  MenedzherSvKh = 'MenedzherSVKh',
  MenedzherZelenjjUgol20 = 'MenedzherZelenjjUgol20',
  MladshijSotrudnikOtdelaPogruzki = 'MladshijSotrudnikOtdelaPogruzki',
  Nablyudatelj = 'Nablyudatelj',
  NapominaniyaBl = 'NapominaniyaBL',
  NavisAnalitik = 'NavisAnalitik',
  NavisDosmotr = 'NavisDosmotr',
  NavisDosmotrRossiya = 'NavisDosmotrRossiya',
  NavisFukui = 'NavisFukui',
  NavisMini = 'NavisMini',
  NavisOtaru = 'NavisOtaru',
  NavisPoluchateli = 'NavisPoluchateli',
  NavisShipping = 'NavisShipping',
  NavisShippingJsc = 'NavisShippingJSC',
  NavisSklad = 'NavisSklad',
  NovjjDogovorPb = 'NovjjDogovorPB',
  OtdelPogruzkiNavisShipping = 'OtdelPogruzkiNavisShipping',
  OtmetkaInvojsaOplachennjm = 'OtmetkaInvojsaOplachennjm',
  Otpravitelj = 'Otpravitelj',
  OtpravkaTelegramUvedomlenij = 'OtpravkaTelegramUvedomlenij',
  OuterAgentOtaru = 'OuterAgentOtaru',
  OuterCarrier = 'OuterCarrier',
  OuterOtaru = 'OuterOtaru',
  PartnerPerevozchik = 'PartnerPerevozchik',
  PartnerSbkts = 'PartnerSBKTS',
  PeremeshhenieObiektaMezhduSkladami = 'PeremeshhenieObiektaMezhduSkladami',
  PeremeshhenieObiektaMezhduStranami = 'PeremeshhenieObiektaMezhduStranami',
  PeremeshhenieZayavokDostavkiMezhduGruppami = 'PeremeshhenieZayavokDostavkiMezhduGruppami',
  PokazKlientovAkebono = 'PokazKlientovAkebono',
  PolnjjDostupKMenyuDejstvijQr = 'PolnjjDostupKMenyuDejstvijQR',
  PolnjjPoiskKontragenta = 'PolnjjPoiskKontragenta',
  PolnjjPoiskObiektov = 'PolnjjPoiskObiektov',
  PolnjjProsmotrKartochkiObiekta = 'PolnjjProsmotrKartochkiObiekta',
  Priemnaya = 'Priemnaya',
  ProsmotrDejstvijPoljzovatelya = 'ProsmotrDejstvijPoljzovatelya',
  ProsmotrIIzmenenieBl = 'ProsmotrIIzmenenieBL',
  ProsmotrInvojsov = 'ProsmotrInvojsov',
  ProsmotrKartochkiDostavki = 'ProsmotrKartochkiDostavki',
  ProsmotrKartochkiKontragenta = 'ProsmotrKartochkiKontragenta',
  ProsmotrKtoOnlajn = 'ProsmotrKtoOnlajn',
  ProsmotrLkVjbrannjkhKlientov = 'ProsmotrLKVjbrannjkhKlientov',
  ProsmotrLichnjkhKabinetov = 'ProsmotrLichnjkhKabinetov',
  ProsmotrOtchetaAkebono = 'ProsmotrOtchetaAkebono',
  ProsmotrPolnogoProfilyaPoljzovatelya = 'ProsmotrPolnogoProfilyaPoljzovatelya',
  ProsmotrSpiskaZelenjjUgol20 = 'ProsmotrSpiskaZelenjjUgol20',
  ProsmotrVsekhLkVjbrannjkhKlientov = 'ProsmotrVsekhLKVjbrannjkhKlientov',
  ProverkaDolgovPoObiektu = 'ProverkaDolgovPoObiektu',
  RazborshhikOtDostupnjkhSudov = 'RazborshhikOtDostupnjkhSudov',
  RedaktirovanieEhksportnjkhSertifikatov = 'RedaktirovanieEhksportnjkhSertifikatov',
  RedaktirovaniePoljzovatelya = 'RedaktirovaniePoljzovatelya',
  SbktsEhkspert = 'SBKTSEhkspert',
  SbktsRazreshenieUdalyatjZayavki = 'SBKTSRazreshenieUdalyatjZayavki',
  SbktsStrashnijEhkspert = 'SBKTSStrashnijEhkspert',
  SbrosPogruzkiRejsa = 'SbrosPogruzkiRejsa',
  SbrosRazgruzkiRejsa = 'SbrosRazgruzkiRejsa',
  SchyotchikPredvariteljnjkhZayavokNaDostavku = 'SchyotchikPredvariteljnjkhZayavokNaDostavku',
  SkladRossiya = 'SkladRossiya',
  SkladovshhikSvKh = 'SkladovshhikSVKh',
  SmenaKlientaObiekta = 'SmenaKlientaObiekta',
  SmenaNomeraKuzova = 'SmenaNomeraKuzova',
  SmenaOtpravitelyaObiekta = 'SmenaOtpravitelyaObiekta',
  SmenaProizvoditelyaIMarki = 'SmenaProizvoditelyaIMarki',
  SmenaStranjNaznacheniya = 'SmenaStranjNaznacheniya',
  SozdanieInvojsaRikun = 'SozdanieInvojsaRikun',
  SozdanieInvojsaZaPokupkuNaYaponskomAuktsione = 'SozdanieInvojsaZaPokupkuNaYaponskomAuktsione',
  SozdanieKontragenta = 'SozdanieKontragenta',
  SozdanieLotaVAkebonoIn = 'SozdanieLotaVAkebonoIn',
  SozdanieZayavokSbkts = 'SozdanieZayavokSBKTS',
  SozdatjZaprosNaVjdachu = 'SozdatjZaprosNaVjdachu',
  SozdavatjZayavkuNaDostavku = 'SozdavatjZayavkuNaDostavku',
  SpisatjSoSklada = 'SpisatjSoSklada',
  StatistikaZagruzkiFotoPoRejsam = 'StatistikaZagruzkiFotoPoRejsam',
  TorgovetsPogranichkami = 'TorgovetsPogranichkami',
  UdalenieFajlovBl = 'UdalenieFajlovBL',
  UpravlenieChatami = 'UpravlenieChatami',
  UpravleniePoljzovatelyami = 'UpravleniePoljzovatelyami',
  VerifitsirovatjPoluchatelya = 'VerifitsirovatjPoluchatelya',
  VjdachaPravaMenyatjTipOplatjTamozhni = 'VjdachaPravaMenyatjTipOplatjTamozhni',
  Voditelj = 'Voditelj',
  YahooMenedzher = 'YahooMenedzher',
  YahooSkladMoto = 'YahooSkladMoto',
  YaponskayaTamozhnya = 'YaponskayaTamozhnya',
  YaponskieRaschetkiSAuktsiona = 'YaponskieRaschetkiSAuktsiona',
  YaponskijSudovojAgent = 'YaponskijSudovojAgent',
  YaponskoeMenyuLogista = 'YaponskoeMenyuLogista',
  ZakrjvatjPretenziiBezUvedomlenij = 'ZakrjvatjPretenziiBezUvedomlenij'
}

export type S3File = {
  __typename?: 'S3File';
  /** Base64 файл */
  binaryBase64?: Maybe<Scalars['String']['output']>;
  /** Ссылка через клаудфронт */
  cloudFrontPath?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Состояние файлов для файлов таможни */
  customDocumentStatus?: Maybe<FileCustomDocumentStatusEnum>;
  /** CDN c учётом разрешения файла и бакета (для старых файлов) */
  fullPathByExtension?: Maybe<Scalars['String']['output']>;
  /** Ссылка на файл с учётом бакета */
  fullPathForFile?: Maybe<Scalars['String']['output']>;
  /** Ссылка через бакет на прямую */
  fullPathForFileWithoutCdn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Путь в бакете */
  key?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserShortInfo>;
};

export type SbktsAdditionalBlank = {
  __typename?: 'SbktsAdditionalBlank';
  blankSeria?: Maybe<SbktsBlankSeria>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SbktsApprove = {
  __typename?: 'SbktsApprove';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

export type SbktsBlankRegistration = {
  __typename?: 'SbktsBlankRegistration';
  id?: Maybe<Scalars['Int']['output']>;
  numberFrom?: Maybe<Scalars['Int']['output']>;
  numberTo?: Maybe<Scalars['Int']['output']>;
};

export type SbktsBlankSeria = {
  __typename?: 'SbktsBlankSeria';
  blankRegistration?: Maybe<SbktsBlankRegistration>;
  blankType?: Maybe<SbktsBlankSeriaEnum>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  usedCount?: Maybe<Scalars['Int']['output']>;
};

export enum SbktsBlankSeriaEnum {
  TypeAdditional = 'TYPE_ADDITIONAL',
  TypeMain = 'TYPE_MAIN'
}

export type SbktsBrokerStatistic = {
  __typename?: 'SbktsBrokerStatistic';
  approved?: Maybe<Scalars['Int']['output']>;
  broker?: Maybe<Broker>;
  created?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Int']['output']>;
  elpts?: Maybe<Scalars['Int']['output']>;
  expertise?: Maybe<Scalars['Int']['output']>;
  hold?: Maybe<Scalars['Int']['output']>;
  movedToAi?: Maybe<Scalars['Int']['output']>;
  movedToTroyan?: Maybe<Scalars['Int']['output']>;
  sbkts?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  unHold?: Maybe<Scalars['Int']['output']>;
};

export type SbktsDamagedBlank = {
  __typename?: 'SbktsDamagedBlank';
  additionalBlank?: Maybe<SbktsAdditionalBlank>;
  blankSeria?: Maybe<SbktsBlankSeria>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  sbktsRequest?: Maybe<SbktsRequest>;
  status?: Maybe<SbktsDamagedStatusEnum>;
  user?: Maybe<User>;
};

export enum SbktsDamagedStatusEnum {
  StatusDamaged = 'STATUS_DAMAGED',
  StatusRevoke = 'STATUS_REVOKE'
}

export type SbktsFile = {
  __typename?: 'SbktsFile';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  doc?: Maybe<DocxTemplate>;
  fileDocx?: Maybe<S3File>;
  filePdf?: Maybe<S3File>;
  fileSig?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']['output']>;
  statusFgis?: Maybe<Scalars['String']['output']>;
  /** Список незаполненных полей в документе */
  undefinedVariables?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SbktsHold = {
  __typename?: 'SbktsHold';
  holdAt?: Maybe<Scalars['DateTime']['output']>;
  holdType?: Maybe<TypeOfHold>;
  id?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<SbktsReason>;
  unHoldAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type SbktsObject = {
  __typename?: 'SbktsObject';
  axesNumber?: Maybe<Scalars['String']['output']>;
  baseSize?: Maybe<Scalars['String']['output']>;
  batterySystem?: Maybe<Scalars['String']['output']>;
  brakeSystem?: Maybe<Scalars['String']['output']>;
  cabine?: Maybe<Scalars['String']['output']>;
  carCategory?: Maybe<Scalars['String']['output']>;
  carLinkage?: Maybe<Scalars['String']['output']>;
  cargoPlace?: Maybe<Scalars['String']['output']>;
  chassis?: Maybe<Scalars['String']['output']>;
  chassisOther?: Maybe<Scalars['String']['output']>;
  clutchSystem?: Maybe<Scalars['String']['output']>;
  collectorSystem?: Maybe<Scalars['String']['output']>;
  combisionEngine?: Maybe<Scalars['String']['output']>;
  combisionEngineCompression?: Maybe<Scalars['String']['output']>;
  combisionEngineCylinder?: Maybe<Scalars['String']['output']>;
  combisionEngineCylindersNumber?: Maybe<Scalars['String']['output']>;
  combisionEngineCylindersPosition?: Maybe<Scalars['String']['output']>;
  combisionEngineFuel?: Maybe<Scalars['String']['output']>;
  combisionEngineFuelSystem?: Maybe<Scalars['String']['output']>;
  combisionEngineIgnitionSystem?: Maybe<Scalars['String']['output']>;
  combisionEngineMaxPowerKWt?: Maybe<Scalars['String']['output']>;
  combisionEngineMaxRpm?: Maybe<Scalars['String']['output']>;
  combisionEngineMinRpm?: Maybe<Scalars['String']['output']>;
  combisionEngineVolumeCm3?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  document?: Maybe<DocumentTemplate>;
  driveType?: Maybe<Scalars['String']['output']>;
  ecologyClass?: Maybe<Scalars['String']['output']>;
  electroSystem?: Maybe<Scalars['String']['output']>;
  electroSystem30mMaxPowerKWt?: Maybe<Scalars['String']['output']>;
  electroSystemVoltage?: Maybe<Scalars['String']['output']>;
  enginePosition?: Maybe<Scalars['String']['output']>;
  forwardSuspensionSystem?: Maybe<Scalars['String']['output']>;
  frameType?: Maybe<Scalars['String']['output']>;
  fullWeight?: Maybe<Scalars['String']['output']>;
  gearBoxSystem?: Maybe<Scalars['String']['output']>;
  generatorSystem?: Maybe<Scalars['String']['output']>;
  generatorSystem30mMaxPower?: Maybe<Scalars['String']['output']>;
  generatorSystemVoltage?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  hybridSystem?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['String']['output']>;
  luggagePlace?: Maybe<Scalars['String']['output']>;
  mainBrakeSystem?: Maybe<Scalars['String']['output']>;
  makerAddress?: Maybe<Scalars['String']['output']>;
  makerName?: Maybe<Scalars['String']['output']>;
  makerPlant?: Maybe<Scalars['String']['output']>;
  manualBrakeSystem?: Maybe<Scalars['String']['output']>;
  markName?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  passengerPlaces?: Maybe<Scalars['String']['output']>;
  places?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  protocol?: Maybe<DocumentProtocol>;
  protocolFields?: Maybe<Array<ProtocolField>>;
  rearSuspensionSystem?: Maybe<Scalars['String']['output']>;
  request?: Maybe<SbktsRequest>;
  sbktsMaker?: Maybe<Scalars['String']['output']>;
  sbktsMark?: Maybe<Scalars['String']['output']>;
  sbktsModel?: Maybe<Scalars['String']['output']>;
  seatPlace?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  spareBrakeSystem?: Maybe<Scalars['String']['output']>;
  steerageSystem?: Maybe<Scalars['String']['output']>;
  steeringWheelPosition?: Maybe<Scalars['String']['output']>;
  suspensionSystem?: Maybe<Scalars['String']['output']>;
  tires?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  transmittionSystem?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
  wheelsTrack?: Maybe<Scalars['String']['output']>;
  wheelsType?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

export type SbktsObjectMedia = {
  __typename?: 'SbktsObjectMedia';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  file?: Maybe<S3File>;
  fileOld?: Maybe<MediaUnion>;
  fileSig?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']['output']>;
  mediaType?: Maybe<SbktsObjectMediaTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SbktsObjectMediaTypeEnum {
  HeadlightTest = 'headlight_test',
  ImgBrakeSystem = 'img_brake_system',
  ImgConsignment = 'img_consignment',
  ImgEmissions = 'img_emissions',
  ImgGeneralView_1 = 'img_general_view_1',
  ImgGeneralView_2 = 'img_general_view_2',
  ImgIdentificationPlate = 'img_identification_plate',
  ImgInnLegalOwner = 'img_inn_legal_owner',
  ImgNoise = 'img_noise',
  ImgOgrnLegalOwner = 'img_ogrn_legal_owner',
  ImgPassportFirst = 'img_passport_first',
  ImgPassportMain = 'img_passport_main',
  ImgPassportRegistration = 'img_passport_registration',
  ImgTpo_1 = 'img_tpo_1',
  ImgTpo_2 = 'img_tpo_2',
  MeasuringPlatform = 'measuring_platform',
  VehicleFromBelow = 'vehicle_from_below'
}

export type SbktsObjectToPlan = {
  __typename?: 'SbktsObjectToPlan';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approver?: Maybe<User>;
  broker?: Maybe<Broker>;
  completeUser?: Maybe<User>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  driverToAi?: Maybe<Driver>;
  driverToTroyan?: Maybe<Driver>;
  elptsAt?: Maybe<Scalars['DateTime']['output']>;
  expert?: Maybe<User>;
  expertAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isExtra?: Maybe<Scalars['Boolean']['output']>;
  movedToAiAt?: Maybe<Scalars['DateTime']['output']>;
  movedToTroyanAt?: Maybe<Scalars['DateTime']['output']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<SbktsPlan>;
  request?: Maybe<SbktsRequest>;
  sbktsAt?: Maybe<Scalars['DateTime']['output']>;
  unloadAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SbktsPlan = {
  __typename?: 'SbktsPlan';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  limitObjects?: Maybe<Scalars['Int']['output']>;
  objects?: Maybe<Array<SbktsObjectToPlan>>;
  planDate?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SbktsPlanStatistic = {
  __typename?: 'SbktsPlanStatistic';
  data?: Maybe<Array<SbktsPlanStatisticByDate>>;
  totalByBrokers?: Maybe<Array<SbktsBrokerStatistic>>;
};

export type SbktsPlanStatisticByDate = {
  __typename?: 'SbktsPlanStatisticByDate';
  brokers?: Maybe<Array<SbktsBrokerStatistic>>;
  date?: Maybe<Scalars['String']['output']>;
  objects?: Maybe<Array<SbktsObjectToPlan>>;
  totalByOneDateWork?: Maybe<Scalars['Int']['output']>;
};

export type SbktsPlansPaginatedList = {
  __typename?: 'SbktsPlansPaginatedList';
  nodes: Array<SbktsPlan>;
  total: Scalars['Int']['output'];
};

export type SbktsReason = {
  __typename?: 'SbktsReason';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type SbktsRequest = {
  __typename?: 'SbktsRequest';
  activeAdditionalBlanks?: Maybe<Array<SbktsAdditionalBlank>>;
  activeHold?: Maybe<SbktsHold>;
  ambassador?: Maybe<Ambassador>;
  approve?: Maybe<SbktsApprove>;
  assignedUser?: Maybe<User>;
  assignedUserAt?: Maybe<Scalars['DateTime']['output']>;
  beginWorkAt?: Maybe<Scalars['DateTime']['output']>;
  blankNumber?: Maybe<Scalars['String']['output']>;
  blankSeria?: Maybe<SbktsBlankSeria>;
  cancelReason?: Maybe<SbktsReason>;
  categoryCar?: Maybe<CategoryCar>;
  comments?: Maybe<Array<SbktsRequestComment>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  damagedBlanks?: Maybe<Array<SbktsDamagedBlank>>;
  deletedAdditionalBlanks?: Maybe<Array<SbktsAdditionalBlank>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryToAi?: Maybe<DeliveryRequest>;
  deliveryToTroyan?: Maybe<DeliveryRequest>;
  events?: Maybe<Array<SbktsRequestEvent>>;
  expertDailyParams?: Maybe<Array<ExpertDailyParam>>;
  files?: Maybe<Array<Maybe<SbktsFile>>>;
  hasClosedHolds?: Maybe<Scalars['Boolean']['output']>;
  holds?: Maybe<Array<SbktsHold>>;
  id?: Maybe<Scalars['Int']['output']>;
  inBoxing?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isCanEditClosed?: Maybe<Scalars['Boolean']['output']>;
  isCanPrint?: Maybe<Scalars['Boolean']['output']>;
  isCanRestore?: Maybe<Scalars['Boolean']['output']>;
  isCompleteRelease?: Maybe<Scalars['Boolean']['output']>;
  isCreateBlank1c?: Maybe<Scalars['Boolean']['output']>;
  isCreateObject1c?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isEmptyThumbprint?: Maybe<Scalars['Boolean']['output']>;
  isHasFewTemplate?: Maybe<Scalars['Boolean']['output']>;
  isHasRequestPdf?: Maybe<Scalars['Boolean']['output']>;
  isHasRequiredAgentMedia?: Maybe<Scalars['Boolean']['output']>;
  isLegalPerson?: Maybe<Scalars['Boolean']['output']>;
  isPaperType?: Maybe<Scalars['Boolean']['output']>;
  isPassedTests?: Maybe<Scalars['Boolean']['output']>;
  isPassedTestsAt?: Maybe<Scalars['DateTime']['output']>;
  isProcessed?: Maybe<Scalars['Boolean']['output']>;
  isQuickly?: Maybe<Scalars['Boolean']['output']>;
  isTroyanStock?: Maybe<Scalars['Boolean']['output']>;
  issuedAt?: Maybe<Scalars['String']['output']>;
  laboratory?: Maybe<Laboratory>;
  laboratoryExpert?: Maybe<LaboratoryExpert>;
  /** Приемщик транспортного средства */
  laboratoryVehicleReceiver?: Maybe<LaboratoryExpert>;
  media?: Maybe<Array<Maybe<SbktsObjectMedia>>>;
  notApprovedByDays?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<ObjectOfServices>;
  protocolNumber?: Maybe<Scalars['String']['output']>;
  readySendToFgisAt?: Maybe<Scalars['DateTime']['output']>;
  requestNumber?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<Broker>;
  sbktsNumber?: Maybe<Scalars['String']['output']>;
  sbktsObject?: Maybe<SbktsObject>;
  sendToFgisAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};


export type SbktsRequestNotApprovedByDaysArgs = {
  days: Scalars['Int']['input'];
};

export type SbktsRequestComment = {
  __typename?: 'SbktsRequestComment';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};

export type SbktsRequestEvent = {
  __typename?: 'SbktsRequestEvent';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type SbktsRequestFilter = {
  assignedUsers?: InputMaybe<Array<Scalars['Int']['input']>>;
  blankNumber?: InputMaybe<Scalars['String']['input']>;
  broken?: InputMaybe<Scalars['Boolean']['input']>;
  broker?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Boolean']['input']>;
  consignment?: InputMaybe<Scalars['Int']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  hasHolds?: InputMaybe<Scalars['Boolean']['input']>;
  isBeginWork?: InputMaybe<Scalars['Boolean']['input']>;
  isCompleteReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFailExchange?: InputMaybe<Scalars['Boolean']['input']>;
  isHasData?: InputMaybe<Scalars['Boolean']['input']>;
  isHasElpts?: InputMaybe<Scalars['Boolean']['input']>;
  isHold?: InputMaybe<Scalars['Boolean']['input']>;
  isInBox?: InputMaybe<Scalars['Boolean']['input']>;
  isMoto?: InputMaybe<Scalars['Boolean']['input']>;
  isNotApproveOver3Day?: InputMaybe<Scalars['Boolean']['input']>;
  isNotApproveOverWeek?: InputMaybe<Scalars['Boolean']['input']>;
  isNotApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isNotHold?: InputMaybe<Scalars['Boolean']['input']>;
  isOnWork?: InputMaybe<Scalars['Boolean']['input']>;
  isPassedTests?: InputMaybe<Scalars['Boolean']['input']>;
  isPriority?: InputMaybe<Scalars['Boolean']['input']>;
  isThirdPartyObject?: InputMaybe<Scalars['Boolean']['input']>;
  laboratory?: InputMaybe<Scalars['Int']['input']>;
  maker?: InputMaybe<Scalars['Int']['input']>;
  mark?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['Int']['input']>;
  moreThirdDays?: InputMaybe<Scalars['Boolean']['input']>;
  onTroyan?: InputMaybe<Scalars['Boolean']['input']>;
  plan?: InputMaybe<Scalars['Int']['input']>;
  requestNumber?: InputMaybe<Scalars['String']['input']>;
  unHold?: InputMaybe<Scalars['Boolean']['input']>;
  unfinished?: InputMaybe<Scalars['Boolean']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SbktsRequestSort = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  approvedAt?: InputMaybe<SortOrderEnum>;
  completedAt?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  passedTestsAt?: InputMaybe<SortOrderEnum>;
  requestNumber?: InputMaybe<SortOrderEnum>;
};

export type SbktsRequestsFilter = {
  sbktsRequest?: InputMaybe<SbktsRequestFilter>;
};

export type SbktsRequestsPaginatedList = {
  __typename?: 'SbktsRequestsPaginatedList';
  nodes: Array<SbktsRequest>;
  total: Scalars['Int']['output'];
};

export type SbktsRequestsSort = {
  sbktsRequest?: InputMaybe<SbktsRequestSort>;
};

export type SbktsStatistic = {
  __typename?: 'SbktsStatistic';
  brokers?: Maybe<Array<SbktsBrokerStatistic>>;
  objects?: Maybe<Array<SbktsObjectToPlan>>;
};

export type Scrap = {
  __typename?: 'Scrap';
  aggregateType?: Maybe<Aggregate>;
  blockingParts?: Maybe<Array<Scrap>>;
  costInPercent?: Maybe<Scalars['Int']['output']>;
  countObjects?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<ScrapTypeDescription>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isNeedEngineNumber?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameJp?: Maybe<Scalars['String']['output']>;
  objectType?: Maybe<TypeOfExport>;
};

export type ScrapAccountSessionUpdateInput = {
  session: Scalars['String']['input'];
  yahooAccount: YahooAccountEnum;
};

export type ScrapAccountSessionUpdateOutput = {
  __typename?: 'ScrapAccountSessionUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ScrapFilter = {
  akbVin?: InputMaybe<Scalars['String']['input']>;
  canceled?: InputMaybe<Scalars['Boolean']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  dateEnd?: InputMaybe<Scalars['String']['input']>;
  delivered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Находится в статусе Ожидает выставления, Выставлен, Не продан, Продан, Отправлен в Яху, Ожидает отправки */
  inStock?: InputMaybe<Scalars['Boolean']['input']>;
  isKaitai?: InputMaybe<Scalars['Boolean']['input']>;
  /** Является запчастью */
  isScrap?: InputMaybe<Scalars['Boolean']['input']>;
  maker?: InputMaybe<Scalars['Int']['input']>;
  mark?: InputMaybe<Scalars['Int']['input']>;
  notSold?: InputMaybe<Scalars['Boolean']['input']>;
  scrapTypeId?: InputMaybe<Scalars['Int']['input']>;
  sold?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  waitDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  waitSendToYahoo?: InputMaybe<Scalars['Boolean']['input']>;
  waitToYahoo?: InputMaybe<Scalars['Boolean']['input']>;
  waitUtilization?: InputMaybe<Scalars['Boolean']['input']>;
  waitYahooApprove?: InputMaybe<Scalars['Boolean']['input']>;
  withoutPlace?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ScrapObject = {
  __typename?: 'ScrapObject';
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  auctionSheet?: Maybe<ObjectMediaItem>;
  autoExtension?: Maybe<Scalars['Boolean']['output']>;
  basket?: Maybe<Scalars['String']['output']>;
  buyoutPrice?: Maybe<Scalars['Int']['output']>;
  carrier?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  countResubmitted?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  endDate?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<YahooLotError>;
  id?: Maybe<Scalars['Int']['output']>;
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  isDelivered?: Maybe<Scalars['Boolean']['output']>;
  isNotSold?: Maybe<Scalars['Boolean']['output']>;
  isResubmitted?: Maybe<Scalars['Boolean']['output']>;
  isSentToYahoo?: Maybe<Scalars['Boolean']['output']>;
  isSold?: Maybe<Scalars['Boolean']['output']>;
  isUtilized?: Maybe<Scalars['Boolean']['output']>;
  isWaitDelivery?: Maybe<Scalars['Boolean']['output']>;
  isWaitUtilization?: Maybe<Scalars['Boolean']['output']>;
  lastSubject?: Maybe<Scalars['String']['output']>;
  lotSize?: Maybe<Scalars['String']['output']>;
  lotSizeMultiplier?: Maybe<Scalars['Int']['output']>;
  markdownRatio?: Maybe<YahooLotResubmitRatioEnum>;
  numResubmit?: Maybe<Scalars['Int']['output']>;
  parentObject?: Maybe<ObjectOfServices>;
  price?: Maybe<Price>;
  scrapObject?: Maybe<ObjectOfServices>;
  scrapType?: Maybe<Scrap>;
  sendToYahooAt?: Maybe<Scalars['DateTime']['output']>;
  sendToYahooBy?: Maybe<User>;
  shipNames?: Maybe<Scalars['String']['output']>;
  shipSizes?: Maybe<YahooLotShipSizes>;
  shipping?: Maybe<Scalars['String']['output']>;
  shippingInput?: Maybe<YahooLotShippingInputEnum>;
  shipschedule?: Maybe<Scalars['String']['output']>;
  state?: Maybe<YahooStateLotEnum>;
  status?: Maybe<Status>;
  trackNumber?: Maybe<Scalars['String']['output']>;
  yahooAccount?: Maybe<Scalars['String']['output']>;
  yahooBuyerId?: Maybe<Scalars['Int']['output']>;
  yahooCategoryCode?: Maybe<Scalars['String']['output']>;
  yahooCategoryId?: Maybe<Scalars['String']['output']>;
  yahooCount?: Maybe<Scalars['Int']['output']>;
  yahooLotDescription?: Maybe<Scalars['String']['output']>;
  yahooLotId?: Maybe<Scalars['String']['output']>;
  yahooLotName?: Maybe<Scalars['String']['output']>;
  yahooPlace?: Maybe<Scalars['String']['output']>;
  yahooPlaceStorage?: Maybe<Scalars['String']['output']>;
};

export type ScrapObjectCommentAddInput = {
  comment: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type ScrapObjectCommentAddOutput = {
  __typename?: 'ScrapObjectCommentAddOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectCreateInput = {
  /** Номер */
  aggregateNumber?: InputMaybe<Scalars['String']['input']>;
  basket?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  lotSize: Scalars['String']['input'];
  /** Количество занимаемых ячеек */
  lotSizeMultiplier?: InputMaybe<Scalars['Int']['input']>;
  objectId: Scalars['Int']['input'];
  place?: InputMaybe<Scalars['String']['input']>;
  /** Тип запчасти */
  scrapTypeId: Scalars['Int']['input'];
  /** Состояние запчасти */
  status: YahooStateLotEnum;
};

export type ScrapObjectCreateOutput = {
  __typename?: 'ScrapObjectCreateOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectDeliverySetInput = {
  address: Scalars['String']['input'];
  carrier: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  trackNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ScrapObjectDeliverySetOutput = {
  __typename?: 'ScrapObjectDeliverySetOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectLotCancelInput = {
  id: Scalars['Int']['input'];
};

export type ScrapObjectLotCancelOutput = {
  __typename?: 'ScrapObjectLotCancelOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectPlaceSetInput = {
  basket?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  place: Scalars['String']['input'];
};

export type ScrapObjectPlaceSetOutput = {
  __typename?: 'ScrapObjectPlaceSetOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectRebindInput = {
  parentObjectId: Scalars['Int']['input'];
  scrapObjectId: Scalars['Int']['input'];
};

export type ScrapObjectRebindOutput = {
  __typename?: 'ScrapObjectRebindOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectSizeSetInput = {
  id: Scalars['Int']['input'];
  lotSize: Scalars['String']['input'];
  lotSizeMultiplier?: InputMaybe<Scalars['Int']['input']>;
};

export type ScrapObjectSizeSetOutput = {
  __typename?: 'ScrapObjectSizeSetOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectStatusSetInput = {
  objectId: Scalars['Int']['input'];
  statusId: Scalars['Int']['input'];
};

export type ScrapObjectStatusSetOutput = {
  __typename?: 'ScrapObjectStatusSetOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectToYahooInput = {
  amount: Scalars['Int']['input'];
  autoExtension: Scalars['Boolean']['input'];
  buyoutPrice?: InputMaybe<Scalars['Int']['input']>;
  categoryCode: Scalars['String']['input'];
  categoryId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  endTime: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  imageIds: Array<Scalars['Int']['input']>;
  markdownRatio?: InputMaybe<YahooLotResubmitRatioEnum>;
  numResubmit?: InputMaybe<Scalars['Int']['input']>;
  shipnames: Array<YahooLotDeliveryServiceEnum>;
  shipping: YahooLotShippingEnum;
  shippinginput: YahooLotShippingInputEnum;
  shipschedule: YahooLotShipScheduleEnum;
  startPrice: Scalars['Int']['input'];
  status: YahooStateLotEnum;
  title: Scalars['String']['input'];
  yahooAccount: YahooAccountEnum;
};

export type ScrapObjectToYahooOutput = {
  __typename?: 'ScrapObjectToYahooOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectUnbindInput = {
  objectId: Scalars['Int']['input'];
};

export type ScrapObjectUnbindOutput = {
  __typename?: 'ScrapObjectUnbindOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectUpdateInput = {
  aggregateNumber?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  scrapTypeId: Scalars['Int']['input'];
};

export type ScrapObjectUpdateOutput = {
  __typename?: 'ScrapObjectUpdateOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectUploadShippingInvoiceInput = {
  id: Scalars['Int']['input'];
  keyS3Files: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ScrapObjectUploadShippingInvoiceOutput = {
  __typename?: 'ScrapObjectUploadShippingInvoiceOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectWriteOffInput = {
  objectId: Scalars['Int']['input'];
};

export type ScrapObjectWriteOffOutput = {
  __typename?: 'ScrapObjectWriteOffOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectsDiscountToYahooInput = {
  objectsIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type ScrapObjectsDiscountToYahooOutput = {
  __typename?: 'ScrapObjectsDiscountToYahooOutput';
  error?: Maybe<MutationError>;
};

export type ScrapTypeCreateInput = {
  aggregateTypeId?: InputMaybe<Scalars['Int']['input']>;
  carTypeId: Scalars['Int']['input'];
  costInPercent?: InputMaybe<Scalars['Int']['input']>;
  countObjects?: InputMaybe<Scalars['Int']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  nameEn: Scalars['String']['input'];
  nameJp: Scalars['String']['input'];
};

export type ScrapTypeCreateOutput = {
  __typename?: 'ScrapTypeCreateOutput';
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescription = {
  __typename?: 'ScrapTypeDescription';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ScrapTypeDescriptionCreateInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ScrapTypeDescriptionCreateOutput = {
  __typename?: 'ScrapTypeDescriptionCreateOutput';
  description?: Maybe<ScrapTypeDescription>;
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescriptionDeleteInput = {
  id: Scalars['Int']['input'];
};

export type ScrapTypeDescriptionDeleteOutput = {
  __typename?: 'ScrapTypeDescriptionDeleteOutput';
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescriptionUpdateInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ScrapTypeDescriptionUpdateOutput = {
  __typename?: 'ScrapTypeDescriptionUpdateOutput';
  description?: Maybe<ScrapTypeDescription>;
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescriptionsPaginatedList = {
  __typename?: 'ScrapTypeDescriptionsPaginatedList';
  nodes: Array<ScrapTypeDescription>;
  total: Scalars['Int']['output'];
};

export type ScrapTypeUpdateInput = {
  aggregateTypeId?: InputMaybe<Scalars['Int']['input']>;
  carTypeId: Scalars['Int']['input'];
  costInPercent?: InputMaybe<Scalars['Int']['input']>;
  countObjects?: InputMaybe<Scalars['Int']['input']>;
  descriptionId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nameEn: Scalars['String']['input'];
  nameJp: Scalars['String']['input'];
};

export type ScrapTypeUpdateOutput = {
  __typename?: 'ScrapTypeUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ScrapUtilizedInput = {
  id: Scalars['Int']['input'];
};

export type ScrapUtilizedOutput = {
  __typename?: 'ScrapUtilizedOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarrier = {
  __typename?: 'SeaCarrier';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SeaCarrierCreateInput = {
  name: Scalars['String']['input'];
};

export type SeaCarrierCreateOutput = {
  __typename?: 'SeaCarrierCreateOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarrierDeleteInput = {
  id: Scalars['Int']['input'];
};

export type SeaCarrierDeleteOutput = {
  __typename?: 'SeaCarrierDeleteOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarrierEditInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type SeaCarrierEditOutput = {
  __typename?: 'SeaCarrierEditOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarriersPaginatedList = {
  __typename?: 'SeaCarriersPaginatedList';
  nodes: Array<SeaCarrier>;
  total: Scalars['Int']['output'];
};

export type SearchObjectsPaginatedList = {
  __typename?: 'SearchObjectsPaginatedList';
  nodes: Array<SearchResult>;
  total: Scalars['Int']['output'];
};

export type SearchResult = Invoice | InvoiceCustom | ObjectOfServices;

export type Sector = {
  __typename?: 'Sector';
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  /** Порядок вывода секторов в списках (сортировка) */
  order?: Maybe<Scalars['Int']['output']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']['output']>;
};

export type SendAllClientsNotificationInput = {
  message: Scalars['String']['input'];
};

export type SendAllClientsNotificationOutput = {
  __typename?: 'SendAllClientsNotificationOutput';
  error?: Maybe<MutationError>;
};

export type SendDirectorFeedbackInput = {
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};

export type SendDirectorFeedbackOutput = {
  __typename?: 'SendDirectorFeedbackOutput';
  error?: Maybe<MutationError>;
};

export type SendFeedbackInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SendFeedbackOutput = {
  __typename?: 'SendFeedbackOutput';
  error?: Maybe<MutationError>;
};

export type SendNotificationEmailInput = {
  notificationKey: EmailObjectNotificationEnum;
  objectId: Scalars['Int']['input'];
};

export type SendNotificationEmailOutput = {
  __typename?: 'SendNotificationEmailOutput';
  error?: Maybe<MutationError>;
};

export type SendPaymentInfoInput = {
  keyS3Files: Array<Scalars['String']['input']>;
  paymentId: Scalars['Int']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SendPaymentInfoOutput = {
  __typename?: 'SendPaymentInfoOutput';
  error?: Maybe<MutationError>;
};

export type SendReportAkebonoPaymentInput = {
  amount: Scalars['String']['input'];
  currencyId: Scalars['Int']['input'];
  invoiceNumber: Scalars['String']['input'];
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['Int']['input']>;
  senderName: Scalars['String']['input'];
  transferDate: Scalars['String']['input'];
};

export type SendReportAkebonoPaymentOutput = {
  __typename?: 'SendReportAkebonoPaymentOutput';
  error?: Maybe<MutationError>;
};

export type SendRussianDeliveryRequestInput = {
  address: Scalars['String']['input'];
  contact: Scalars['String']['input'];
  fileBase64?: InputMaybe<Scalars['String']['input']>;
  objectId: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export type SendRussianDeliveryRequestOutput = {
  __typename?: 'SendRussianDeliveryRequestOutput';
  error?: Maybe<MutationError>;
};

export type Sender = {
  __typename?: 'Sender';
  address?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  defaultOwner?: Maybe<DefaultOwner>;
  email?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Scalars['String']['output']>>;
  fax?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Scalars['String']['output']>>;
};

export type SenderAgent = {
  __typename?: 'SenderAgent';
  data?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<Sender>;
};

export type SenderAgentsSearchFilter = {
  partName?: InputMaybe<Scalars['String']['input']>;
};

export type SendersPaginatedList = {
  __typename?: 'SendersPaginatedList';
  nodes: Array<Sender>;
  total: Scalars['Int']['output'];
};

export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  amount?: Maybe<Scalars['Int']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<User>;
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']['output']>;
  lotLink?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  owner?: Maybe<Owner>;
  requestParam?: Maybe<ServiceRequestTypeParam>;
  requestType?: Maybe<TypeOfServiceRequest>;
  status?: Maybe<ServiceRequestStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ServiceRequestCanceledInput = {
  id: Scalars['Int']['input'];
};

export type ServiceRequestCanceledOutput = {
  __typename?: 'ServiceRequestCanceledOutput';
  error?: Maybe<MutationError>;
};

export type ServiceRequestCompletedInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  services: Array<CompletedServiceRequestInput>;
};

export type ServiceRequestCompletedOutput = {
  __typename?: 'ServiceRequestCompletedOutput';
  error?: Maybe<MutationError>;
};

export type ServiceRequestCount = {
  __typename?: 'ServiceRequestCount';
  auctionInspectionCount?: Maybe<Scalars['Int']['output']>;
  videoInspectionCount?: Maybe<Scalars['Int']['output']>;
};

export type ServiceRequestCreateInput = {
  objectId: Scalars['Int']['input'];
  serviceRequests: Array<ServiceRequestInput>;
};

export type ServiceRequestCreateOutput = {
  __typename?: 'ServiceRequestCreateOutput';
  error?: Maybe<MutationError>;
  serviceRequests?: Maybe<Array<ServiceRequest>>;
};

export type ServiceRequestFilter = {
  /** Поиск по кузову */
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Поиск по кузову */
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Запросы на досмотр на стокярде */
  isStock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Запросы на досмотр USS */
  isUSS?: InputMaybe<Scalars['Boolean']['input']>;
  /** Поиск по кузову */
  searchByVin?: InputMaybe<Scalars['String']['input']>;
  /** По статусу заявки */
  status?: InputMaybe<ServiceRequestStatusEnum>;
  /** Список типов инспекций */
  types?: InputMaybe<Array<InputMaybe<VideoWatchTypeEnum>>>;
};

export type ServiceRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Int']['input'];
  typeParamId?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceRequestObjectsFilter = {
  objectServiceRequest?: InputMaybe<ObjectServiceRequestFilter>;
};

export type ServiceRequestObjectsPaginatedList = {
  __typename?: 'ServiceRequestObjectsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export type ServiceRequestObjectsSort = {
  objectOfService?: InputMaybe<ObjectOfServiceSort>;
};

export type ServiceRequestSort = {
  createdAt?: InputMaybe<SortOrderEnum>;
  dueDate?: InputMaybe<SortOrderEnum>;
};

export enum ServiceRequestStatusEnum {
  Active = 'active',
  Canceled = 'canceled',
  Completed = 'completed'
}

export type ServiceRequestTypeParam = {
  __typename?: 'ServiceRequestTypeParam';
  cost?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEng?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceRequestTypeParamAddInput = {
  cost: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  makerId?: InputMaybe<Scalars['Int']['input']>;
  markId?: InputMaybe<Scalars['Int']['input']>;
  requestTypeId: Scalars['Int']['input'];
};

export type ServiceRequestTypeParamAddOutput = {
  __typename?: 'ServiceRequestTypeParamAddOutput';
  error?: Maybe<MutationError>;
  requestTypeParam?: Maybe<ServiceRequestTypeParam>;
};

export type ServiceRequestsFilter = {
  serviceRequest?: InputMaybe<ServiceRequestFilter>;
};

export type ServiceRequestsPaginatedList = {
  __typename?: 'ServiceRequestsPaginatedList';
  nodes: Array<ServiceRequest>;
  total: Scalars['Int']['output'];
};

export type ServiceRequestsSort = {
  serviceRequest?: InputMaybe<ServiceRequestSort>;
};

export type SetBoundaryInput = {
  boundaryDate: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type SetBoundaryOutput = {
  __typename?: 'SetBoundaryOutput';
  error?: Maybe<MutationError>;
};

export type SetIsGlonassNeedInput = {
  objectId: Scalars['Int']['input'];
};

export type SetIsGlonassNeedOutput = {
  __typename?: 'SetIsGlonassNeedOutput';
  error?: Maybe<MutationError>;
};

export type ShowArchiveFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SortObjectMediaInput = {
  imageIdsArray: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type SortObjectMediaOutput = {
  __typename?: 'SortObjectMediaOutput';
  error?: Maybe<MutationError>;
};

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export enum StatisticTypeEnum {
  Auction = 'auction',
  Date = 'date',
  None = 'none'
}

export type Status = {
  __typename?: 'Status';
  id?: Maybe<Scalars['Int']['output']>;
  outerNameEng?: Maybe<Scalars['String']['output']>;
  outerNameRu?: Maybe<Scalars['String']['output']>;
};

export type StatusToObject = {
  __typename?: 'StatusToObject';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Status>;
};

export type Stock = {
  __typename?: 'Stock';
  /** Список активных секторов */
  activeSectors?: Maybe<Array<Sector>>;
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Склад в Отару */
  inOtaru?: Maybe<Scalars['Boolean']['output']>;
  /** Склад в Томакомай */
  inTomakomai?: Maybe<Scalars['Boolean']['output']>;
  /** Склад в Тояме */
  inToyama?: Maybe<Scalars['Boolean']['output']>;
  /** Склад аукциона */
  isAuction?: Maybe<Scalars['Boolean']['output']>;
  /** Склад в ЯП */
  isJapan?: Maybe<Scalars['Boolean']['output']>;
  /** Склад порта */
  isPort?: Maybe<Scalars['Boolean']['output']>;
  /** Хранение на складе платное */
  isStoragePaid?: Maybe<Scalars['Boolean']['output']>;
  /** Склад судна */
  isVessel?: Maybe<Scalars['Boolean']['output']>;
  /** Виртуальный склад */
  isVirtual?: Maybe<Scalars['Boolean']['output']>;
  /** Склад с которых можно делать выдачу */
  issueAllow?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  /** Флаг отправки уведомления о прибытии на склад клиенту */
  notificationClient?: Maybe<Scalars['Boolean']['output']>;
  /** Флаг отправки уведомления о прибытии на склад менеджеру */
  notificationManager?: Maybe<Scalars['Boolean']['output']>;
  /** Список всех секторов */
  sectors?: Maybe<Array<Sector>>;
  /** Стоимость хранения */
  storageCost?: Maybe<Scalars['Int']['output']>;
  /** Количество дней бесплатного хранения */
  storageFreeDays?: Maybe<Scalars['Int']['output']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']['output']>;
};

export type StockCountiesPaginatedList = {
  __typename?: 'StockCountiesPaginatedList';
  nodes: Array<Country>;
  total: Scalars['Int']['output'];
};

export type StocksFilter = {
  countryStock?: InputMaybe<CountryStockFilter>;
};

export type StocksPaginatedList = {
  __typename?: 'StocksPaginatedList';
  nodes: Array<Stock>;
  total: Scalars['Int']['output'];
};

export type StorageCreateInput = {
  countryId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type StorageCreateOutput = {
  __typename?: 'StorageCreateOutput';
  error?: Maybe<MutationError>;
};

export type StorageDaysByStock = {
  __typename?: 'StorageDaysByStock';
  carrier?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantityStorageDays?: Maybe<Scalars['Int']['output']>;
  stock?: Maybe<Stock>;
};

export type StorageSectorCreateInput = {
  capacity: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  nameEng: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  stockId: Scalars['Int']['input'];
};

export type StorageSectorCreateOutput = {
  __typename?: 'StorageSectorCreateOutput';
  error?: Maybe<MutationError>;
};

export type StorageSectorEditInput = {
  capacity: Scalars['Int']['input'];
  isDeleted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nameEng: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  sectorId: Scalars['Int']['input'];
  stockId: Scalars['Int']['input'];
};

export type StorageSectorEditOutput = {
  __typename?: 'StorageSectorEditOutput';
  error?: Maybe<MutationError>;
};

export type StorageUpdateInput = {
  /** Id склада */
  id: Scalars['Int']['input'];
  /** Уведомлять клиента о прибытии объекта */
  notificationClient: Scalars['Boolean']['input'];
  /** Уведомлять менеджера о прибытии объекта */
  notificationManager: Scalars['Boolean']['input'];
  /** Стоимость одного дня хранения (Иен) */
  storageCost?: InputMaybe<Scalars['Int']['input']>;
  /** Количество дней бесплатного хранения */
  storageFreeDays?: InputMaybe<Scalars['Int']['input']>;
};

export type StorageUpdateOutput = {
  __typename?: 'StorageUpdateOutput';
  error?: Maybe<MutationError>;
};

export type SubscribeUserToTelegramInput = {
  botId: Scalars['Int']['input'];
};

export type SubscribeUserToTelegramOutput = {
  __typename?: 'SubscribeUserToTelegramOutput';
  error?: Maybe<MutationError>;
  uuid: Scalars['String']['output'];
};

export type SyncObjectInput = {
  objectId: Scalars['Int']['input'];
};

export type SyncObjectOutput = {
  __typename?: 'SyncObjectOutput';
  error?: Maybe<MutationError>;
};

export type Tag = {
  __typename?: 'Tag';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  name_eng?: Maybe<Scalars['String']['output']>;
};

export type Tariff = {
  __typename?: 'Tariff';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ThirdPartyTrip = {
  __typename?: 'ThirdPartyTrip';
  cancelReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  contract?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  quantityCancelled?: Maybe<Scalars['String']['output']>;
  trip?: Maybe<Trip>;
};

export type Ticket = {
  __typename?: 'Ticket';
  accessUsers?: Maybe<Array<User>>;
  /** @deprecated Moved to decision */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Moved to decision */
  approvedBy?: Maybe<User>;
  canApproveOrganization?: Maybe<Scalars['Boolean']['output']>;
  canApproveStaff?: Maybe<Scalars['Boolean']['output']>;
  canDecline?: Maybe<Scalars['Boolean']['output']>;
  canPayout?: Maybe<Scalars['Boolean']['output']>;
  canStaffActions?: Maybe<Scalars['Boolean']['output']>;
  clientChat?: Maybe<ChatForOthers>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  decisions?: Maybe<Array<TicketDecision>>;
  declineAt?: Maybe<Scalars['DateTime']['output']>;
  expireTime?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<S3File>>;
  history?: Maybe<Array<TicketHistory>>;
  id?: Maybe<Scalars['Int']['output']>;
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  isDecline?: Maybe<Scalars['Boolean']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  object?: Maybe<ObjectOfServices>;
  organizationChat?: Maybe<ChatForOthers>;
  reasonInner?: Maybe<TicketReason>;
  requestAmount?: Maybe<Price>;
  review?: Maybe<TicketReview>;
  status?: Maybe<TicketStatus>;
  statuses?: Maybe<Array<TicketStatuses>>;
  ticketReason?: Maybe<TicketReason>;
  ticketSubType?: Maybe<TypeOfTicketSub>;
  ticketType?: Maybe<TypeOfTicket>;
};

export type TicketAddReviewInput = {
  message: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Float']['input']>;
  ticketId: Scalars['Int']['input'];
};

export type TicketAddReviewOutput = {
  __typename?: 'TicketAddReviewOutput';
  error?: Maybe<MutationError>;
};

export type TicketAddUserInput = {
  ticketId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type TicketAddUserOutput = {
  __typename?: 'TicketAddUserOutput';
  error?: Maybe<MutationError>;
};

export type TicketApproveItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  compensationAmount?: InputMaybe<Scalars['Float']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  decisionId: Scalars['Int']['input'];
  guiltyMessage: Scalars['String']['input'];
  isCompensatedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  isPayOrganization?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TicketChangeSubTypeInput = {
  ticketId: Scalars['Int']['input'];
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketChangeSubTypeOutput = {
  __typename?: 'TicketChangeSubTypeOutput';
  error?: Maybe<MutationError>;
};

export type TicketChangeTypeInput = {
  ticketId: Scalars['Int']['input'];
  typeId: Scalars['Int']['input'];
};

export type TicketChangeTypeOutput = {
  __typename?: 'TicketChangeTypeOutput';
  error?: Maybe<MutationError>;
};

export enum TicketClientStatusEnum {
  Cancel = 'CANCEL',
  Done = 'DONE',
  New = 'NEW',
  Progress = 'PROGRESS',
  WaitPayout = 'WAIT_PAYOUT'
}

export type TicketCreateInput = {
  keyS3Files?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
  requestAmount?: InputMaybe<Scalars['Int']['input']>;
  ticketTypeId: Scalars['Int']['input'];
};

export type TicketCreateOutput = {
  __typename?: 'TicketCreateOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecision = {
  __typename?: 'TicketDecision';
  amount?: Maybe<Price>;
  appealCount?: Maybe<Scalars['Int']['output']>;
  appealMessage?: Maybe<Scalars['String']['output']>;
  approveToPayoutAt?: Maybe<Scalars['DateTime']['output']>;
  approveToPayoutBy?: Maybe<User>;
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  canAppeal?: Maybe<Scalars['Boolean']['output']>;
  canApproveOrganization?: Maybe<Scalars['Boolean']['output']>;
  canCancel?: Maybe<Scalars['Boolean']['output']>;
  canChange?: Maybe<Scalars['Boolean']['output']>;
  canResetWaitPayout?: Maybe<Scalars['Boolean']['output']>;
  cancelAt?: Maybe<Scalars['DateTime']['output']>;
  cancelBy?: Maybe<User>;
  compensationAmount?: Maybe<Price>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  expireTime?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<S3File>>;
  guiltyMessage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCompensatedByThirdParty?: Maybe<Scalars['Boolean']['output']>;
  isPayOrganization?: Maybe<Scalars['Boolean']['output']>;
  isPenalty?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Organization>;
  organizationComment?: Maybe<Scalars['String']['output']>;
  payoutAt?: Maybe<Scalars['DateTime']['output']>;
  payoutBy?: Maybe<User>;
  status?: Maybe<TicketStatus>;
  ticket?: Maybe<Ticket>;
};

export type TicketDecisionAddInput = {
  decisions: Array<TicketDecisionItemInput>;
  ticketId: Scalars['Int']['input'];
};

export type TicketDecisionAddOutput = {
  __typename?: 'TicketDecisionAddOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionAppealInput = {
  decisionId: Scalars['Int']['input'];
  keyS3Files?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message: Scalars['String']['input'];
};

export type TicketDecisionAppealOutput = {
  __typename?: 'TicketDecisionAppealOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionApproveInput = {
  ticketId: Scalars['Int']['input'];
};

export type TicketDecisionApproveOrganizationInput = {
  decisions: Array<InputMaybe<TicketApproveItemInput>>;
  ticketId: Scalars['Int']['input'];
};

export type TicketDecisionApproveOrganizationOutput = {
  __typename?: 'TicketDecisionApproveOrganizationOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionApproveOutput = {
  __typename?: 'TicketDecisionApproveOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionCancelInput = {
  decisionId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
};

export type TicketDecisionCancelOnlyAdminInput = {
  decisionId: Scalars['Int']['input'];
};

export type TicketDecisionCancelOnlyAdminOutput = {
  __typename?: 'TicketDecisionCancelOnlyAdminOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionCancelOutput = {
  __typename?: 'TicketDecisionCancelOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionFilter = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  decisionStatuses?: InputMaybe<Array<Scalars['Int']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAppeal?: InputMaybe<Scalars['Boolean']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isPayDirector?: InputMaybe<Scalars['Boolean']['input']>;
  isPayout?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<Array<Scalars['Int']['input']>>;
  payoutDateFrom?: InputMaybe<Scalars['String']['input']>;
  payoutDateTo?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['Int']['input']>;
  ticketNumber?: InputMaybe<Scalars['String']['input']>;
  ticketStatuses?: InputMaybe<Array<Scalars['Int']['input']>>;
  trips?: InputMaybe<Array<Scalars['Int']['input']>>;
  vessels?: InputMaybe<Array<Scalars['Int']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type TicketDecisionInput = {
  decisions: Array<TicketDecisionItemInput>;
  innerReason?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  ticketId: Scalars['Int']['input'];
};

export type TicketDecisionItemInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['Int']['input'];
};

export type TicketDecisionOutput = {
  __typename?: 'TicketDecisionOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionPayoutInput = {
  message: Scalars['String']['input'];
  ticketId: Scalars['Int']['input'];
};

export type TicketDecisionPayoutOutput = {
  __typename?: 'TicketDecisionPayoutOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionResetPayoutInput = {
  decisionId: Scalars['Int']['input'];
};

export type TicketDecisionResetPayoutOutput = {
  __typename?: 'TicketDecisionResetPayoutOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionUpdateInput = {
  amount: Scalars['String']['input'];
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  decisionId: Scalars['Int']['input'];
};

export type TicketDecisionUpdateOutput = {
  __typename?: 'TicketDecisionUpdateOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionsFilter = {
  ticketDecision?: InputMaybe<TicketDecisionFilter>;
};

export type TicketDecisionsPaginatedList = {
  __typename?: 'TicketDecisionsPaginatedList';
  nodes: Array<TicketDecision>;
  total: Scalars['Int']['output'];
};

export type TicketDeclineInput = {
  innerReason: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  ticketId: Scalars['Int']['input'];
};

export type TicketDeclineOutput = {
  __typename?: 'TicketDeclineOutput';
  error?: Maybe<MutationError>;
};

export type TicketFilter = {
  activeForReport?: InputMaybe<Scalars['Boolean']['input']>;
  byStatus?: InputMaybe<TicketClientStatusEnum>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  decisionCreate?: InputMaybe<Scalars['String']['input']>;
  forReport?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isPayDirector?: InputMaybe<Scalars['Boolean']['input']>;
  isPayout?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<Array<Scalars['Int']['input']>>;
  payoutDateFrom?: InputMaybe<Scalars['String']['input']>;
  payoutDateTo?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<Scalars['Int']['input']>>;
  ticketNumber?: InputMaybe<Scalars['String']['input']>;
  trips?: InputMaybe<Array<Scalars['Int']['input']>>;
  types?: InputMaybe<Array<Scalars['Int']['input']>>;
  vessels?: InputMaybe<Array<Scalars['Int']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type TicketHistory = {
  __typename?: 'TicketHistory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type TicketReason = {
  __typename?: 'TicketReason';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TicketRemoveUserInput = {
  ticketId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type TicketRemoveUserOutput = {
  __typename?: 'TicketRemoveUserOutput';
  error?: Maybe<MutationError>;
};

export type TicketResetInput = {
  ticketId: Scalars['Int']['input'];
};

export type TicketResetOutput = {
  __typename?: 'TicketResetOutput';
  error?: Maybe<MutationError>;
};

export type TicketReview = {
  __typename?: 'TicketReview';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

export type TicketSilentCloseInput = {
  ticketId: Scalars['Int']['input'];
};

export type TicketSilentCloseOutput = {
  __typename?: 'TicketSilentCloseOutput';
  error?: Maybe<MutationError>;
};

export type TicketSort = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
};

export type TicketStatistic = {
  __typename?: 'TicketStatistic';
  closedByCloseRange?: Maybe<Scalars['Float']['output']>;
  closedByCreatedRange?: Maybe<Scalars['Float']['output']>;
  closedTotal?: Maybe<Scalars['Float']['output']>;
  createdByRange?: Maybe<Scalars['Float']['output']>;
  createdTotal?: Maybe<Scalars['Float']['output']>;
  declineTotal?: Maybe<Scalars['Float']['output']>;
  declinedByCreatedRange?: Maybe<Scalars['Float']['output']>;
  declinedByDeclineRange?: Maybe<Scalars['Float']['output']>;
  inProgressCreateByRange?: Maybe<Scalars['Float']['output']>;
  inProgressTotal?: Maybe<Scalars['Float']['output']>;
  waitApproveByCreateRange?: Maybe<Scalars['Float']['output']>;
  waitApproveByDecisionRange?: Maybe<Scalars['Float']['output']>;
  waitApproveTotal?: Maybe<Scalars['Float']['output']>;
  waitDecisionByCreateRange?: Maybe<Scalars['Float']['output']>;
  waitDecisionTotal?: Maybe<Scalars['Float']['output']>;
  waitOrganizationApproveByApproveRange?: Maybe<Scalars['Float']['output']>;
  waitOrganizationApproveByCreatedRange?: Maybe<Scalars['Float']['output']>;
  waitOrganizationApproveTotal?: Maybe<Scalars['Float']['output']>;
  waitPayoutByApproveRange?: Maybe<Scalars['Float']['output']>;
  waitPayoutByCreatedRange?: Maybe<Scalars['Float']['output']>;
  waitPayoutTotal?: Maybe<Scalars['Float']['output']>;
};

export type TicketStatus = {
  __typename?: 'TicketStatus';
  forDecision?: Maybe<Scalars['Boolean']['output']>;
  forTicket?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  innerName?: Maybe<Scalars['String']['output']>;
  outerName?: Maybe<Scalars['String']['output']>;
  outerNameEn?: Maybe<Scalars['String']['output']>;
};

export type TicketStatuses = {
  __typename?: 'TicketStatuses';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TicketStatus>;
};

export type TicketsFilter = {
  ticket?: InputMaybe<TicketFilter>;
};

export type TicketsPaginatedList = {
  __typename?: 'TicketsPaginatedList';
  nodes: Array<Ticket>;
  total: Scalars['Int']['output'];
};

export type TicketsReasonsPaginatedList = {
  __typename?: 'TicketsReasonsPaginatedList';
  nodes: Array<TicketReason>;
  total: Scalars['Int']['output'];
};

export type TicketsSort = {
  ticket?: InputMaybe<TicketSort>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  name?: Maybe<Scalars['String']['output']>;
  offset?: Maybe<Scalars['String']['output']>;
};

export enum TireTypeEnum {
  Allseason = 'ALLSEASON',
  Dirt = 'DIRT',
  Spike = 'SPIKE',
  Summer = 'SUMMER',
  Winter = 'WINTER'
}

export type ToggleUserNotifyMessageInput = {
  channelId: Scalars['Int']['input'];
  messageTypeId: Scalars['Int']['input'];
};

export type ToggleUserNotifyMessageOutput = {
  __typename?: 'ToggleUserNotifyMessageOutput';
  error?: Maybe<MutationError>;
};

export type TotalCustomsClearanceTime = {
  __typename?: 'TotalCustomsClearanceTime';
  bySenders?: Maybe<Array<CustomsClearanceTimeBySender>>;
  byTypes?: Maybe<Array<CustomsClearanceTimeByTypes>>;
};

export type Transmission = {
  __typename?: 'Transmission';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Trip = {
  __typename?: 'Trip';
  bls?: Maybe<Array<Bl>>;
  countObjectByPlan?: Maybe<Scalars['Int']['output']>;
  /** Растаможка от */
  customsClearanceFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Растаможка до */
  customsClearanceTo?: Maybe<Scalars['DateTime']['output']>;
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  dateVl?: Maybe<Scalars['DateTime']['output']>;
  exportDeclarations?: Maybe<Array<ExportDeclaration>>;
  exportPaidAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<S3File>>;
  fullTitle?: Maybe<Scalars['String']['output']>;
  guaranteedPlaces?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isArrived?: Maybe<Scalars['Boolean']['output']>;
  isBookingAllow?: Maybe<Scalars['Boolean']['output']>;
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  isExport?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  numberContainerLine?: Maybe<Scalars['String']['output']>;
  /** Third party objects */
  objects?: Maybe<Array<ObjectOfServices>>;
  plan?: Maybe<Plan>;
  plans?: Maybe<Array<Plan>>;
  portFrom?: Maybe<Port>;
  portTo?: Maybe<Port>;
  reservePlaces?: Maybe<Scalars['Int']['output']>;
  seaCarrier?: Maybe<SeaCarrier>;
  /** Выгрузка от */
  shipUnloadingFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Выгрузка до */
  shipUnloadingTo?: Maybe<Scalars['DateTime']['output']>;
  shipped?: Maybe<Scalars['Boolean']['output']>;
  simpleTitle?: Maybe<Scalars['String']['output']>;
  stockTo?: Maybe<Stock>;
  tripBoxes?: Maybe<Array<TripBox>>;
  unloadingByUser?: Maybe<User>;
  unloadingFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vessel?: Maybe<Vessel>;
  vesselArrived?: Maybe<Scalars['Boolean']['output']>;
  vesselNotArrived?: Maybe<Scalars['Boolean']['output']>;
};

export type TripBox = {
  __typename?: 'TripBox';
  destinationOffice?: Maybe<Scalars['Int']['output']>;
  destinationOfficeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  packages?: Maybe<Array<ObjectPackage>>;
  trip?: Maybe<Trip>;
};

export type TripCalendarEvent = {
  __typename?: 'TripCalendarEvent';
  allDay?: Maybe<Scalars['Boolean']['output']>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  borderColor?: Maybe<Scalars['String']['output']>;
  classNames?: Maybe<Scalars['String']['output']>;
  colorWarning?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconWarning?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  textColor?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleWarning?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TripChangeObjectsOrderInput = {
  objectOrderList: Array<TripObjectOrderInput>;
  planId: Scalars['Int']['input'];
};

export type TripChangeObjectsOrderOutput = {
  __typename?: 'TripChangeObjectsOrderOutput';
  error?: Maybe<MutationError>;
};

export type TripCloseInput = {
  id: Scalars['Int']['input'];
};

export type TripCloseOutput = {
  __typename?: 'TripCloseOutput';
  error?: Maybe<MutationError>;
};

export type TripCreateInput = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  dateVl: Scalars['DateTime']['input'];
  number: Scalars['String']['input'];
  portFrom: Scalars['Int']['input'];
  portTo: Scalars['Int']['input'];
  seaCarrierId: Scalars['Int']['input'];
  ship: Scalars['Int']['input'];
  stockTo: Scalars['Int']['input'];
};

export type TripCreateOutput = {
  __typename?: 'TripCreateOutput';
  error?: Maybe<MutationError>;
};

export type TripDeleteInput = {
  id: Scalars['Int']['input'];
};

export type TripDeleteOutput = {
  __typename?: 'TripDeleteOutput';
  error?: Maybe<MutationError>;
};

export type TripEditInput = {
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  dateVl: Scalars['DateTime']['input'];
  id: Scalars['Int']['input'];
  number: Scalars['String']['input'];
  portFrom: Scalars['Int']['input'];
  portTo: Scalars['Int']['input'];
  seaCarrierId: Scalars['Int']['input'];
  ship: Scalars['Int']['input'];
  stockTo: Scalars['Int']['input'];
};

export type TripEditOutput = {
  __typename?: 'TripEditOutput';
  error?: Maybe<MutationError>;
};

export type TripFilter = {
  /** По названию филиала офиса */
  branchOffice?: InputMaybe<Scalars['String']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  destinationCountry?: InputMaybe<Scalars['Int']['input']>;
  forBoxes?: InputMaybe<Scalars['Boolean']['input']>;
  forParking?: InputMaybe<Scalars['Boolean']['input']>;
  forTickets?: InputMaybe<Scalars['Boolean']['input']>;
  fromCountry?: InputMaybe<Array<Scalars['Int']['input']>>;
  fromJapan?: InputMaybe<Scalars['Boolean']['input']>;
  /** С долгами */
  hasDebts?: InputMaybe<Scalars['Boolean']['input']>;
  /** По состоянию */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isRoro?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  tripsIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  vesselId?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export enum TripFilterEnum {
  All = 'all',
  WithTrip = 'with_trip',
  WithoutTrip = 'without_trip'
}

export type TripLoadingInput = {
  planId: Scalars['Int']['input'];
};

export type TripLoadingOutput = {
  __typename?: 'TripLoadingOutput';
  error?: Maybe<MutationError>;
};

export type TripObjectOrderInput = {
  objectId: Scalars['Int']['input'];
  /** Позиция в списке */
  position: Scalars['Int']['input'];
};

export type TripReturnToSourcePortInput = {
  planId: Scalars['Int']['input'];
};

export type TripReturnToSourcePortOutput = {
  __typename?: 'TripReturnToSourcePortOutput';
  error?: Maybe<MutationError>;
};

export type TripSchedule = {
  __typename?: 'TripSchedule';
  trips?: Maybe<Array<TripScheduleInfo>>;
  /** Самая последняя дата обновления среди рейсов и их планов погрузки */
  tripsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TripScheduleInfo = {
  __typename?: 'TripScheduleInfo';
  id?: Maybe<Scalars['Int']['output']>;
  serialNum?: Maybe<Scalars['String']['output']>;
  ship?: Maybe<VesselInfo>;
  status?: Maybe<TripStatusEnum>;
  wayPoints?: Maybe<Array<WayPoint>>;
};

export type TripSort = {
  createdAt?: InputMaybe<SortOrderEnum>;
  dateFrom?: InputMaybe<SortOrderEnum>;
  dateTo?: InputMaybe<SortOrderEnum>;
};

export enum TripStatusEnum {
  ComingSoon = 'comingSoon',
  DepartingSoon = 'departingSoon',
  OnWay = 'onWay',
  RecentlyEnded = 'recentlyEnded'
}

export type TripToPortInput = {
  containerId?: InputMaybe<Scalars['Int']['input']>;
  planId: Scalars['Int']['input'];
};

export type TripToPortOutput = {
  __typename?: 'TripToPortOutput';
  error?: Maybe<MutationError>;
};

export type TripsCalendar = {
  __typename?: 'TripsCalendar';
  events?: Maybe<Array<TripCalendarEvent>>;
  ships?: Maybe<Array<VesselInfoCalendar>>;
};

export type TripsFilter = {
  trip?: InputMaybe<TripFilter>;
};

export type TripsPaginatedList = {
  __typename?: 'TripsPaginatedList';
  nodes: Array<Trip>;
  total: Scalars['Int']['output'];
};

export type TripsSort = {
  trip?: InputMaybe<TripSort>;
};

export type TroyanCustomer = {
  __typename?: 'TroyanCustomer';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfAdditional = {
  __typename?: 'TypeOfAdditional';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export type TypeOfAdditionalServiceRequest = {
  __typename?: 'TypeOfAdditionalServiceRequest';
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEng?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export type TypeOfChassis = {
  __typename?: 'TypeOfChassis';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<ChassisTypeEnum>;
};

export type TypeOfContainer = {
  __typename?: 'TypeOfContainer';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfCustom = {
  __typename?: 'TypeOfCustom';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum TypeOfDocumentEnum {
  Header = 'header',
  Template = 'template'
}

export type TypeOfEngine = {
  __typename?: 'TypeOfEngine';
  alias?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfExport = {
  __typename?: 'TypeOfExport';
  id?: Maybe<Scalars['Int']['output']>;
  isDefaultForScrap?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
  outerNameEng?: Maybe<Scalars['String']['output']>;
  outerNameRu?: Maybe<Scalars['String']['output']>;
};

export type TypeOfExportEditInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  nameEng: Scalars['String']['input'];
};

export type TypeOfExportEditOutput = {
  __typename?: 'TypeOfExportEditOutput';
  error?: Maybe<MutationError>;
};

export type TypeOfFlat = {
  __typename?: 'TypeOfFlat';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfFreight = {
  __typename?: 'TypeOfFreight';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfHold = {
  __typename?: 'TypeOfHold';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfHouse = {
  __typename?: 'TypeOfHouse';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfHousing = {
  __typename?: 'TypeOfHousing';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum TypeOfJapanPaymentEnum {
  Food = 'FOOD',
  Fuel = 'FUEL',
  Highway = 'HIGHWAY'
}

export type TypeOfLocality = {
  __typename?: 'TypeOfLocality';
  fullName?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
};

export type TypeOfObjectKey = {
  __typename?: 'TypeOfObjectKey';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export enum TypeOfOwnerEnum {
  Broker = 'BROKER',
  FormalOwner = 'FORMAL_OWNER',
  Owner = 'OWNER',
  Sender = 'SENDER'
}

export type TypeOfSaw = {
  __typename?: 'TypeOfSaw';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<DisassemblyPlan>;
};

export type TypeOfServiceRequest = {
  __typename?: 'TypeOfServiceRequest';
  additionalTypes?: Maybe<Array<TypeOfAdditionalServiceRequest>>;
  cost?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEng?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAdditional?: Maybe<Scalars['Boolean']['output']>;
  isForObject?: Maybe<Scalars['Boolean']['output']>;
  isOrdered?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};


export type TypeOfServiceRequestIsOrderedArgs = {
  objectId?: InputMaybe<Scalars['Int']['input']>;
};

export enum TypeOfStatusEnum {
  Object = 'OBJECT',
  ObjectResale = 'OBJECT_RESALE',
  Troyan = 'TROYAN',
  YahooLot = 'YAHOO_LOT'
}

export type TypeOfTicket = {
  __typename?: 'TypeOfTicket';
  code?: Maybe<Scalars['String']['output']>;
  defaultCurrency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export type TypeOfTicketSub = {
  __typename?: 'TypeOfTicketSub';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfUseVehicle = {
  __typename?: 'TypeOfUseVehicle';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TypeOfUser = {
  __typename?: 'TypeOfUser';
  id?: Maybe<Scalars['Int']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameRu?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TypeOfUserEnum>;
};

export enum TypeOfUserEnum {
  UserTypeBrokerAccount = 'USER_TYPE_BROKER_ACCOUNT',
  UserTypeClientAccount = 'USER_TYPE_CLIENT_ACCOUNT',
  UserTypeEmployeeAccount = 'USER_TYPE_EMPLOYEE_ACCOUNT',
  UserTypeRecipientAccount = 'USER_TYPE_RECIPIENT_ACCOUNT',
  UserTypeSenderAccount = 'USER_TYPE_SENDER_ACCOUNT',
  UserTypeSvhManagerAccount = 'USER_TYPE_SVH_MANAGER_ACCOUNT',
  UserTypeTroyanEmployeeAccount = 'USER_TYPE_TROYAN_EMPLOYEE_ACCOUNT'
}

export type TypeOfWatchCar = {
  __typename?: 'TypeOfWatchCar';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UnResolvedTaskFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UnViewChatInput = {
  chatId: Scalars['Int']['input'];
};

export type UnViewChatOutput = {
  __typename?: 'UnViewChatOutput';
  error?: Maybe<MutationError>;
};

export type UndefinedPayment = {
  __typename?: 'UndefinedPayment';
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']['output']>;
  payerName?: Maybe<Scalars['String']['output']>;
  receiptDate?: Maybe<Scalars['DateTime']['output']>;
};

export type UndefinedPaymentFilter = {
  maxMays?: InputMaybe<Scalars['Int']['input']>;
  minDays?: InputMaybe<Scalars['Int']['input']>;
};

export type UndefinedPaymentSort = {
  amount?: InputMaybe<SortOrderEnum>;
  date?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type UndefinedPaymentsFilter = {
  undefinedPayment?: InputMaybe<UndefinedPaymentFilter>;
};

export type UndefinedPaymentsPaginatedList = {
  __typename?: 'UndefinedPaymentsPaginatedList';
  nodes: Array<UndefinedPayment>;
  total: Scalars['Int']['output'];
};

export type UndefinedPaymentsSort = {
  undefinedPayment?: InputMaybe<UndefinedPaymentSort>;
};

export type UnlockTheIssuanceInput = {
  comment: Scalars['String']['input'];
  objectId: Scalars['Int']['input'];
};

export type UnlockTheIssuanceOutput = {
  __typename?: 'UnlockTheIssuanceOutput';
  error?: Maybe<MutationError>;
};

export type UnreadFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateInvoiceAuctionPurchaseInput = {
  invoiceKeysS3?: InputMaybe<Array<Scalars['String']['input']>>;
  nomenclaturesList: Array<InputMaybe<InvoiceServiceInput>>;
  objectId: Scalars['Int']['input'];
};

export type UpdateInvoiceAuctionPurchaseOutput = {
  __typename?: 'UpdateInvoiceAuctionPurchaseOutput';
  error?: Maybe<MutationError>;
};

export type UploadFilesInput = {
  entity: MediaEntityEnum;
  entityId: Scalars['Int']['input'];
  keyS3Files: Array<Scalars['String']['input']>;
  mediaType: MediaTypeEnum;
  onlyOneFile?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UploadFilesOutput = {
  __typename?: 'UploadFilesOutput';
  error?: Maybe<MutationError>;
};

export type User = {
  __typename?: 'User';
  agent?: Maybe<Agent>;
  allowedMediaTypes?: Maybe<Array<MediaTypeEnum>>;
  auctionGroups?: Maybe<Array<AuctionGroup>>;
  balance?: Maybe<Array<Money>>;
  broker?: Maybe<Broker>;
  carrierDriver?: Maybe<Driver>;
  chatGroups?: Maybe<Array<ChatGroup>>;
  clientsUsers?: Maybe<Array<UserShortInfo>>;
  commentCssClass?: Maybe<Scalars['String']['output']>;
  debtUpdatedDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  digests?: Maybe<Array<Digest>>;
  driverMode?: Maybe<DriverMode>;
  emails?: Maybe<Array<UserEmail>>;
  expireAccount?: Maybe<Scalars['String']['output']>;
  expireToken?: Maybe<Scalars['Int']['output']>;
  freightPaymentCountry?: Maybe<Country>;
  guid1C?: Maybe<Scalars['String']['output']>;
  hasPermission?: Maybe<Scalars['Boolean']['output']>;
  hasRole?: Maybe<Scalars['Boolean']['output']>;
  homePageUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAcceptCondition?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  /** Наличие подключенного телеграмм бота akebonobot */
  isBotAkebono?: Maybe<Scalars['Boolean']['output']>;
  /** Наличие подключенного телеграмм бота cartrackbot */
  isBotCarsTrack?: Maybe<Scalars['Boolean']['output']>;
  /** Наличие подключенного телеграмм бота ecarjpbot */
  isBotEcarJpAuc?: Maybe<Scalars['Boolean']['output']>;
  isBroker?: Maybe<Scalars['Boolean']['output']>;
  isChangePassword?: Maybe<Scalars['Boolean']['output']>;
  isClient?: Maybe<Scalars['Boolean']['output']>;
  /** Политика со скрытыми финансами */
  isHiddenFinance?: Maybe<Scalars['Boolean']['output']>;
  isMasquerade?: Maybe<Scalars['Boolean']['output']>;
  isPermanent?: Maybe<Scalars['Boolean']['output']>;
  isSender?: Maybe<Scalars['Boolean']['output']>;
  /** Флаг для отображения\скрытия отчёта 1С */
  isShowReport?: Maybe<Scalars['Boolean']['output']>;
  isStaff?: Maybe<Scalars['Boolean']['output']>;
  isUpdateContact?: Maybe<Scalars['Boolean']['output']>;
  laboratoryExpert?: Maybe<LaboratoryExpert>;
  language?: Maybe<LanguageEnum>;
  masqueradeUser?: Maybe<Scalars['Int']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  owner?: Maybe<Owner>;
  permissionGroups?: Maybe<Array<PermissionGroup>>;
  permissions?: Maybe<Array<Permission>>;
  ports?: Maybe<Array<Port>>;
  /** @deprecated Useless */
  reminders?: Maybe<Array<Reminder>>;
  requiredChangePassword?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<UserRole>;
  roles?: Maybe<Array<UserRole>>;
  sender?: Maybe<Sender>;
  stocks?: Maybe<Array<Stock>>;
  tickets?: Maybe<Array<Ticket>>;
  timeZone?: Maybe<TimeZone>;
  type?: Maybe<TypeOfUser>;
  userParameter?: Maybe<UserParameter>;
  username?: Maybe<Scalars['String']['output']>;
  vessels?: Maybe<Array<Vessel>>;
};


export type UserBalanceArgs = {
  isAkebono?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserHasPermissionArgs = {
  code: Scalars['String']['input'];
  componentName?: InputMaybe<Scalars['String']['input']>;
  ignoreSuperPermission?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserHasRoleArgs = {
  isBase: Scalars['Boolean']['input'];
  role: RoleEnum;
};

export type UserChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type UserChangePasswordOutput = {
  __typename?: 'UserChangePasswordOutput';
  error?: Maybe<MutationError>;
};

export type UserChannel = {
  __typename?: 'UserChannel';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  enabledMessage?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserEmail = {
  __typename?: 'UserEmail';
  dateConfirm?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isConfirm?: Maybe<Scalars['Boolean']['output']>;
  useForChatNotifications?: Maybe<Scalars['Boolean']['output']>;
};

export type UserEmailInput = {
  email: Scalars['String']['input'];
  useForNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserFilter = {
  eq?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  oldRoleId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  policyId?: InputMaybe<Scalars['Int']['input']>;
  userTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UserParameter = {
  __typename?: 'UserParameter';
  agent?: Maybe<Agent>;
  baseModule?: Maybe<Module>;
  chatAlias?: Maybe<ChatAlias>;
  commentCssClass?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  driverMode?: Maybe<DriverMode>;
  expireAccount?: Maybe<Scalars['String']['output']>;
  expirePassword?: Maybe<Scalars['Int']['output']>;
  expirePermission?: Maybe<Scalars['Int']['output']>;
  expireToken?: Maybe<Scalars['Int']['output']>;
  freightPaymentCountry?: Maybe<Country>;
  guid1C?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isChangePassword?: Maybe<Scalars['Boolean']['output']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<LanguageEnum>;
  oldRole?: Maybe<UserRole>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Policy>;
  smsPasswordChangedAt?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType?: Maybe<TypeOfUser>;
};

export type UserProfileUpdateInput = {
  emails?: InputMaybe<Array<UserEmailInput>>;
  language: LanguageEnum;
  timezone: Scalars['String']['input'];
};

export type UserProfileUpdateOutput = {
  __typename?: 'UserProfileUpdateOutput';
  error?: Maybe<MutationError>;
};

/** Рейтинг */
export enum UserRatingEnum {
  Gold = 'gold',
  Newbie = 'newbie',
  Silver = 'silver'
}

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  sync1C?: Maybe<Scalars['Boolean']['output']>;
  systemCreatedAt?: Maybe<Scalars['String']['output']>;
};

export type UserShortInfo = {
  __typename?: 'UserShortInfo';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserType = {
  __typename?: 'UserType';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UsersFilter = {
  user?: InputMaybe<UserFilter>;
};

export type UsersPaginatedList = {
  __typename?: 'UsersPaginatedList';
  nodes: Array<User>;
  total: Scalars['Int']['output'];
};

export type Vehicle = {
  __typename?: 'Vehicle';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentKm?: Maybe<VehicleKm>;
  driver?: Maybe<Driver>;
  employees?: Maybe<Array<Maybe<User>>>;
  etcCard?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  fuelCard?: Maybe<Scalars['String']['output']>;
  fuelTankVolume?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  maker?: Maybe<Maker>;
  mark?: Maybe<Mark>;
  name?: Maybe<Scalars['String']['output']>;
  plate?: Maybe<Scalars['String']['output']>;
  technicalInspectionExpirationDate?: Maybe<Scalars['String']['output']>;
  useType?: Maybe<TypeOfUseVehicle>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type VehicleKm = {
  __typename?: 'VehicleKm';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']['output']>;
  km?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
};

export type Vessel = {
  __typename?: 'Vessel';
  id?: Maybe<Scalars['Int']['output']>;
  isRoro?: Maybe<Scalars['Boolean']['output']>;
  lastNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numbers?: Maybe<Array<Scalars['String']['output']>>;
  vesselType?: Maybe<VesselTypeInfo>;
};

export type VesselCreateInput = {
  name: Scalars['String']['input'];
};

export type VesselCreateOutput = {
  __typename?: 'VesselCreateOutput';
  error?: Maybe<MutationError>;
};

export type VesselInfo = {
  __typename?: 'VesselInfo';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VesselInfoCalendar = {
  __typename?: 'VesselInfoCalendar';
  backgroundColorEvent?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VesselTypeInfo = {
  __typename?: 'VesselTypeInfo';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameEng?: Maybe<Scalars['String']['output']>;
};

export type VesselUpdateInput = {
  id: Scalars['Int']['input'];
  type: Scalars['Int']['input'];
};

export type VesselUpdateOutput = {
  __typename?: 'VesselUpdateOutput';
  error?: Maybe<MutationError>;
};

/** Типы инспекции */
export enum VideoWatchTypeEnum {
  /** С аукциона USS TOKYO */
  Auction = 'AUCTION',
  /** С аукциона USS Yokohama */
  AuctionYokohama = 'AUCTION_YOKOHAMA'
}

export type ViewChatInput = {
  chatId: Scalars['Int']['input'];
};

export type ViewChatOutput = {
  __typename?: 'ViewChatOutput';
  error?: Maybe<MutationError>;
};

export type Watch = {
  __typename?: 'Watch';
  airbagExploded?: Maybe<Scalars['Boolean']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
  colorObject?: Maybe<Color>;
  commentWatch?: Maybe<Scalars['String']['output']>;
  countKeys?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  emptyBattery?: Maybe<Scalars['Boolean']['output']>;
  engine?: Maybe<Scalars['String']['output']>;
  engineCapacityL?: Maybe<Scalars['Float']['output']>;
  engineModel?: Maybe<Scalars['String']['output']>;
  enginePowerKwh?: Maybe<Scalars['Float']['output']>;
  engineType?: Maybe<TypeOfEngine>;
  engineVolumeCm3?: Maybe<Scalars['Float']['output']>;
  explodeDate?: Maybe<Scalars['DateTime']['output']>;
  explodeReportPrintedAt?: Maybe<Scalars['DateTime']['output']>;
  explodedBy?: Maybe<User>;
  freonRemoved?: Maybe<Scalars['Boolean']['output']>;
  glonassIdentifier?: Maybe<Scalars['String']['output']>;
  glonassModel?: Maybe<Scalars['String']['output']>;
  hasSdCard?: Maybe<Scalars['Boolean']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAcceptSecurity?: Maybe<Scalars['Boolean']['output']>;
  isBroken?: Maybe<Scalars['Boolean']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  isDrivable?: Maybe<Scalars['Boolean']['output']>;
  isNeedEngineWatch?: Maybe<Scalars['Boolean']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  loadCapacity?: Maybe<Scalars['Float']['output']>;
  maker?: Maybe<Maker>;
  makerColor?: Maybe<MakerColor>;
  /** Страна производства */
  manufactureCountry?: Maybe<Country>;
  mark?: Maybe<Mark>;
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Model>;
  noFuel?: Maybe<Scalars['Boolean']['output']>;
  permittedMaximumWeightSbkts?: Maybe<Scalars['Int']['output']>;
  recycleNumber?: Maybe<Scalars['String']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  transmission?: Maybe<Transmission>;
  user?: Maybe<User>;
  watchCarType?: Maybe<TypeOfWatchCar>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightFull?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type WatchCarUpdateInput = {
  attachments?: InputMaybe<Scalars['String']['input']>;
  carTypeId: Scalars['Int']['input'];
  chassisTypeId: Scalars['Int']['input'];
  colorId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  complete: Scalars['Boolean']['input'];
  engineModel?: InputMaybe<Scalars['String']['input']>;
  engineNumber?: InputMaybe<Scalars['String']['input']>;
  engineTypeId: Scalars['Int']['input'];
  height: Scalars['Int']['input'];
  isBroken?: InputMaybe<Scalars['Boolean']['input']>;
  length: Scalars['Int']['input'];
  loadCapacity: Scalars['Float']['input'];
  makerId: Scalars['Int']['input'];
  markId: Scalars['Int']['input'];
  mediaKeysS3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectId: Scalars['Int']['input'];
  sawId?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
  vin: Scalars['String']['input'];
  weight: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type WatchCarUpdateOutput = {
  __typename?: 'WatchCarUpdateOutput';
  error?: Maybe<MutationError>;
};

export type WatchCommentTemplate = {
  __typename?: 'WatchCommentTemplate';
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type WayPoint = {
  __typename?: 'WayPoint';
  city?: Maybe<CityInfo>;
  date?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
};

export type Wheel = {
  __typename?: 'Wheel';
  /** Объект с которого сняли колеса */
  disassembleObject?: Maybe<ObjectOfServices>;
  /** Производитель диска */
  diskManufacturer?: Maybe<Scalars['String']['output']>;
  /** Тип диска */
  diskType?: Maybe<Scalars['String']['output']>;
  /** ID обьекта */
  id?: Maybe<Scalars['Int']['output']>;
  /** Собран на полете */
  isLoaded?: Maybe<Scalars['Boolean']['output']>;
  /** Куплен */
  isPurchased?: Maybe<Scalars['Boolean']['output']>;
  /** Палета */
  pallet?: Maybe<Pallet>;
  /** Цена */
  price?: Maybe<Price>;
  /** Производитель резины */
  tireManufacturer?: Maybe<Scalars['String']['output']>;
  /** Высота */
  tireProfileHeight?: Maybe<Scalars['Int']['output']>;
  /** Ширина */
  tireProfileWidth?: Maybe<Scalars['Int']['output']>;
  /** Радиус */
  tireRadius?: Maybe<Scalars['Int']['output']>;
  /** Тип резины */
  tireType?: Maybe<Scalars['String']['output']>;
  /** Процент износа резины */
  tireWear?: Maybe<Scalars['Int']['output']>;
};

export type WheelAllsFilter = {
  wheel?: InputMaybe<WheelFilter>;
};

export type WheelAllsPaginatedList = {
  __typename?: 'WheelAllsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export type WheelBuyInput = {
  objectId: Scalars['Int']['input'];
};

export type WheelBuyOutput = {
  __typename?: 'WheelBuyOutput';
  error?: Maybe<MutationError>;
};

export type WheelCreateInput = {
  count: Scalars['Int']['input'];
  diskManufacturer: Scalars['String']['input'];
  diskType: DiskTypeEnum;
  fotos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectId: Scalars['Int']['input'];
  tireManufacturer: Scalars['String']['input'];
  tireProfileHeight: Scalars['Int']['input'];
  tireProfileWidth: Scalars['Int']['input'];
  tireRadius: Scalars['Int']['input'];
  tireType: TireTypeEnum;
  tireWear: Scalars['Int']['input'];
};

export type WheelCreateOutput = {
  __typename?: 'WheelCreateOutput';
  error?: Maybe<MutationError>;
};

export type WheelFilter = {
  /** Купленны */
  asPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  /** Производитель диска */
  diskManufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Тип диска */
  diskType?: InputMaybe<Array<InputMaybe<DiskTypeEnum>>>;
  /** Производитель резины */
  tireManufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Высота резины максимум */
  tireProfileHeightMax?: InputMaybe<Scalars['Int']['input']>;
  /** Высота резины минимум */
  tireProfileHeightMin?: InputMaybe<Scalars['Int']['input']>;
  /** Ширина резины максимум */
  tireProfileWidthMax?: InputMaybe<Scalars['Int']['input']>;
  /** Ширина резины минимум */
  tireProfileWidthMin?: InputMaybe<Scalars['Int']['input']>;
  /** Радиус максимум */
  tireRadiusMax?: InputMaybe<Scalars['Int']['input']>;
  /** Радиус минимум */
  tireRadiusMin?: InputMaybe<Scalars['Int']['input']>;
  /** Тип резины */
  tireType?: InputMaybe<Array<InputMaybe<TireTypeEnum>>>;
  /** Процент износа резины максимум */
  tireWearMax?: InputMaybe<Scalars['Int']['input']>;
  /** Процент износа резины минимум */
  tireWearMin?: InputMaybe<Scalars['Int']['input']>;
  /** Без цены */
  withoutPrice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WheelFilterValue = {
  __typename?: 'WheelFilterValue';
  /** Список производителей дисков */
  diskManufacturer?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Список типов дисков */
  diskType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Список производителей резины */
  tireManufacturer?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Список размеров по высоте резины */
  tireProfileHeight?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Список размеров по ширине резины */
  tireProfileWidth?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Список радиусов */
  tireRadius?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Список типов резины */
  tireType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type WheelRemoveFromPalletInput = {
  objectId: Scalars['Int']['input'];
};

export type WheelRemoveFromPalletOutput = {
  __typename?: 'WheelRemoveFromPalletOutput';
  error?: Maybe<MutationError>;
};

export type WheelSetIsLoadedInput = {
  objectId: Scalars['Int']['input'];
};

export type WheelSetIsLoadedOutput = {
  __typename?: 'WheelSetIsLoadedOutput';
  error?: Maybe<MutationError>;
};

export type WheelSetPriceInput = {
  objectId: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
};

export type WheelSetPriceOutput = {
  __typename?: 'WheelSetPriceOutput';
  error?: Maybe<MutationError>;
};

export type WheelStatistic = {
  __typename?: 'WheelStatistic';
  /** Колес купленно */
  wheelBuyCount?: Maybe<Scalars['Int']['output']>;
  /** Колес упакованно на складе */
  wheelPackedCount?: Maybe<Scalars['Int']['output']>;
  /** Колес на складе */
  wheelStockCount?: Maybe<Scalars['Int']['output']>;
  /** Колес не упакованно на складе  */
  wheelUnpackedCount?: Maybe<Scalars['Int']['output']>;
};

export type WheelsFilter = {
  wheel?: InputMaybe<WheelFilter>;
};

export type WheelsPaginatedList = {
  __typename?: 'WheelsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export type WithUserMessagesFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WithoutMediaObjectsPaginatedList = {
  __typename?: 'WithoutMediaObjectsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int']['output'];
};

export enum YahooAccountEnum {
  Akebono429 = 'akebono429',
  Eoosh66991 = 'eoosh66991',
  Ikyib99865 = 'ikyib99865',
  Magadanshop = 'magadanshop'
}

export enum YahooLotDeliveryServiceEnum {
  Other = 'other',
  Sagawa = 'sagawa',
  Seino = 'seino',
  Yamato = 'yamato',
  YuMail = 'yu_mail',
  YuPack = 'yu_pack'
}

export type YahooLotError = {
  __typename?: 'YahooLotError';
  message?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum YahooLotResubmitRatioEnum {
  V_0 = 'v_0',
  V_5 = 'v_5',
  V_10 = 'v_10',
  V_15 = 'v_15',
  V_20 = 'v_20',
  V_25 = 'v_25',
  V_30 = 'v_30'
}

export enum YahooLotShipScheduleEnum {
  OneTwo = 'one_two',
  ThreeSeven = 'three_seven',
  TwoThree = 'two_three'
}

export type YahooLotShipSizes = {
  __typename?: 'YahooLotShipSizes';
  isJpYupackOfficialShip?: Maybe<Scalars['Boolean']['output']>;
  isJpYupacketOfficialShip?: Maybe<Scalars['Boolean']['output']>;
  isYahunekoNekoposuShip?: Maybe<Scalars['Boolean']['output']>;
  isYahunekoTaqbinBigShip?: Maybe<Scalars['Boolean']['output']>;
  isYahunekoTaqbinCompactShip?: Maybe<Scalars['Boolean']['output']>;
  isYahunekoTaqbinShip?: Maybe<Scalars['Boolean']['output']>;
};

export enum YahooLotShippingEnum {
  Buyer = 'buyer',
  Seller = 'seller'
}

export enum YahooLotShippingInputEnum {
  Arrival = 'arrival',
  Later = 'later'
}

export type YahooOwner = {
  __typename?: 'YahooOwner';
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isHasBlockedObjects?: Maybe<Scalars['Boolean']['output']>;
  isHasOldDebts?: Maybe<Scalars['Boolean']['output']>;
  isHasPenalty?: Maybe<Scalars['Boolean']['output']>;
  isUnfairPartner?: Maybe<Scalars['Boolean']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type YahooOwnersPaginatedList = {
  __typename?: 'YahooOwnersPaginatedList';
  nodes: Array<YahooOwner>;
  total: Scalars['Int']['output'];
};

export enum YahooStateLotEnum {
  New = 'new',
  Used10 = 'used10',
  Used20 = 'used20',
  Used40 = 'used40',
  Used60 = 'used60',
  Used80 = 'used80'
}

export type DonateBuhankaMutationVariables = Exact<{
  input?: InputMaybe<DonateBuhankaInput>;
}>;


export type DonateBuhankaMutation = { __typename?: 'Mutation', donateBuhanka?: { __typename?: 'DonateBuhankaOutput', error?: { __typename?: 'MutationError', code?: string | null, message?: string | null } | null } | null };

export type DonateBuhankaStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type DonateBuhankaStatusQuery = { __typename?: 'Query', donateBuhankaStatus?: { __typename?: 'DonateBuhanka', total?: number | null, collected?: number | null } | null };

export const DonateBuhankaDocument = gql`
    mutation donateBuhanka($input: DonateBuhankaInput) {
  donateBuhanka(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DonateBuhankaGQL extends Apollo.Mutation<DonateBuhankaMutation, DonateBuhankaMutationVariables> {
    override document = DonateBuhankaDocument;
    override client = 'shared/familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DonateBuhankaStatusDocument = gql`
    query donateBuhankaStatus {
  donateBuhankaStatus {
    total
    collected
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DonateBuhankaStatusGQL extends Apollo.Query<DonateBuhankaStatusQuery, DonateBuhankaStatusQueryVariables> {
    override document = DonateBuhankaStatusDocument;
    override client = 'shared/familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DeliveryUnion": [
      "DeliveryRequest",
      "ObjectDelivered"
    ],
    "MediaUnion": [
      "ObjectMediaItem",
      "S3File"
    ],
    "SearchResult": [
      "Invoice",
      "InvoiceCustom",
      "ObjectOfServices"
    ]
  }
};
      export default result;
    