import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DeliveryStatisticsStepModalComponent } from '../delivery-statistics-step-modal/delivery-statistics-step-modal.component';

@Component({
  selector: 'app-delivery-statistics-step-icon',
  templateUrl: './delivery-statistics-step-icon.component.html',
  styleUrl: './delivery-statistics-step-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryStatisticsStepIconComponent {
  @Input({ required: true }) icon!: string;
  @Input() descriptionTranslationKey!: string;
  @Input() videoUrl!: string;

  constructor(
    private nzModalService: NzModalService,
    private translate: TranslateService,
  ) {}

  openInfoModal(): void {
    this.nzModalService.create({
      nzTitle: this.translate.instant(
        `DELIVERY_STATISTICS_SECTION.STATISTICS_STEP.${this.descriptionTranslationKey}`,
      ),
      nzContent: DeliveryStatisticsStepModalComponent,
      nzWidth: '55%',
      nzFooter: null,
      nzData: {
        description: this.descriptionTranslationKey,
        videoUrl: this.videoUrl,
      },
      nzClassName: 'custom-modal-title',
      nzStyle: { 'max-width': '800px' },
      nzCentered: true,
    });
  }
}
