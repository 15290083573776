import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A valid DateTime, transported as a iso8601 string */
  DateTime: { input: any; output: any; }
  /** A secure, escaped string! */
  SecureString: { input: any; output: any; }
};

export type AutoCalculatorParamFilter = {
  calculatorType?: InputMaybe<CalculatorTypeFilter>;
};

export type CalculatorTypeFilter = {
  eq?: InputMaybe<Array<TypeEnum>>;
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum CurrencyEnum {
  /** Euro */
  Eur = 'EUR',
  /** Japan Yen */
  Jpy = 'JPY',
  /** Russian Ruble */
  Rub = 'RUB',
  /** US Dollar */
  Usd = 'USD'
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo';
  buy?: Maybe<Scalars['Float']['output']>;
  centralBank?: Maybe<Scalars['Float']['output']>;
  currency: CurrencyEnum;
  date: Scalars['DateTime']['output'];
  nominal: Scalars['Float']['output'];
  sell?: Maybe<Scalars['Float']['output']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  countryIso?: Maybe<Scalars['String']['output']>;
  hasPermission?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isOtherCountries?: Maybe<Scalars['Boolean']['output']>;
  officeBranch?: Maybe<OfficeBranch>;
  plan?: Maybe<Plan>;
};


export type CurrentUserHasPermissionArgs = {
  code: Scalars['String']['input'];
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  date: Scalars['DateTime']['output'];
  nominal: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type OfficeBranch = {
  __typename?: 'OfficeBranch';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isWorldwide?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Param = {
  __typename?: 'Param';
  calculatorType?: Maybe<TypeEnum>;
  constant?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paramGroupConditions?: Maybe<Array<ParamGroupCondition>>;
  parameter?: Maybe<ParamEnum>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ParamCondition = {
  __typename?: 'ParamCondition';
  compare?: Maybe<ParamConditionCompareEnum>;
  conditionType?: Maybe<ParamConditionTypeEnum>;
  id?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ParamConditionCompareEnum {
  Amount = 'amount',
  AmountRub = 'amount_rub',
  AuctionName = 'auction_name',
  BranchOffice = 'branch_office',
  CargoVolume = 'cargo_volume',
  CartrackAuctionId = 'cartrack_auction_id',
  EnginePower = 'engine_power',
  EngineVolume = 'engine_volume',
  Inspection = 'inspection',
  InstallGlonass = 'install_glonass',
  IsHybrid = 'is_hybrid',
  Plan = 'plan',
  Year = 'year'
}

export enum ParamConditionTypeEnum {
  Equally = 'equally',
  Less = 'less',
  More = 'more'
}

export enum ParamEnum {
  AutomobileBranchFee = 'automobile_branch_fee',
  AutomobileCustomClearanceIndividual = 'automobile_custom_clearance_individual',
  AutomobileCustomClearanceLegalEntity = 'automobile_custom_clearance_legal_entity',
  AutomobileCustomPayment = 'automobile_custom_payment',
  AutomobileCustomPaymentPrimbrokerCoefficient = 'automobile_custom_payment_primbroker_coefficient',
  AutomobileCustomsFeeToPrice = 'automobile_customs_fee_to_price',
  AutomobileExciseLegalEntity = 'automobile_excise_legal_entity',
  AutomobileFreight = 'automobile_freight',
  AutomobileGlonassInstallation = 'automobile_glonass_installation',
  AutomobileIndividualBankTransferRation = 'automobile_individual_bank_transfer_ration',
  AutomobileIndividualPercentCoefficient = 'automobile_individual_percent_coefficient',
  AutomobileIndividualVolumeCoefficient = 'automobile_individual_volume_coefficient',
  AutomobileLegalEntityBankTransferRation = 'automobile_legal_entity_bank_transfer_ration',
  AutomobileLegalEntityEnginePower = 'automobile_legal_entity_engine_power',
  AutomobileLegalEntityPercentCoefficient = 'automobile_legal_entity_percent_coefficient',
  AutomobileLegalEntityVolumeCoefficient = 'automobile_legal_entity_volume_coefficient',
  AutomobileMaximalAuctionPrice = 'automobile_maximal_auction_price',
  AutomobileMaximalEnginePower = 'automobile_maximal_engine_power',
  AutomobileMaximalEngineVolume = 'automobile_maximal_engine_volume',
  AutomobileMinimalAuctionPrice = 'automobile_minimal_auction_price',
  AutomobileMinimalEnginePower = 'automobile_minimal_engine_power',
  AutomobileMinimalEngineVolume = 'automobile_minimal_engine_volume',
  AutomobileMinimalManifacturedYear = 'automobile_minimal_manifactured_year',
  AutomobilePercentageOfCost = 'automobile_percentage_of_cost',
  AutomobilePlanCommission = 'automobile_plan_commission',
  AutomobilePurchaseCommission = 'automobile_purchase_commission',
  AutomobileUtilsborBasic = 'automobile_utilsbor_basic',
  AutomobileUtilsborIndividual = 'automobile_utilsbor_individual',
  AutomobileUtilsborIndividualHighest = 'automobile_utilsbor_individual_highest',
  AutomobileUtilsborLegalEntity = 'automobile_utilsbor_legal_entity',
  ElectroAutoCustomClearanceGlonass = 'electro_auto_custom_clearance_glonass',
  ElectroAutoCustomPaymentInRubles = 'electro_auto_custom_payment_in_rubles',
  ElectroAutoEnginePower = 'electro_auto_engine_power',
  ElectroAutoFeeInRubles = 'electro_auto_fee_in_rubles',
  ElectroAutoMaximalAuctionPrice = 'electro_auto_maximal_auction_price',
  ElectroAutoMinimalAuctionPrice = 'electro_auto_minimal_auction_price',
  ElectroAutoMinimalEnginePower = 'electro_auto_minimal_engine_power',
  ElectroAutoMinimalManifacturedYear = 'electro_auto_minimal_manifactured_year',
  ElectroAutoNds = 'electro_auto_nds',
  ElectroAutoUtilsbor = 'electro_auto_utilsbor',
  ElectroBranchFee = 'electro_branch_fee',
  ElectroCustomPaymentPrimbrokerCoefficient = 'electro_custom_payment_primbroker_coefficient',
  ElectroFreight = 'electro_freight',
  ElectroPercentageOfCost = 'electro_percentage_of_cost',
  ElectroPlanCommission = 'electro_plan_commission',
  ElectroPurchaseCommission = 'electro_purchase_commission',
  ExciseDutyRateGasoline = 'excise_duty_rate_gasoline',
  ExciseDutyRateHybrid = 'excise_duty_rate_hybrid',
  MongoliaPurchaseCommission = 'mongolia_purchase_commission',
  MotoAuctionFee = 'moto_auction_fee',
  MotoBrokerFee = 'moto_broker_fee',
  MotoCustomsFee = 'moto_customs_fee',
  MotoCustomsFeeToPriceIndividual = 'moto_customs_fee_to_price_individual',
  MotoCustomsFeeToPriceLegalEntity = 'moto_customs_fee_to_price_legal_entity',
  MotoDealerFee = 'moto_dealer_fee',
  MotoDelivery = 'moto_delivery',
  MotoExciseRate = 'moto_excise_rate',
  MotoFreight = 'moto_freight',
  MotoImportDuty = 'moto_import_duty',
  MotoPercentageOfCost = 'moto_percentage_of_cost',
  MotoPlanCommission = 'moto_plan_commission',
  MotoRussiaPurchaseCommission = 'moto_russia_purchase_commission',
  MotoVatRate = 'moto_vat_rate',
  PartsAmericanEuropeanCarModels = 'parts_american_european_car_models',
  PartsBranchFee = 'parts_branch_fee',
  PartsFreight = 'parts_freight',
  PartsFreightPalletOneCar = 'parts_freight_pallet_one_car',
  PartsFreightPalletTwoCar = 'parts_freight_pallet_two_car',
  PartsMaximalAuctionPrice = 'parts_maximal_auction_price',
  PartsMinimalAuctionPrice = 'parts_minimal_auction_price',
  PartsPalletingOneCar = 'parts_palleting_one_car',
  PartsPalletingTwoCar = 'parts_palleting_two_car',
  PartsPercentageOfCost = 'parts_percentage_of_cost',
  PartsPlanCommission = 'parts_plan_commission',
  PartsPurchaseCommission = 'parts_purchase_commission',
  PartsSawAdditional = 'parts_saw_additional',
  PartsSawBase = 'parts_saw_base',
  WorldWideCargoVolume = 'world_wide_cargo_volume',
  WorldWideCifInYen = 'world_wide_cif_in_yen',
  WorldWideInspectionCost = 'world_wide_inspection_cost',
  WorldWidePurchaseCommission = 'world_wide_purchase_commission'
}

export type ParamGroupCondition = {
  __typename?: 'ParamGroupCondition';
  id?: Maybe<Scalars['ID']['output']>;
  paramConditions?: Maybe<Array<ParamCondition>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ParamPaginatedList = {
  __typename?: 'ParamPaginatedList';
  nodes: Array<Param>;
  total: Scalars['Int']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['SecureString']['output']>;
};

export type Query = {
  __typename?: 'Query';
  altecarExchangeRate?: Maybe<ExchangeRate>;
  atbExchangeRate?: Maybe<CurrencyInfo>;
  countries: Array<Country>;
  currentUser?: Maybe<CurrentUser>;
  exchangeRate: ExchangeRate;
  khanbankExchangeRate?: Maybe<CurrencyInfo>;
  maybankExchangeRate: CurrencyInfo;
  param?: Maybe<Param>;
  params?: Maybe<ParamPaginatedList>;
  primbankExchangeRate?: Maybe<CurrencyInfo>;
  pskbExchangeRate?: Maybe<CurrencyInfo>;
  stripeExchangeRate?: Maybe<ExchangeRate>;
};


export type QueryAltecarExchangeRateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  iso?: InputMaybe<CurrencyEnum>;
};


export type QueryAtbExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryExchangeRateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  iso: CurrencyEnum;
};


export type QueryKhanbankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryMaybankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryParamArgs = {
  parameter: ParamEnum;
};


export type QueryParamsArgs = {
  filter?: InputMaybe<AutoCalculatorParamFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPrimbankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryPskbExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryStripeExchangeRateArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  iso?: InputMaybe<CurrencyEnum>;
};

export enum TypeEnum {
  Automobile = 'automobile',
  ElectroAuto = 'electro_auto',
  Hybrid = 'hybrid',
  MongoliaAuto = 'mongolia_auto',
  Motorcycle = 'motorcycle',
  Parts = 'parts',
  WorldWide = 'world_wide'
}

export type AutoFreightQueryVariables = Exact<{ [key: string]: never; }>;


export type AutoFreightQuery = { __typename?: 'Query', automobileFreight?: { __typename?: 'Param', constant?: string | null, id?: string | null } | null };

export const AutoFreightDocument = gql`
    query autoFreight {
  automobileFreight: param(parameter: automobile_freight) {
    constant
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoFreightGQL extends Apollo.Query<AutoFreightQuery, AutoFreightQueryVariables> {
    override document = AutoFreightDocument;
    override client = 'calculator';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    