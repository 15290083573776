import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { combineLatest, map, startWith } from 'rxjs';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';

@Component({
  selector: 'app-calculator-link',
  templateUrl: './calculator-link.component.html',
  styleUrl: './calculator-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculatorLinkComponent {
  @HostBinding('style.display') display = 'none';

  constructor(currentUserService: CurrentUserService, private router: Router) {
    const isAllowedUserCountry$ = currentUserService.currentUser$.pipe(
      map((user) => {
        const countryIso = user?.countryIso?.toLowerCase();
        return countryIso === 'ru' || countryIso === 'mn';
      }),
    );

    const isCalculatorPageRoute$ = this.router.events.pipe(
      startWith(null),
      map(() => this.router.url.includes('calculator')),
    );

    combineLatest([isAllowedUserCountry$, isCalculatorPageRoute$])
      .pipe(
        map(([isAllowedCountry, isCalculatorPage]) => isAllowedCountry && !isCalculatorPage),
        takeUntilDestroyed(),
      )
      .subscribe((canRender) => {
        this.display = canRender ? 'block' : 'none';
      });
  }
}
