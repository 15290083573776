import {
  AkebonoLotSource,
  AkebonoLotTypeEnum,
} from '../../../graphql/akebono-main-graphql.service';
import { MochironLotMinimalFragment } from '../../../graphql/mochiron-graphql.service';
import { lotTypeByDepartmentId } from '../constants/lot-type-by-department-id';
import { LotSource } from '../enums/lot-source.enum';
import { LotType } from '../enums/lot-type.enum';
import { ILotMinimalBase } from '../interfaces/lot-minimal-base.interface';
import { ILotMinimal } from '../interfaces/lot-minimal.interface';
import { Location } from '../types/location.type';
import { MediaItem } from '../types/media-item.type';
import { Value } from '../types/value.type';
import { LotAdapterUtilities } from '../utilities/lot-adapter.utilities';

export class MochironLotMinimalAdapter implements ILotMinimal {
  id?: string | undefined;
  media?: MediaItem[] | undefined;
  description?: string | undefined;
  phone?: string | undefined;
  hasWhatsApp?: boolean | undefined;
  hasTelegram?: boolean | undefined;
  maker?: string | undefined;
  mark?: string | undefined;
  model?: string | undefined;
  grade?: string | undefined;
  year?: number | undefined;
  engineVolume?: Value | undefined;
  transmission?: string | undefined;
  fuel?: string | undefined;
  bodyType?: string | undefined;
  drive?: string | undefined;
  rating?: string | undefined;
  price?: Value | undefined;
  priceInStock: Value | undefined;
  priceInJapan: Value | undefined;
  priceOnDelivery?: Value | undefined;
  mileage?: Value | undefined;
  color?: string | undefined;
  location?: string | undefined;
  vin?: string | undefined;
  accident?: string | undefined;
  isSold?: boolean | undefined;
  type: LotType;
  source: LotSource;
  akebonoLotType: AkebonoLotTypeEnum;
  akebonoLotSource: AkebonoLotSource;
  fields: Set<keyof ILotMinimalBase>;

  constructor(lot: MochironLotMinimalFragment) {
    this.type = this.departmentIdToLotType(lot);
    this.source = LotSource.Mochiron;
    this.akebonoLotType =
      <AkebonoLotTypeEnum>lot.akebonoLotSourceSubgroup || AkebonoLotTypeEnum.Oneprice;
    this.akebonoLotSource =
      <AkebonoLotSource>lot.akebonoLotSourceProvider || AkebonoLotSource.Mochiron;

    this.id = lot.id.toString();
    this.media = this.normalizeMedia(lot.images);
    this.maker = lot.companyEn || undefined;
    this.mark = lot.modelEn || undefined;
    this.model = lot.modelTypeEn || undefined;
    this.grade = lot.modelGradeEn || undefined;
    this.year = lot.year || undefined;
    this.engineVolume = LotAdapterUtilities.buildValue(lot.engineVolumeNum, 'cc');
    this.transmission = lot.transmissionEn || undefined;
    this.rating = lot.ratingEn || undefined;
    this.price = this.getPriceByLocation(lot);
    this.priceInStock = this.normalizePriceInStock(lot);
    this.priceInJapan = this.normalizePriceInJP(lot);
    this.priceOnDelivery = this.normalizePriceOnDelivery(lot);
    this.mileage = this.normalizeMileage(lot);
    this.color = lot.colorEn || undefined;
    this.location = lot.location?.replaceAll('_', ' ') || undefined;
    this.vin = lot.vin || undefined;
    this.isSold = lot.isSold || false;
    this.phone = lot.phone || undefined;
    this.hasTelegram = lot.hasTelegram || undefined;
    this.hasWhatsApp = lot.hasWhatsapp || undefined;
    this.description = lot.userDescription || undefined;
    this.fields = new Set([
      'id',
      'media',
      'maker',
      'mark',
      'model',
      'grade',
      'year',
      'engineVolume',
      'transmission',
      'rating',
      'price',
      'priceInStock',
      'priceInJapan',
      'priceOnDelivery',
      'mileage',
      'color',
      'location',
      'vin',
      'isSold',
      'description',
      'hasTelegram',
      'hasWhatsApp',
    ]);
  }

  private departmentIdToLotType(lot: MochironLotMinimalFragment): LotType {
    if (lot.departmentId) {
      const lotType = lotTypeByDepartmentId[lot.departmentId];
      if (lotType) {
        return lotType;
      }
    }

    throw new Error(`Unsupported Department id '${lot.departmentId}'`);
  }

  private normalizeMileage(lot: MochironLotMinimalFragment): Value | undefined {
    const value = LotAdapterUtilities.buildValue(
      lot.mileageNum,
      lot.location?.replaceAll('_', ' ') === LotType.Usa ? 'mile' : 'km',
    );
    if (value) {
      return {
        ...value,
        value: value.value * 1000,
      };
    } else {
      return undefined;
    }
  }

  private normalizePriceInJP(lot: MochironLotMinimalFragment): Value | undefined {
    return this.createPriceValue(lot.priceInJapan, lot.priceInJapanCurrency);
  }

  private normalizePriceOnDelivery(lot: MochironLotMinimalFragment): Value | undefined {
    return this.createPriceValue(lot.priceOnDelivery, lot.priceOnDeliveryCurrency);
  }

  private normalizePriceInStock(lot: MochironLotMinimalFragment): Value | undefined {
    return this.createPriceValue(lot.priceInStock, lot.priceInStockCurrency);
  }

  private createPriceValue(
    value: number | null | undefined,
    currency: string | null | undefined,
  ): Value | undefined {
    if (value != null && currency != null) {
      return {
        value,
        unit: currency,
      };
    }
    return undefined;
  }

  getPriceByLocation(lot: MochironLotMinimalFragment): Value | undefined {
    switch (this.location?.toLowerCase()) {
      case Location.MONGOLIA:
      case Location.ULAANBAATAR:
      case Location.ZAMIIN_UUD:
        return this.normalizePriceInStock(lot);
      case Location.TRANSPORTING:
        return this.normalizePriceOnDelivery(lot);
      default:
        return this.normalizePriceInJP(lot);
    }
  }

  private normalizeMedia(
    sourceImages: MochironLotMinimalFragment['images'],
  ): MediaItem[] | undefined {
    if (!sourceImages) {
      return undefined;
    }

    const images = sourceImages.split('#');
    return LotAdapterUtilities.normalizeMedia(images);
  }
}
