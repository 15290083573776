<div class="categories-scroll-container">
  <nz-row [nzGutter]="[15, 15]" nzJustify="start" class="categories-row">
    @for (lotType of availableLotTypes; track lotType) {
    <nz-col>
      <a
        [routerLink]="['/', lotType | lowercase, 'lots']"
        routerLinkActive="active"
        app-button
        appType="primary"
        shape="round"
        [class.active]="activeLotType === lotType"
      >
        <span>{{ 'AUCTION.LINK_CATEGORIES.' + lotType | uppercase | translate }}</span>
      </a>
    </nz-col>
    }
  </nz-row>
<div class="arrow"><span>›››</span></div>
</div>

