import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { CurrentUserService } from 'src/app/services/current-user/current-user.service';
import { LanguageService } from 'src/app/services/language.service';
import { SizeService } from 'src/app/services/size/size.service';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { LayoutConfigService } from './services/layout-config.service';
import { PageTitleService } from 'src/app/services/page-title/page-title.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  providers: [LayoutConfigService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  hasPaddings$: Observable<boolean>;
  hasWidthLimit$: Observable<boolean>;
  canRenderTelegram$: Observable<boolean>;
  hideMainContactsText$: Observable<boolean>;

  constructor(
    sizeService: SizeService,
    private drawerService: NzDrawerService,
    layoutConfigService: LayoutConfigService,
    languageService: LanguageService,
    currentUserService: CurrentUserService,
    private pageTitle: PageTitleService,
  ) {
    this.hasPaddings$ = layoutConfigService.hasPaddings$;
    this.hasWidthLimit$ = layoutConfigService.hasWidthLimit$;
    this.canRenderTelegram$ = combineLatest([
      currentUserService.isRussian$,
      languageService.currentLanguage$,
    ]).pipe(
      map(([isRussian, language]) => isRussian || language === 'ru'),
      startWith(false),
    );

    this.hideMainContactsText$ = sizeService.isTablet$;
  }

  resetPageTitle(): void {
    this.pageTitle.setTitle('Akebono Co.,Ltd - Japanese auto auctions, worldwide delivery');
  }

  openMobileMenu(): void {
    this.drawerService.create({
      nzContent: MobileMenuComponent,
      nzPlacement: 'left',
      nzWidth: 358,
      nzClosable: false,
      nzBodyStyle: { padding: 0 },
    });
  }
}
