import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatorPageComponent } from './calculator-page.component';
import { CalculatorModule } from '@akebono/calculator';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CalculatorPageComponent],
  imports: [CommonModule, CalculatorModule, TranslateModule],
})
export class CalculatorPageModule {}
