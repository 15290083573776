import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, combineLatest, tap} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ArticleFragment, ArticleQuery} from "../../../../graphql/content-auto-graphql.service";
import {LanguageService} from "../../../../services/language.service";
import {Language} from "../../../../utilities/types/language.type";
import {InformationService} from "../../../../services/information/information.service";
import {PageTitleService} from "../../../../services/page-title/page-title.service";

@Component({
  selector: 'app-information-page',
  templateUrl: './information-page.component.html',
  styleUrl: './information-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationPageComponent {
  language$: Observable<Language>;
  article$: Observable<ArticleFragment | undefined | null>;
  loading$: Observable<boolean> | undefined;

  countryInformationType: 'cis' | 'other' = 'cis';
  articlePage = '';

  constructor(
    private languageService: LanguageService,
    private title: PageTitleService,
    private route: ActivatedRoute,
    private informationService: InformationService
  ) {
    this.language$ = this.languageService.currentLanguage$
    const params$ = this.route.params.pipe(
      tap((params) => {
        this.countryInformationType = params['countryInformationType'];
        this.articlePage = params['articlePage'] || null;
      }),
      map(params => {
        return {
          countryInformationType: params['countryInformationType'] === 'cis' ? 'ru' : 'another',
          articlePage: this.articlePage
        };
      })
    );

    this.article$ = combineLatest([this.language$, params$]).pipe(
      switchMap(([language, {countryInformationType, articlePage}]) =>
        this.fetchArticle(language, countryInformationType, articlePage)
      )
    );
  }

  private fetchArticle(language: Language, countryInformationType: string, page: string): Observable<ArticleQuery['article']> {
    const articleQueryRef = this.informationService.fetchArticle(countryInformationType, page);
    this.loading$ = articleQueryRef.loading;
    return articleQueryRef.data.pipe(
      map(result => {
        const article = result.article;

        if (article?.title && article?.title?.ru && article?.title?.en) {
          this.title.setTitle(language === article.title.ru ? article.title.ru : article.title.en);
        }
        console.log('result.article', result.article)
        return article;
      })
    );
  }
}
