import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Article = {
  __typename?: 'Article';
  categories: Array<Category>;
  content?: Maybe<TranslatedContent>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  isRead: Scalars['Boolean']['output'];
  page?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  site: Site;
  siteId: Scalars['Long']['output'];
  title?: Maybe<TranslatedContent>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  viewsCount?: Maybe<Scalars['Long']['output']>;
};

export type ArticleFilterInput = {
  and?: InputMaybe<Array<ArticleFilterInput>>;
  categories?: InputMaybe<ListFilterInputTypeOfCategoryFilterInput>;
  content?: InputMaybe<TranslatedContentFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ArticleFilterInput>>;
  page?: InputMaybe<StringOperationFilterInput>;
  publishedAt?: InputMaybe<DateTimeOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<LongOperationFilterInput>;
  title?: InputMaybe<TranslatedContentFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  viewsCount?: InputMaybe<LongOperationFilterInput>;
};

export type ArticleSortInput = {
  content?: InputMaybe<TranslatedContentSortInput>;
  country?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  page?: InputMaybe<SortEnumType>;
  publishedAt?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  title?: InputMaybe<TranslatedContentSortInput>;
  updatedAt?: InputMaybe<SortEnumType>;
  viewsCount?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ArticlesCollectionSegment = {
  __typename?: 'ArticlesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Article>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Block = {
  __typename?: 'Block';
  content?: Maybe<TranslatedContent>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  page?: Maybe<Scalars['String']['output']>;
  site: Site;
  siteId: Scalars['Long']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BlockFilterInput = {
  and?: InputMaybe<Array<BlockFilterInput>>;
  content?: InputMaybe<TranslatedContentFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<BlockFilterInput>>;
  page?: InputMaybe<StringOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<LongOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type BlockSortInput = {
  content?: InputMaybe<TranslatedContentSortInput>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  page?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type BlocksCollectionSegment = {
  __typename?: 'BlocksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Block>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A segment of a collection. */
export type CategoriesCollectionSegment = {
  __typename?: 'CategoriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  articles: Array<Article>;
  children: Array<Category>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['Long']['output']>;
  site: Site;
  siteId: Scalars['Long']['output'];
  title?: Maybe<TranslatedContent>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Long']['output']>;
};

export type CategoryFilterInput = {
  and?: InputMaybe<Array<CategoryFilterInput>>;
  articles?: InputMaybe<ListFilterInputTypeOfArticleFilterInput>;
  children?: InputMaybe<ListFilterInputTypeOfCategoryFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<CategoryFilterInput>>;
  parent?: InputMaybe<CategoryFilterInput>;
  parentId?: InputMaybe<LongOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<LongOperationFilterInput>;
  title?: InputMaybe<TranslatedContentFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  weight?: InputMaybe<LongOperationFilterInput>;
};

export type CategorySortInput = {
  country?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<CategorySortInput>;
  parentId?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  title?: InputMaybe<TranslatedContentSortInput>;
  updatedAt?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ListFilterInputTypeOfArticleFilterInput = {
  all?: InputMaybe<ArticleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ArticleFilterInput>;
  some?: InputMaybe<ArticleFilterInput>;
};

export type ListFilterInputTypeOfBlockFilterInput = {
  all?: InputMaybe<BlockFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BlockFilterInput>;
  some?: InputMaybe<BlockFilterInput>;
};

export type ListFilterInputTypeOfCategoryFilterInput = {
  all?: InputMaybe<CategoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CategoryFilterInput>;
  some?: InputMaybe<CategoryFilterInput>;
};

export type ListFilterInputTypeOfNewsFilterInput = {
  all?: InputMaybe<NewsFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NewsFilterInput>;
  some?: InputMaybe<NewsFilterInput>;
};

export type ListFilterInputTypeOfPageFilterInput = {
  all?: InputMaybe<PageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PageFilterInput>;
  some?: InputMaybe<PageFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  userArticleViewCreate: UserArticleViewCreatePayload;
  userNewsViewCreate: UserNewsViewCreatePayload;
};


export type MutationUserArticleViewCreateArgs = {
  input: UserArticleViewCreateInput;
};


export type MutationUserNewsViewCreateArgs = {
  input: UserNewsViewCreateInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type News = {
  __typename?: 'News';
  content?: Maybe<TranslatedContent>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isImportant?: Maybe<Scalars['Boolean']['output']>;
  isRead: Scalars['Boolean']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  site: Site;
  siteId: Scalars['Long']['output'];
  tgBotPublishedAt?: Maybe<TranslatedContent>;
  tgChannelPublishedAt?: Maybe<TranslatedContent>;
  title?: Maybe<TranslatedContent>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  viewsCount?: Maybe<Scalars['Long']['output']>;
};

/** A segment of a collection. */
export type NewsCollectionSegment = {
  __typename?: 'NewsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<News>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type NewsFilterInput = {
  and?: InputMaybe<Array<NewsFilterInput>>;
  content?: InputMaybe<TranslatedContentFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  image?: InputMaybe<StringOperationFilterInput>;
  isImportant?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<NewsFilterInput>>;
  publishedAt?: InputMaybe<DateTimeOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<LongOperationFilterInput>;
  tgBotPublishedAt?: InputMaybe<TranslatedContentFilterInput>;
  tgChannelPublishedAt?: InputMaybe<TranslatedContentFilterInput>;
  title?: InputMaybe<TranslatedContentFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  viewsCount?: InputMaybe<LongOperationFilterInput>;
};

export type NewsSortInput = {
  content?: InputMaybe<TranslatedContentSortInput>;
  country?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  image?: InputMaybe<SortEnumType>;
  isImportant?: InputMaybe<SortEnumType>;
  publishedAt?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  tgBotPublishedAt?: InputMaybe<TranslatedContentSortInput>;
  tgChannelPublishedAt?: InputMaybe<TranslatedContentSortInput>;
  title?: InputMaybe<TranslatedContentSortInput>;
  updatedAt?: InputMaybe<SortEnumType>;
  viewsCount?: InputMaybe<SortEnumType>;
};

export type Page = {
  __typename?: 'Page';
  description?: Maybe<TranslatedContent>;
  footer?: Maybe<TranslatedContent>;
  id: Scalars['Long']['output'];
  keywords?: Maybe<TranslatedContent>;
  path: Scalars['String']['output'];
  site: Site;
  siteId: Scalars['Long']['output'];
  title?: Maybe<TranslatedContent>;
};

export type PageFilterInput = {
  and?: InputMaybe<Array<PageFilterInput>>;
  description?: InputMaybe<TranslatedContentFilterInput>;
  footer?: InputMaybe<TranslatedContentFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  keywords?: InputMaybe<TranslatedContentFilterInput>;
  or?: InputMaybe<Array<PageFilterInput>>;
  path?: InputMaybe<StringOperationFilterInput>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<LongOperationFilterInput>;
  title?: InputMaybe<TranslatedContentFilterInput>;
};

export type Query = {
  __typename?: 'Query';
  article?: Maybe<Article>;
  articles?: Maybe<ArticlesCollectionSegment>;
  block?: Maybe<Block>;
  blocks?: Maybe<BlocksCollectionSegment>;
  categories?: Maybe<CategoriesCollectionSegment>;
  category?: Maybe<Category>;
  news?: Maybe<NewsCollectionSegment>;
  newsEntry?: Maybe<News>;
  page?: Maybe<Page>;
};


export type QueryArticleArgs = {
  order?: InputMaybe<Array<ArticleSortInput>>;
  where?: InputMaybe<ArticleFilterInput>;
};


export type QueryArticlesArgs = {
  order?: InputMaybe<Array<ArticleSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ArticleFilterInput>;
};


export type QueryBlockArgs = {
  id: Scalars['Long']['input'];
};


export type QueryBlocksArgs = {
  order?: InputMaybe<Array<BlockSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlockFilterInput>;
};


export type QueryCategoriesArgs = {
  order?: InputMaybe<Array<CategorySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryCategoryArgs = {
  id: Scalars['Long']['input'];
};


export type QueryNewsArgs = {
  order?: InputMaybe<Array<NewsSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<NewsFilterInput>;
};


export type QueryNewsEntryArgs = {
  id: Scalars['Long']['input'];
};


export type QueryPageArgs = {
  fullUrl: Scalars['String']['input'];
};

export type Site = {
  __typename?: 'Site';
  articles: Array<Article>;
  blocks: Array<Block>;
  categories: Array<Category>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  news: Array<News>;
  pages: Array<Page>;
  tgBotName?: Maybe<Scalars['String']['output']>;
  tgChannelPostUrl?: Maybe<TranslatedContent>;
  tgMessageType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SiteFilterInput = {
  and?: InputMaybe<Array<SiteFilterInput>>;
  articles?: InputMaybe<ListFilterInputTypeOfArticleFilterInput>;
  blocks?: InputMaybe<ListFilterInputTypeOfBlockFilterInput>;
  categories?: InputMaybe<ListFilterInputTypeOfCategoryFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  domain?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  news?: InputMaybe<ListFilterInputTypeOfNewsFilterInput>;
  or?: InputMaybe<Array<SiteFilterInput>>;
  pages?: InputMaybe<ListFilterInputTypeOfPageFilterInput>;
  tgBotName?: InputMaybe<StringOperationFilterInput>;
  tgChannelPostUrl?: InputMaybe<TranslatedContentFilterInput>;
  tgMessageType?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type SiteSortInput = {
  description?: InputMaybe<SortEnumType>;
  domain?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  tgBotName?: InputMaybe<SortEnumType>;
  tgChannelPostUrl?: InputMaybe<TranslatedContentSortInput>;
  tgMessageType?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TranslatedContent = {
  __typename?: 'TranslatedContent';
  cn?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
  jp?: Maybe<Scalars['String']['output']>;
  kr?: Maybe<Scalars['String']['output']>;
  ru?: Maybe<Scalars['String']['output']>;
};

export type TranslatedContentFilterInput = {
  and?: InputMaybe<Array<TranslatedContentFilterInput>>;
  cn?: InputMaybe<StringOperationFilterInput>;
  en?: InputMaybe<StringOperationFilterInput>;
  jp?: InputMaybe<StringOperationFilterInput>;
  kr?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TranslatedContentFilterInput>>;
  ru?: InputMaybe<StringOperationFilterInput>;
};

export type TranslatedContentSortInput = {
  cn?: InputMaybe<SortEnumType>;
  en?: InputMaybe<SortEnumType>;
  jp?: InputMaybe<SortEnumType>;
  kr?: InputMaybe<SortEnumType>;
  ru?: InputMaybe<SortEnumType>;
};

export type UserArticleViewCreateInput = {
  id: Scalars['Long']['input'];
};

export type UserArticleViewCreatePayload = {
  __typename?: 'UserArticleViewCreatePayload';
  mutationError?: Maybe<MutationError>;
};

export type UserNewsViewCreateInput = {
  id: Scalars['Long']['input'];
};

export type UserNewsViewCreatePayload = {
  __typename?: 'UserNewsViewCreatePayload';
  mutationError?: Maybe<MutationError>;
};

export type ArticleFragment = { __typename?: 'Article', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, page?: string | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null };

export type CategoryItemFragment = { __typename?: 'Category', id: any, weight?: any | null, parentId?: any | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, articles: Array<{ __typename?: 'Article', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, page?: string | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null }> };

export type ArticleQueryVariables = Exact<{
  where?: InputMaybe<ArticleFilterInput>;
  order?: InputMaybe<Array<ArticleSortInput> | ArticleSortInput>;
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, page?: string | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null } | null };

export type CategoriesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryFilterInput>;
  order?: InputMaybe<Array<CategorySortInput> | CategorySortInput>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoriesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Category', id: any, weight?: any | null, parentId?: any | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, articles: Array<{ __typename?: 'Article', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, page?: string | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null }> }> | null } | null };

export type NewsItemFragment = { __typename?: 'News', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null };

export type NewsEntryQueryVariables = Exact<{
  id: Scalars['Long']['input'];
}>;


export type NewsEntryQuery = { __typename?: 'Query', newsEntry?: { __typename?: 'News', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null } | null };

export type NewsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsFilterInput>;
  order?: InputMaybe<Array<NewsSortInput> | NewsSortInput>;
}>;


export type NewsQuery = { __typename?: 'Query', news?: { __typename?: 'NewsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'News', id: any, createdAt?: any | null, publishedAt?: any | null, updatedAt?: any | null, title?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null, content?: { __typename?: 'TranslatedContent', ru?: string | null, en?: string | null } | null }> | null } | null };

export const ArticleFragmentDoc = gql`
    fragment Article on Article {
  id
  title {
    ru
    en
  }
  content {
    ru
    en
  }
  createdAt
  publishedAt
  updatedAt
  page
}
    `;
export const CategoryItemFragmentDoc = gql`
    fragment CategoryItem on Category {
  id
  title {
    ru
    en
  }
  weight
  parentId
  articles {
    ...Article
  }
}
    ${ArticleFragmentDoc}`;
export const NewsItemFragmentDoc = gql`
    fragment NewsItem on News {
  id
  title {
    ru
    en
  }
  content {
    ru
    en
  }
  createdAt
  publishedAt
  updatedAt
}
    `;
export const ArticleDocument = gql`
    query article($where: ArticleFilterInput, $order: [ArticleSortInput!]) {
  article(where: $where, order: $order) {
    ...Article
  }
}
    ${ArticleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArticleGQL extends Apollo.Query<ArticleQuery, ArticleQueryVariables> {
    override document = ArticleDocument;
    override client = 'content/auto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoriesDocument = gql`
    query categories($skip: Int, $take: Int, $where: CategoryFilterInput, $order: [CategorySortInput!]) {
  categories(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      ...CategoryItem
    }
    totalCount
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoriesGQL extends Apollo.Query<CategoriesQuery, CategoriesQueryVariables> {
    override document = CategoriesDocument;
    override client = 'content/auto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsEntryDocument = gql`
    query newsEntry($id: Long!) {
  newsEntry(id: $id) {
    ...NewsItem
  }
}
    ${NewsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsEntryGQL extends Apollo.Query<NewsEntryQuery, NewsEntryQueryVariables> {
    override document = NewsEntryDocument;
    override client = 'content/auto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsDocument = gql`
    query news($skip: Int, $take: Int, $where: NewsFilterInput, $order: [NewsSortInput!]) {
  news(take: $take, skip: $skip, where: $where, order: $order) {
    items {
      ...NewsItem
    }
    totalCount
  }
}
    ${NewsItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsGQL extends Apollo.Query<NewsQuery, NewsQueryVariables> {
    override document = NewsDocument;
    override client = 'content/auto';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    