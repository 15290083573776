import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LotType } from 'src/app/services/lot-adapter/enums/lot-type.enum';
import { NzTabPosition } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'app-category-links',
  templateUrl: './category-links.component.html',
  styleUrls: ['./category-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryLinksComponent {
  @Input({ required: true }) activeLotType!: LotType;
  @Input({ required: true }) availableLotTypes!: string[];
  nzTabPosition: NzTabPosition = 'top';
  selectedIndex = 27;
}
