import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RxPush } from '@rx-angular/template/push';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ButtonModule } from 'src/app/components/button/button.module';
import { LotCardModule } from 'src/app/components/lot-card/lot-card.module';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { LanguageService } from 'src/app/services/language.service';

import { AuctionBreadcrumbModule } from '../../components/auction-breadcrumb/auction-breadcrumb.module';
import { LotListFilterModule } from '../../components/lots-list-filter/lots-list-filter.module';
import { LotsListSortModule } from '../../components/lots-list-sort/lots-list-sort.module';
import { CategoryInfoComponent } from './components/category-info/category-info.component';
import { CategoryLinksComponent } from './components/category-links/category-links.component';
import { LotsListComponent } from './components/lots-list/lots-list.component';
import { PaginationStatusComponent } from './components/pagination-status/pagination-status.component';
import { en } from './locale/en';
import { ru } from './locale/ru';
import { LotsPageComponent } from './lots-page.component';
import { NzResultComponent } from 'ng-zorro-antd/result';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@NgModule({
  declarations: [
    LotsPageComponent,
    CategoryLinksComponent,
    CategoryInfoComponent,
    PaginationStatusComponent,
    LotsListComponent,
  ],
  imports: [
    CommonModule,
    NzGridModule,
    ButtonModule,
    TranslateModule,
    RouterModule,
    AuctionBreadcrumbModule,
    RxPush,
    PaginationModule,
    NzGridModule,
    RxPush,
    LotCardModule,
    NzSpinModule,
    NzIconModule,
    LotListFilterModule,
    LotsListSortModule,
    NzResultComponent,
    NzTabsModule,
  ],
  providers: [],
  exports: [LotsPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LotsPageModule {
  constructor(languageService: LanguageService) {
    languageService.addLocales({ en, ru });
  }
}
