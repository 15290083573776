<nz-row
  [nzGutter]="[10, 10]"
  nzJustify="space-between"
  class="price"
  [class.main]="priceDetail?.isMain"
>
  <nz-col>
    <span>{{ priceDetail?.label ?? '--' | translate }}:</span>
  </nz-col>
  <nz-col>
    <div>
      @if (priceDetail?.priceValue) {
        @if (priceDetail?.priceValue?.value === 0) {
          <nz-row class="value">{{ 'LOT.ON_REQUEST' | translate }}</nz-row>
        } @else {
          <nz-row class="value" [nzGutter]="[5, 0]">
            <nz-col>
              {{ (priceDetail?.priceValue?.value ?? 0) | currency: priceDetail?.priceValue?.unit : 'symbol-narrow' : '1.0-2' }}
            </nz-col>
            @if (renderTooltipForMainPrice) {
              <nz-col>
                <span
                  nz-icon
                  nzType="info-circle"
                  nzTheme="outline"
                  nz-tooltip
                  [nzTooltipTitle]="priceDetail?.tooltip  ?? '--' | translate"
                ></span>
              </nz-col>
            }
          </nz-row>

          @if (isGreenCornerType && convertedPriceToRub) {
            <div class="rub-price">
              ₽{{ convertedPriceToRub | number:'1.0-0' }}
            </div>
          }
        }
      } @else {
        {{ 'LOT.ON_REQUEST' | translate }}
      }
    </div>
  </nz-col>
</nz-row>
