import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { MainRoutingModule } from './main-routing.module';
import { InformationPageModule } from './pages/information-page/information-page.module';
import { MainPageModule } from './pages/main-page/main-page.module';
import { NewsItemPageModule } from './pages/news-item-page/news-item-page.module';
import { NewsPageModule } from './pages/news-page/news-page.module';
import { SanctionsPolicyPageModule } from './pages/sanctions-policy-page/sanctions-policy-page.module';
import { DeliveryStatisticsSectionModule } from './sections/delivery-statistics-section/delivery-statistics-section.module';
import { CalculatorPageModule } from './pages/calculator-page/calculator-page.module';

@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule,
    MainPageModule,
    SanctionsPolicyPageModule,
    DeliveryStatisticsSectionModule,
    InformationPageModule,
    NewsPageModule,
    NewsItemPageModule,
    CalculatorPageModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainModule {}
