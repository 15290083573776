import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ILot } from '../../../../../../services/lot-adapter/interfaces/lot.interface';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LotContactsModalComponent } from '../lot-contacts-modal/lot-contacts-modal.component';
import { CurrentUserServiceDataQuery } from '../../../../../../graphql/akebono-main-graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { LotContactFormModalComponent } from '../lot-contact-form-modal/lot-contact-form-modal.component';
import { PriceDetail } from './types/lot-info.types';
import { Location } from '../../../../../../services/lot-adapter/types/location.type';
import { LotType } from '../../../../../../services/lot-adapter/enums/lot-type.enum';
import { LotUpcomingSailingsModalComponent } from './components/lot-upcoming-sailings-modal/lot-upcoming-sailings-modal.component';
import { departmentIdByLotType } from '../../../../../../services/lot-adapter/constants/department-id-by-lot-type';
import { map, Observable, shareReplay } from 'rxjs';
import { ExchangeRateService } from '../../../../../../services/exchange-rate/exchange-rate.service';
import {
  AutoFreightGQL,
  AutoFreightQuery,
  AutoFreightQueryVariables,
} from 'src/app/graphql/auto-freight-graphql.service';
import { QueryHandlingService } from 'src/app/services/query-handling-service/query-handling.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-lot-info',
  templateUrl: './lot-info.component.html',
  styleUrl: './lot-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotInfoComponent implements OnInit {
  @Input({ required: true }) lot!: ILot;
  @Input({ required: true }) currentUser: CurrentUserServiceDataQuery['currentUser'];
  @Input({ required: true }) activeLotType!: LotType;
  priceDetails: PriceDetail[] = [];
  jpyToRub$: Observable<number> | undefined;
  usdToRub$: Observable<number> | undefined;
  LotType = LotType;
  dataFreight: number = 0;

  constructor(
    private nzModalService: NzModalService,
    private readonly qhs: QueryHandlingService,
    private translate: TranslateService,
    private exchangeRateService: ExchangeRateService,
    private autoFreightGQL: AutoFreightGQL,
  ) {
    const dataFreightRef = this.qhs.fetch<AutoFreightQuery, AutoFreightQueryVariables>(
      this.autoFreightGQL,
    );

    const dataQuery$ = dataFreightRef.data.pipe(shareReplay(1));
    dataQuery$
      .pipe(
        takeUntilDestroyed(),
        map((data) => Number(data.automobileFreight?.constant) || 0),
      )
      .subscribe((value) => {
        this.dataFreight = value;
        this.initPriceDetails();
      });
  }

  ngOnInit(): void {
    this.initPriceDetails();
    this.fetchExchangeRates();
  }

  private initPriceDetails(): void {
    this.priceDetails = [
      {
        priceValue: this.lot.priceInJapan,
        label: 'LOT.FIELDS.JAPAN_PRICE',
        tooltip: 'LOT.FIELDS.JAPAN_TOOLTIP',
        isMain: this.isJpLocation,
      },
      // {
      //   priceValue:
      //     this.lot.priceOnDelivery && this.lot.priceOnDelivery.value !== 0
      //       ? this.lot.priceOnDelivery
      //       : {
      //           unit: this.lot.priceInJapan?.unit || '',
      //           value:
      //             this.lot.priceInJapan?.unit === 'JPY'
      //               ? (this.lot.priceInJapan?.value || 0) + this.dataFreight
      //               : this.lot.priceInJapan?.value || 0,
      //         },
      //   label: 'LOT.FIELDS.TRANSIT_PRICE',
      //   tooltip: 'LOT.FIELDS.TRANSIT_TOOLTIP',
      //   isMain: this.isTransportLocation,
      // },
    ];

    if (
      (this.lot.priceOnDelivery && this.lot.priceOnDelivery.value !== 0) ||
      this.lot.priceInJapan?.unit === 'JPY'
    ) {
      this.priceDetails.push({
        priceValue:
          this.lot.priceOnDelivery && this.lot.priceOnDelivery.value !== 0
            ? this.lot.priceOnDelivery
            : {
                unit: this.lot.priceInJapan?.unit || '',
                value:
                  this.lot.priceInJapan?.unit === 'JPY'
                    ? (this.lot.priceInJapan?.value || 0) + this.dataFreight
                    : this.lot.priceInJapan?.value || 0,
              },
        label: 'LOT.FIELDS.TRANSIT_PRICE',
        tooltip: 'LOT.FIELDS.TRANSIT_TOOLTIP',
        isMain: this.isTransportLocation,
      });
    }

    if (this.lot.priceInStock && this.lot.priceInStock.value !== 0) {
      this.priceDetails.push({
        priceValue: this.lot.priceInStock,
        label: 'LOT.FIELDS.MONGOLIA_PRICE',
        tooltip: 'LOT.FIELDS.MONGOLIA_TOOLTIP',
        isMain: this.isMnLocation,
      });
    }

    this.priceDetails.push({
      priceValue: this.lot.price,
      label: 'LOT.FIELDS.PRICE',
      tooltip: 'LOT.FIELDS.PRICE',
      isMain: true,
    });
  }

  private fetchExchangeRates(): void {
    if (this.activeLotType !== LotType.GreenCorner) {
      return;
    }

    this.jpyToRub$ = this.exchangeRateService.rubToJpyExchangeRate$;
    this.usdToRub$ = this.exchangeRateService.usdToRubExchangeRate$;
  }

  openApplicationModal(): void {
    this.nzModalService.create({
      nzTitle: this.translate.instant('LOT_APPLICATION_MODAL.FILL_OUT_APPLICATION'),
      nzContent: LotContactFormModalComponent,
      nzWidth: '60%',
      nzData: {
        ...this.contactFormModalData,
        activeLotType: this.activeLotType,
      },
      nzFooter: null,
    });
  }

  openUpcomingSailingsModal(): void {
    this.nzModalService.create({
      nzContent: LotUpcomingSailingsModalComponent,
      nzWidth: '80%',
      nzTitle: undefined,
      nzFooter: null,
    });
  }

  openContactsModal(): void {
    this.nzModalService.create({
      nzTitle: this.translate.instant('LOT_APPLICATION_MODAL.SELLER_CONTACTS'),
      nzContent: LotContactsModalComponent,
      nzWidth: '60%',
      nzFooter: null,
    });
  }

  private get contactFormModalData(): Record<string, unknown> {
    return {
      lotId: this.lot.akebonoLotId || this.lot?.id?.toString(),
      lotType: this.lot?.akebonoLotType,
      lotSource: this.lot?.akebonoLotSource,
      objectGUID: this.lot?.guid,
      departmentId: departmentIdByLotType[this.activeLotType as keyof typeof departmentIdByLotType],
      wholesalePrice: this.lot.price?.value,
      wholesalePriceCurrency: this.lot.price?.unit,
      currentUser: this.currentUser,
    };
  }

  private get isMnLocation(): boolean {
    return [Location.MONGOLIA, Location.ULAANBAATAR, Location.ZAMIIN_UUD].includes(
      this.lot?.location as Location,
    );
  }

  private get isJpLocation(): boolean {
    return this.lot?.location === Location.JAPAN;
  }

  private get isTransportLocation(): boolean {
    return this.lot?.location?.toLowerCase() === Location.TRANSPORTING;
  }
}
